@import '../../../../stylesheets/variables';

.container {
	display: flex;
	color: black;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	align-items: center;
}

.icon {
	width: 24px !important;
	height: 24px !important;
	margin: 0 8px 0 0;
}
