@import '../../../../stylesheets/variables';

.container {
	color: $c_blue_500;
}

.deleteIcon {
	color: #fd8770;
}

.icon {
	body[dir='rtl'] & {
		transform: rotate(180deg);
	}
}
