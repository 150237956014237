@import '../../../../../../stylesheets/variables.scss';

.container {
	display: flex;
	flex-flow: column;
	flex: 1;
	@include legacyGap(8px, column);

	.header {
		font-size: $fs_medium;
	}

	.amount {
		font-size: $fs_xlarge;
		font-weight: $fw_medium;
		color: $c_blue_500;
	}

	.date {
		font-size: $fs_medium;
		color: $c_gray;
	}
}
