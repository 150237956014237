@import '../../../../stylesheets/variables.scss';

.container {
	position: relative;
}

.back {
	position: absolute;
	top: -110px;
	left: -40px;

	@media (max-width: 500px) {
		top: -95px;
		left: -25px;
	}
}

.alignmentContainer {
	max-width: 380px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	text-align: left;

	ul {
		list-style: disc;
		margin-left: 20px;

		li {
			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}
	}

	.fileUpload {
		margin-top: 24px;
		padding: 28px 24px;
		background-color: $c_gray_200;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		position: relative;

		h3 {
			font-size: $fs_large;
			font-weight: $fw_medium;
			margin-bottom: 10px;
		}

		p {
			font-size: $fs_small;
			margin: 0 0 4px;
		}

		input {
			opacity: 0;
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.submit {
		margin: 24px auto;
	}

	.successContainer {
		text-align: center;

		a {
			margin-top: 20px;
			width: 232px;
		}
	}
}
