@import '../../../../stylesheets/variables.scss';

.title {
	font-size: 20px;
	line-height: $lh_medium;
	text-align: center;
	font-weight: $fw_medium;
	margin-bottom: 32px;
}

.post {
	width: 380px;
	margin: 40px auto 24px;
	font-size: $fs_medium;
	line-height: 20px;
	font-weight: $fw_regular;
}

.button {
	margin: 0 auto;
	width: 232px;
	display: block;
	margin-bottom: 15px;
}
