@import '../../../../stylesheets/variables';

.header {
	display: flex;
}

.title {
	font-size: 20px;
	line-height: 28px;
	font-weight: $fw_medium;
	margin: 0;
	text-align: left;
	margin-bottom: 32px;
	display: inline-flex;
}

.link {
	text-decoration: none;
	font-size: $fs_small;
	font-weight: $fw_medium;
	line-height: 22px;
	color: $c_blue_300;
	margin-left: auto;
	margin-right: 0;
	display: inline-flex;
	justify-content: flex-end;
}

.iconContainer {
	margin: 45px auto;
	text-align: center;
}

.arrows {
	margin: 24px auto 12px;

	&.disabled {
		filter: grayscale(1);

		img:hover {
			cursor: not-allowed;
			transform: none;
			animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
		}
	}

	img:hover {
		cursor: pointer;
		transition: $tr_slow;
		transform: rotate(180deg);
	}
}

.form {
	max-width: 380px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
}

.button {
	margin-top: 40px;
	font-size: $fs_medium;
}

.option {
	display: flex;
	align-items: center;

	.currencyIcon {
		width: 24px;
		height: 24px;
		margin-right: 8px;
	}
}

.currencyLabel {
	color: $c_blue_300;
	font-size: $fs_medium;
	font-weight: $fw_medium;
}

.amount {
	font-size: $fs_small;
	font-weight: $fw_regular;
	color: black;
}

.refAmount {
	font-size: 10px;
	color: #b3b6c6;
	font-weight: $fw_regular;
	line-height: 20px;
}

.currencyName {
	color: #b3b6c6;
}

.recurringContainer {
	margin-top: 32px;
}

.recurringOrderDetails {
	border: 1px solid #e6e8f0;
	border-radius: 4px;
	padding: 16px;
}

.label {
	display: flex;
	color: $c_blue_300;
	font-size: $fs_small !important;
	font-weight: $fw_medium;
	margin-bottom: 8px;
}

.datePicker {
	margin-right: 0 !important;
}

.select {
	margin-bottom: 16px;

	> *:nth-child(3) {
		> div > div > div > span {
			display: none;
		}
	}
}

.twoColumns {
	display: flex;
	flex-direction: column;

	> * {
		margin-bottom: 16px;
	}

	@media (min-width: 550px) {
		flex-direction: row;

		> * {
			margin-bottom: 0;

			&:first-child {
				margin-right: 8px;
			}
			&:last-child {
				margin-left: 8px;
			}
		}
	}
}

.freqOption {
	color: black;
	display: flex;
	width: 100%;
	text-align: left;
	justify-content: flex-start;
	flex-direction: column;
	font-size: $fs_medium;
	font-weight: $fw_medium;

	> span {
		color: #757575;
		font-size: $fs_small;
		font-weight: $fw_regular;
	}
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-3px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(3px, 0, 0);
	}
}

.tooltip {
	border-radius: 4px;
	background: $c_blue_300;
	color: white;
	font-weight: $fw_regular;
	font-size: $fs_small;
	padding: 12px 20px;
}

.availableBalance {
	margin-top: 8px;
	font-size: $fs_small;
	color: #b3b6c6;
	text-align: left;

	span {
		font-weight: $fw_medium;
		color: $c_blue_300;
	}
}
