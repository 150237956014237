@import '../../../stylesheets/variables.scss';

.container {
	padding: 40px;
}

.label {
	color: $c_blue_300;
}

.select {
	display: block;
	width: 150px;
	min-width: 135px;
}

.singleValue {
	display: flex;
	align-items: center;
}

.option {
	display: flex;
	align-items: center;

	.currencyIcon {
		width: 20px;
		height: 20px;
		margin: 0;
		margin-right: 8px;
	}
}
