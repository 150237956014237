@import '../../stylesheets/variables';

.title {
	color: $c_gray_600;
	font-size: $fs_xsmall;
	flex: 1;
}

.tags {
	display: flex;
	flex-flow: row;
	@include legacyGap(8px, row);

	.tag {
		font-size: $fs_xsmall;
		font-weight: $fw_medium;
		color: $c_blue_100;
		border: 1px solid $c_blue_100;
		border-radius: 4px;
		min-width: 55px;
		padding: 2px 6px;
		line-height: 13px;
		text-align: center;
		white-space: nowrap;
		cursor: pointer;
		text-transform: uppercase;

		&.active {
			color: white;
			background-color: $c_blue_100;
		}
	}
}

.container {
	display: flex;
	flex-flow: column;
}
