@import '../../stylesheets/variables';

@mixin container {
	padding: 10px;
	margin: 20px 0;
	border-radius: 4px;
}

.ERROR {
	@include container;
	color: $c_red;
	border: 1px solid $c_red;
}

.SUCCESS {
	@include container;
	color: $c_green;
	border: 1px solid $c_green;
}
