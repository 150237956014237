@import '../../../stylesheets/variables';

.layout {
	display: flex;
	flex-flow: row;
	justify-content: center;
	@include legacyGap(16px, row);
}

.sidebar {
	display: flex;
	flex-flow: column;
	width: 376px;
}

.main {
	display: flex;
	flex-flow: column;
	max-width: 768px;
	width: 100%;
	gap: 16px;
}
