@import '../../../../stylesheets/variables.scss';

.form {
	width: 100%;
	max-width: 328px;

	@media (max-width: 1200px) {
		margin: 0 auto;
	}

	> div:first-child > label {
		margin-top: 0;
	}
}

.depositWalletSelect {
	margin: 0 0 32px;
}

.input {
	margin: 0 0 24px;
}

.receiveAmount {
	position: relative;
	margin: 0 0 40px;
}

.receiveError {
	bottom: -24px;
}

.button {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0 auto !important;
	max-width: 232px;
	width: 232px;
}

.label {
	color: $c_blue_300;
	font-size: 12px;
	font-weight: 500;
	margin: 0 0 8px;
}

.value {
	color: $c_black;
	font-size: 16px;
	font-weight: 400;
}

.loader {
	margin: auto;
}

.option {
	display: flex;
	align-items: center;
	cursor: pointer;

	* {
		cursor: pointer;
	}

	.currencyIcon {
		width: 20px;
		height: 20px;
		margin: 0 10px 0 0;
	}
}

.currencyInputContainer {
	max-width: 438px;
}

.link {
	color: $c_blue;
	text-decoration: none;
	font-weight: $fw_medium;
}

.terms {
	width: 100%;
	text-align: center;
	font-size: $fs_small;
	padding-bottom: 16px;
}

.disclaimer {
	margin: 0 40px;
}
