@import '../../../../stylesheets/variables.scss';

.container {
	position: relative;
}

.button {
	width: 232px;
	margin: 0 auto 36px;
}

.form {
	max-width: 380px;
	margin: 0 auto;
	width: 100%;
}

.label {
	font-size: $fs_medium;
	line-height: $lh_medium;
	font-weight: $fw_medium;
	color: $c_blue_300;
}

.input {
	width: 100%;
	margin-bottom: 32px;
}

.fullWidth {
	width: 100%;
}

.title,
.text {
	max-width: 380px;
	width: 100%;
	text-align: left;
	color: #7b7e93;
	margin: 0 auto 16px;
}

.title {
	font-size: $fs_large;
	line-height: 24px;
	font-weight: $fw_medium;
}

.text {
	font-size: $fs_medium;
	line-height: 20px;
	font-weight: $fw_regular;
}

.back {
	position: absolute;
	top: -110px;
	left: -40px;

	@media (max-width: 500px) {
		top: -95px;
		left: -25px;
	}
}
