@import '../../../../stylesheets/variables.scss';

.container {
	width: 100%;
	border-radius: 8px;
	background-color: white;
	margin-bottom: 16px;
	padding: 32px 0 0 0;
	text-align: center;
	margin-top: 20px;

	@media (min-width: 992px) {
		margin-top: 0;
	}

	&.paddingBottom {
		padding-bottom: 32px !important;
	}
}

.backButton {
	margin: 20px 0;
}
/*! rtl:begin:ignore */
.imgContainer {
	width: 100%;
	position: relative;
	margin-bottom: 20px;
	width: 262px;
	margin: 0 auto 10px;

	.cardNumber,
	.name {
		position: absolute;
		font-size: $fs_medium;
		text-transform: uppercase;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
		color: white;
		letter-spacing: 0.5px;
	}

	.name {
		left: 30px;
		bottom: 16px;
	}

	.cardNumber {
		font-size: $fs_large;
		top: 94px;
		right: 26px;
		letter-spacing: 2.5px;
	}

	img {
		width: 262px;
		margin: 0 auto;
	}
}
/*! rtl:end:ignore */

.info {
	font-size: $fs_medium;
	line-height: 21px;
	font-weight: $fw_regular;
	color: black;
	width: 262px;
	margin: 40px auto 24px;
	text-align: center;
}

.controlList {
	.noBottom {
		border-bottom: 0;
	}

	li {
		border-bottom: 1px solid $c_gray_400;

		&:last-of-type {
			border: 0;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			overflow: hidden;
		}

		.listItem {
			display: flex;
			align-items: center;
			font-size: $fs_large;
			line-height: $lh_medium;
			font-weight: $fw_medium;
			color: $c_blue_300;
			text-decoration: none;
			text-align: left;
			padding: 10px 28px 10px 10px;

			@media (min-width: 992px) {
				padding: 14px 28px 14px 14px;
			}

			> img {
				width: 48px;
				height: 48px;

				@media (max-width: 767px) {
					width: 36px;
					height: 36px;
				}
			}

			&.active,
			&:hover {
				background: #f5f8ff; /* Old browsers */
				background: -moz-linear-gradient(
					left,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* FF3.6-15 */
				background: -webkit-linear-gradient(
					left,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(
					to right,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
			}

			img {
				margin-right: 20px;
			}
		}
	}

	@media (max-width: 979px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	@media (max-width: 767px) {
		display: grid;
		grid-template-columns: 1fr;
	}
}

.greyScale {
	filter: grayscale(1);
}

.lockContainer {
	position: absolute;
	z-index: 10;
	width: 70px !important;
	top: calc(50% - 35px);
	left: calc(50% - 35px);
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.6);
}

.lockedCard {
	width: 70px !important;
}

.buttonRow {
	flex: 1;
	display: block;
	margin: 25px auto;
	text-align: center;
	justify-content: center;

	&.cards {
		li {
			max-width: 80px;
			margin-right: 15px;
		}
	}

	li {
		margin-right: 20px;
		display: inline-flex;

		a {
			color: $c_blue_300;
		}

		@media (min-width: 767px) {
			margin-right: 60px;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.labelText {
	font-size: $fs_medium;
	line-height: 20px;
	font-weight: $fw_regular;
	color: black;
	margin-bottom: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 5px;
}

.balance {
	font-size: 20px;
	font-weight: 600;
	line-height: 26px;
	color: $c_blue_300;
	margin-bottom: 6px;
	text-align: center;
}

.issued {
	font-size: $fs_small;
	font-weight: $fw_regular;
	color: #64696f;
	margin: 0;

	@media (max-width: 767px) {
		margin-bottom: 28px;
	}
}

.tooltip {
	white-space: pre-wrap;
}
