@import '../../../../stylesheets/variables.scss';

.liveRates {
	display: flex;
	color: #b3b6c6;
	font-weight: $fw_regular;
	align-items: center;

	h6 {
		margin: 0;
		font-size: $fs_small;
		font-weight: $fw_regular;
		display: flex;
	}

	&.wide {
		color: black;
		border-top: 1px solid #e6e6e6;
		padding-top: 24px;
		margin-top: 24px;

		h6 {
			font-size: $fs_medium;
		}
	}
}

.flexRates {
	display: flex;
	margin-left: 4px;

	&.wide {
		margin-left: auto;
		margin-right: 0;

		* {
			color: $c_blue_100;
			font-size: $fs_medium;
		}
	}
}

.receiveRate {
	margin-left: 3px;
}

.receiveRate,
.payRate {
	height: 24px;
	font-size: $fs_small;
	line-height: 24px;
	color: $c_blue_300;
	font-weight: $fw_medium;
}

.loader {
	transform: scale(0.6);
	margin: 0;
	line-height: 0;
}
