.container {
	max-width: 1160px;
	width: 100%;
	margin: 0 auto;
}

.flexGrid {
	display: block;
	
	@media (min-width: 992px) {
		display: flex;
	}
}