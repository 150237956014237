@import '../../../../stylesheets/variables.scss';

.icon {
	margin-right: 12px;
	max-width: initial;
}

.item {
	text-align: left;
	align-items: center;
	padding: 11px 0;
	transition: $tr_fast;
	cursor: pointer;
	display: flex;

	&:hover {
		background: #f5f8ff; /* Old browsers */
		background: -moz-linear-gradient(
			left,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* FF3.6-15 */
		background: -webkit-linear-gradient(
			left,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(
			to right,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
	}
}

.description {
	font-weight: $fw_regular;
	font-size: $fs_medium;
	line-height: 24px;
	color: black;
	flex: 1 auto;

	@media (min-width: 768px) {
		font-size: $fs_large;
	}
}

.details {
	display: block;
	font-size: 10px;
	font-weight: $fw_regular;
	color: $c_gray;
	line-height: $lh_medium;

	> span {
		&:first-child {
			margin-right: 10px;
		}
	}

	@media (min-width: 768px) {
		font-size: $fs_small;
	}
}

.amount {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	color: black;
	text-align: right;

	&.green {
		color: #53c789;
	}

	@media (min-width: 768px) {
		font-size: $fs_large;
	}
}
