@import '../../../../stylesheets/variables.scss';
@import '../../../../components/Input/Input.module.scss';

.container {
	display: flex;
	flex-flow: column;
	@include legacyGap(20px, column);
	align-items: center;
	padding: 24px;

	@media (min-width: 767px) {
		width: 680px;
	}
}

.title {
	color: $c_blue_300;
	font-size: $fs_huge;
	font-weight: $fw_medium;
}

.icon {
	width: 96px;
	height: 96px;
}

.button {
	font-weight: $fw_medium;
	font-size: $fs_medium;
	min-width: 20%;
}

.success {
	padding: 32px 0;
	@include legacyGap(32px, column);

	.title {
		color: $c_black;
		font-size: $fs_xxlarge;
		font-weight: $fw_medium;
	}

	.icon {
		width: auto;
		height: 160px;

		img {
			height: 160px;
		}
	}
}

.closeIcon {
	position: absolute;
	top: 24px;
	right: 24px;
	width: 24px;
	height: 24px;
	z-index: 10;
	cursor: pointer;

	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 18px;
		height: 3px;
		background: $c_blue_300;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}

.emails {
	input {
		@extend .input;
	}
}
