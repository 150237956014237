@import '../../../../../stylesheets/variables.scss';

.modal {
	display: flex;
	padding: 20px;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media (min-width: 768px) {
		padding: 40px 80px;
		width: 768px;
	}
}

.header {
	text-align: center;
}

.transactionName {
	font-size: 28px;
	word-break: break-word;
	font-weight: $fw_medium;
	color: $c_blue_300;
	margin: 25px auto 8px;
}

.id {
	margin: 0 auto 4px;
	font-size: $fs_large;
	font-weight: $fw_regular;
	color: black;
	word-break: break-word;
}

.date {
	color: $c_gray;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	margin: 0 auto 4px;
}

.status {
	color: black;
	font-size: $fs_large;
	font-weight: $fw_regular;
	margin: 0 auto 40px;
	text-transform: capitalize;
}

.assets {
	display: flex;
	align-items: center;
	justify-content: center;

	.icon {
		padding: 0;
		margin: 0;
		border: 2px solid white;
		box-shadow: none;

		&.margin {
			margin-left: -7px;
			margin-right: 8px;
		}
	}

	.date {
		display: block;
	}
}

.icon {
	width: 36px;
	height: 36px;
}

.arrow {
	margin: 0 4px;
}

.content {
	width: 100%;
	display: block;
}

.closeIcon {
	position: absolute;
	top: 24px;
	right: 10px;
	width: 24px;
	height: 24px;
	z-index: 10;
	cursor: pointer;

	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 18px;
		height: 3px;
		background: $c_blue_300;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}
