@import '../../stylesheets/variables.scss';

.container {
	margin: 20px 0 0;
	padding: 0 20px;

	@media (min-width: 1025px) and (max-width: 1024px) {
		display: flex;
	}

	@media (min-width: 1200px) {
		display: flex;
	}

	@media (min-width: 1025px) {
		padding: 0 40px;
	}
}

.column {
	margin: 0 0 40px;

	&:last-child {
		margin: 0;
	}

	@media (min-width: 1025px) and (max-width: 1024px) {
		flex: 1 1 calc(50% - 50px);
		margin: 0 80px 0 0;

		&:last-child {
			margin: 0;
		}
	}

	@media (min-width: 1200px) {
		flex: 1 1 calc(50% - 50px);
		margin: 0 80px 0 0;

		&:last-child {
			margin: 0;
		}
	}
}

.howToColumn {
	max-width: 280px;
}
