@import '../../../../../stylesheets/variables';

.label {
	margin-bottom: 8px;
	font-size: $fs_large;
	font-weight: $fw_medium;
	color: $c_blue_300;
	display: flex;
	width: 100%;

	> * {
		&:first-child {
			margin-left: 6px;
			cursor: pointer;
		}

		&:last-child {
			height: 100%;
			display: flex;
			flex: 2 3;
			justify-content: flex-end;
			text-align: right;
			color: $c_blue;
			font-size: $fs_medium;
		}
	}
}

.value {
	margin-bottom: 24px;
	word-break: break-word;
	color: black;
	font-size: $fs_large;
	font-weight: $fw_regular;
}
