.container {
	max-width: 1160px;
	width: 100%;
	margin: 0 auto;
}

.flexGrid {
	display: flex;
}

.sidebar {
	flex: 1;
}

.content {
	flex: 3;
}
