@import '../../stylesheets/variables';

.head {
	margin: 0 0 0 16px;
	display: flex;
	align-items: center;

	@media (min-width: 767px) {
		margin: 0 0 29px 16px;
	}
}

.title {
	font-size: 20px;
	text-align: left;
	line-height: 28px;
	font-weight: $fw_medium;
	margin-left: 20px;
}

.iconContainer {
	display: flex;
	align-items: center;
	justify-self: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	text-align: center;

	.icon {
		width: 38px;
		height: 38px;
		margin: 6px 8px 4px 6px;
	}
}

.mobileContainer {
	display: block;

	@media (min-width: 767px) {
		display: flex;
	}

	> div {
		@media (min-width: 767px) {
			&:first-child {
				margin-right: 15px;
			}
		}
	}
}

.historyBack {
	margin-top: 20px;
	margin-bottom: 20px;

	@media (min-width: 767px) {
		margin-left: 12px;
	}

	@media (min-width: 992px) {
		margin-left: initial;
		margin-top: initial;
		margin-bottom: initial;
	}
}

.header {
	display: flex;
	align-items: center;
}

.position {
	margin-left: auto;

	@media (min-width: 767px) {
		margin-right: 16px;
	}
}
