.loader {
    margin: 0 auto;
}

.sidebar {
	display: block;
	
	@media (min-width: 992px) {
		min-width: 376px;
		flex: 1;
	}
}