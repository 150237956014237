@import '../../../../stylesheets/variables';

.form {
	max-width: 438px;
	width: 100%;
	display: flex;
	flex-flow: column;
	@include legacyGap(32px, column);
}

.label {
	color: $c_blue_300;
	font-size: $fs_medium;
	font-weight: $fw_medium;
}

.labelWithTooltip {
	display: flex;
	flex-direction: row-reverse;
	@include legacyGap(8px, row);
	align-items: center;
}

.tooltip {
	background-color: $c_blue_300;
	color: white;
	font-size: $fs_small;
	font-weight: $fw_regular;
	border-radius: 4px;
	padding: 12px 24px;
}

.inlineLabel {
	display: inline-flex;
	@include legacyGap(8px, row);
}

.currencyInput {
	display: flex;
	@include legacyGap(8px, column);
	flex-flow: column;
	position: relative;
}

.input,
.selectedCurrencyInput {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;

	.inputCurrencyIcon {
		width: 20px;
		height: 20px;
		position: relative;
		display: flex;
		margin-left: 14px;
	}

	.numberInput {
		display: flex;
		flex: 1;
		width: 100%;
		height: 40px;
		font-size: $fs_medium;
		font-weight: $fw_regular;
		line-height: 16px;
		border: none;
		transition: $tr_fast;

		&[disabled] {
			cursor: not-allowed;
		}

		&:active,
		&:focus {
			outline: none;
		}
	}

	.currencyInfo {
		display: flex;
		flex-flow: column;
		position: relative;
		background-color: #fff;
		padding-right: 8px;

		.code {
			font-size: $fs_small;
			line-height: 16px;
			text-align: right;
			color: $c_blue_300;
		}

		.name {
			font-size: 10px;
			color: $c_gray_500;
			line-height: 16px;
			text-align: right;
		}
	}
}

.selectedCurrencyInput {
	position: absolute;
	top: 1px;
	left: 0;
	width: calc(100% - 32px);
}

.input {
	border-radius: 4px;
	border: 1px solid #d9dbe5;
	transition: $tr_fast;
	height: 40px;

	&:active,
	&:focus {
		outline: none;
	}

	.numberInput {
		height: 38px;
	}
}
