@import '../../../../stylesheets/variables.scss';

.container {
	position: relative;
}

.back {
	position: absolute;
	top: -110px;
	left: -40px;

	@media (max-width: 500px) {
		top: -95px;
		left: -25px;
	}
}

.verificationContainer {
	max-width: 380px;
	margin: 0 auto;

	label {
		text-align: left;
	}
}

.submit {
	margin: 24px auto;
	max-width: 232px;
	width: 100%;
}

.margins {
	margin: 20px auto;
}

.input {
	margin-bottom: 20px;
}
