@import '../../stylesheets/variables.scss';

.cardContainer {
	background: white 0% 0% no-repeat padding-box;
	box-shadow: 0px 6px 10px #4972f40d;
	border-radius: 8px;
	padding: 31px 0px 31px 40px;
	margin-bottom: 21px;

	.faqLink {
		color: $c_blue_100;
		text-decoration: none;
		font-weight: $fw_medium;
	}

	.flexContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;

		img {
			max-width: 145px;

			@media (max-width: 767px) {
				max-width: 150px;
			}
		}

		.textContainer {
			max-width: 423px;

			.title {
				color: $c_blue_300;
				font-size: $fs_xlarge;
				font-weight: $fw_medium;
				line-height: 32px;
				margin-bottom: 16px;
			}

			.paragraph {
				line-height: 28px;
				font-weight: $fw_regular;
				font-size: $fs_medium;
				color: $c_blue_300;
				max-width: 425px;
				width: 100%;
				margin-bottom: 14px;
			}
		}

		a {
			width: 100%;
			max-width: 176px;

			@media (max-width: 767px) {
				max-width: 100%;
				margin: 0 auto;
			}
		}
	}

	@media (max-width: 767px) {
		padding: 20px;
	}
}
