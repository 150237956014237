@import '../../../../stylesheets/variables.scss';

.container {
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	min-height: 676px;
}

.host {
	display: flex;
	flex-flow: column;
	@include legacyGap(14px, column);
}

.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
	scale: 2;
	margin: auto;
}

.infoBox {
	display: flex;
	flex-flow: row;
	align-items: center;
	@include legacyGap(32px, row);
	padding: 20px 40px;

	.title {
		font-size: $fs_xlarge;
		font-weight: $fw_medium;
		color: $c_blue_300;
		line-height: 32px;
	}

	.details {
		display: flex;
		flex-flow: row;

		font-size: $fs_medium;
		color: $c_blue_300;
		line-height: 28px;
	}

	.icon {
		img {
			width: 211px;
			height: 169px;
		}
	}
}
