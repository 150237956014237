@import '../../../../stylesheets/variables.scss';

.title {
	font-size: 20px;
	line-height: $lh_medium;
	font-weight: $fw_medium;
	color: black;
	margin: 0 auto 32px;
}

.img {
	max-width: 380px;
	width: 100%;
	margin: 0 auto 40px;
}

.paragraph {
	max-width: 380px;
	width: 100%;
	margin: 0 auto 40px;
	font-size: $fs_medium;
	line-height: 20px;
	font-weight: $fw_regular;
	color: black;
}

.button {
	width: 232px;
	margin: 0 auto 100px;
}
