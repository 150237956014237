@import '../../../../stylesheets/variables.scss';

.select {
	padding-top: 12px;
	max-width: 300px;
	width: 100%;
	margin-left: 20px;

	@media (min-width: 1024px) {
		margin-left: 40px;
	}
}

.label {
	color: $c_blue_300;
	font-size: $fs_medium;
	margin-bottom: 8px;
}

.option {
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 30px;

	> img {
		margin-right: 8px;
	}

	* {
		cursor: pointer;
	}

	.currencyIcon {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
}

.currencyLabel {
	margin-right: 5px;
}

.currencyLabel {
	align-items: center;
	display: flex;
	font-size: $fs_medium;
	font-weight: $fw_regular;
}

.currencyName {
	color: #b3b6c6;
}
