@import '../../../../stylesheets/variables.scss';

.container {
	position: relative;
}

.label {
	font-size: $fs_small;
	line-height: $lh_medium;
	font-weight: $fw_medium;
	color: $c_blue_300;
	margin-bottom: 8px;
	display: flex;
	align-items: center;

	* {
		margin-left: 7px;
	}
}

.inputCurrencyIcon {
	width: 20px;
	height: 20px;
	position: absolute;
	left: 6px;
	bottom: 6px;
}

.numberInput {
	display: block;
	width: 100%;
	height: 64px;
	border-radius: 4px;
	border: 1px solid #d9dbe5;
	padding-left: 12px;
	line-height: 16px;
	transition: $tr_fast;
	font-size: $fs_xlarge;
	color: $c_blue_300;
	font-weight: $fw_medium;

	&[disabled] {
		cursor: not-allowed;
	}

	&:active,
	&:focus {
		border-color: darken(#d9dbe5, 15%) !important;
		outline: none;
	}
}

.errorInput {
	border-color: $c_red;
	color: $c_red;
}

.error {
	position: absolute;
	font-size: $fs_small;
	color: $c_red;
	margin-top: 8px;
	background-color: #fff;
	display: block;
	width: 100%;
	text-align: left;

	a {
		color: $c_blue_100;
		text-decoration: none;
		font-size: 12px;
		font-weight: 500;
	}
}

.currencySelect {
	position: absolute;
	display: flex;
	align-items: center;
	bottom: 8px;
	right: 8px;
	padding: 5px 32px 5px 5px;
	cursor: pointer;
	background-color: #f5f8ff;
	border-radius: 4px;
	transition: $tr_fast;

	&.noBg {
		background-color: transparent;
	}

	&.active,
	&:hover {
		background-color: darken(#f5f8ff, 2%);

		&:before,
		&:after {
			background-color: $c_blue_300;
		}
	}

	&:before,
	&:after {
		transition: $tr_fast;
		width: 8px;
		height: 2px;
		content: '';
		background-color: #b3b6c6;
		background-color: $c_blue_300;
		border-radius: 2px;
		display: block;
		position: absolute;
		top: 24px;
		right: 7px;
	}

	&:before {
		right: 11.5px;
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}

	.icon {
		width: 24px;
		height: 24px;
	}
}

.code {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	line-height: 16px;
	text-align: right;
	color: $c_blue_300;
}

.inputLoader {
	position: absolute;
	text-align: center;
	left: calc(50% - 12px);
	bottom: 20px;
	z-index: 10;

	> * {
		border-color: #b3b6c6 transparent transparent transparent !important;
	}
}

.opacity {
	opacity: 0.4;
	pointer-events: none;
}

.useAll {
	margin-top: 6px;
	display: flex;
	align-items: center;
	color: #b3b6c6;
	font-size: $fs_small;

	strong {
		color: $c_blue_300;
		font-weight: $fw_medium;
		margin-left: 4px;
	}
}
