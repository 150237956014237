@import '../../../../../stylesheets/variables';

.container {
	padding: 40px;
	width: 100%;
	position: relative;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 40px 80px;
		width: 522px;
	}

	@media (min-width: 992px) {
		height: auto;
		width: 680px;
		max-width: initial;
	}
}

.closeIcon {
	position: absolute;
	top: 24px;
	right: 10px;
	width: 24px;
	height: 24px;
	z-index: 10;
	cursor: pointer;

	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 14px;
		height: 2px;
		background: $c_blue_300;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}

.header {
	text-align: center;

	.title {
		color: $c_blue_300;
		font-size: $fs_huge;
		font-weight: $fw_medium;
	}
}

.footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
