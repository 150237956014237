@import '../../../../stylesheets/variables.scss';

.status {
	font-size: $fs_small;
	font-weight: $fw_medium;
	height: 24px;
	display: inline-flex;
	align-items: center;
	border-radius: 4px;
	padding: 0 8px;
	text-transform: capitalize;
	background-color: #ebf1ff;
	color: #4972f4;

	&.EXPIRED {
		background-color: #fff3f0;
		color: #ed654a;
	}
	&.PAID {
		background-color: #edf9f3;
		color: #53c789;
	}
	&.CANCELLED {
		background-color: rgba(#f23961, 10%);
		color: #f23961;
	}
}
