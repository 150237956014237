@import '../../stylesheets/variables';

.container {
	max-width: 1160px;
	width: 100%;
	margin: 0 auto;
}

.flexGrid {
	display: flex;
}

.sidebar {
	max-width: 385px;
	min-width: 385px;
	margin-right: 8px;
}

.navbar {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	color: $c_blue_300;
	display: flex;
	@include legacyGap(12px, row);
	align-items: center;
	text-decoration: none;
	padding: 24px 0;
}

.icon {
	body[dir='rtl'] & {
		transform: rotateZ(180deg);
	}
}
