@import '../../../stylesheets/variables';

.selectRow {
	padding: 0 20px 20px 20px;

	@media (min-width: 1200px) {
		padding: 0 40px 0 40px;
	}

	label {
		font-size: $fs_small;
		color: $c_blue_300;
		margin-bottom: 8px;
	}
}

.row {
	display: flex;
	flex-direction: column;
	margin: 24px 0 16px;

	@media (min-width: 480px) {
		align-items: center;
		flex-direction: row;
	}
}

.select {
	margin: 0 0 15px;
	width: 100%;

	@media (min-width: 480px) {
		flex: 1 1 calc(50% - 16px);
		margin: 0 16px 0 0;
		max-width: 300px;
		width: 100%;

		&:last-child {
			margin: 0;
		}
	}
}

.loader {
	margin: auto;
}
