.isWaiting {
	pointer-events: none;
}

.button {
	display: block;
	margin: 0 auto 15px;
	max-width: 232px;
	width: 100%;
}
