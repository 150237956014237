@import '../../../../stylesheets/variables.scss';

.post {
	font-size: $fs_medium;
	font-weight: $fw_regular;
	line-height: 22px;
	margin-bottom: 40px;
}

.img {
	display: block;
	margin: 0 auto 40px;
}

.list {
	margin-top: 28px;

	li {
		display: grid;
		color: $c_blue_300;
		font-weight: $fw_medium;
		font-size: $fs_medium;

		@media (min-width: 630px) {
			grid-template-columns: 30% 20% 35% 15%;
		}

		&:first-child {
			display: none;

			@media (min-width: 630px) {
				display: grid;
			}
		}
	}
}

.controls {
	display: flex;

	> div:first-child {
		max-width: 344px;
	}
}

.status {
	position: relative;
}

.label {
	height: 40px;
	color: $c_blue_100;
	background-color: #ebf1ff;
	border-radius: 8px;
	display: flex;
	align-items: center;
	font-weight: $fw_medium;
	padding: 0 16px;
	cursor: pointer;
	transition: $tr_fast;

	&:hover {
		color: white;
		background-color: $c_blue_100;
	}

	&.open {
		color: white;
		background-color: #3656c0;
	}
}

.icon {
	font-size: 12px;
	margin-left: 8px;
}

.dropdown {
	position: absolute;
	padding: 12px;
	width: auto;
	z-index: 3;
	top: 46px;
	right: 0;
	border: 1px solid #e6e8f0;
	border-radius: 8px;
	background-color: white;
	box-shadow: 0 10px 10px #4972f40f;

	li {
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		cursor: pointer;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.checkbox {
	width: 20px;
	height: 20px;
	border: 1px solid #c8d4fc;
	border-radius: 4px;
	margin-right: 12px;
}

.option {
	font-size: $fs_small;
	font-weight: $fw_medium;
	height: 24px;
	display: inline-flex;
	align-items: center;
	border-radius: 4px;
	padding: 0 8px;
	text-transform: capitalize;
	background-color: #ebf1ff;
	color: #4972f4;

	&.EXPIRED {
		background-color: #fff3f0;
		color: #ed654a;
	}
	&.PAID {
		background-color: #edf9f3;
		color: #53c789;
	}
	&.PENDING {
		background-color: rgba(#fa9419, 15%);
		color: #fa9419;
	}
	&.FAILED {
		background-color: rgba(#f23961, 10%);
		color: #f23961;
	}
}

.container {
	> div {
		padding: 0;
		box-shadow: none;
	}
}
