@import '../../stylesheets/variables.scss';

.searchRow {
	padding: 12px 20px;

	@media (min-width: 1024px) {
		padding: 12px 40px;
	}

	span {
		display: block;
		margin-bottom: 8px;
		font-size: $fs_medium;
		color: $c_blue_500;
		font-weight: $fw_medium;
	}

	.searchInput {
		max-width: 300px;
	}
}
