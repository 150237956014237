@import '../../stylesheets/variables.scss';

.title {
	color: black;
	font-size: $fs_large;
	font-weight: $fw_medium;
	display: flex;
	align-items: center;
	margin: 0 0 24px;

	@media (min-width: 767px) {
		margin: 0 37px 24px;
	}

	&:first-of-type {
		margin-top: 27px;

		@media (min-width: 992px) {
			margin-top: 0;
		}
	}
}

.refCurrency {
	display: inline-block;
	margin-left: auto;
}

.dropdownLabel {
	color: $c_blue_300;
	font-size: 15px;
	font-weight: $fw_regular;
}

.select {
	justify-content: flex-end;
}

.input {
	margin: 0 auto;

	@media (min-width: 992px) {
		margin: 0 39px 0 0;
	}
	> input {
		max-width: 438px;
		width: 100%;
	}
}

.block {
	display: block;
}

.walletBlocks {
	margin-top: 0;
}

.mb0 {
	margin-bottom: 0;
}

.ml {
	margin-left: 18px;

	@media (min-width: 768px) {
		margin-left: 46px;
	}
}

@keyframes glowing {
	0% {
		box-shadow: 0 0 3px $c_blue_100;
	}
	50% {
		box-shadow: 0 0 10px $c_blue_100;
	}
	100% {
		box-shadow: 0 0 3px $c_blue_100;
	}
}
