@import '../../stylesheets/variables.scss';

.infoBubble {
	border-radius: 50%;
	background-color: $c_blue_100;
	color: white;
	width: 14px;
	height: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: $fs_small;
	font-weight: $fw_medium;
	padding-top: 2.9px;
}

:global {
	:local(.tooltip) {
		&.tippy-box {
			background: #102d6f;
			color: #ffffff;
			font-size: 12px;
			font-weight: 400;
			padding: 8px;
		}

		.tippy-arrow {
			color: #102d6f;
		}
	}
}
