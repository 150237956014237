@import '../../../../stylesheets/variables.scss';

.container {
	border-top: 1px solid #e6e6e6;
	padding-bottom: 40px;
	margin: 0 20px;

	@media (min-width: 992px) {
		margin: 0 40px;
	}
}

.title {
	color: $c_blue_300;
	font-size: $fs_large;
	line-height: 24px;
	font-weight: $fw_medium;
	margin: 24px;
}
