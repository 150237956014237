.container {
	position: relative;
}

.back {
	position: absolute;
	top: -110px;
	left: -40px;

	@media (max-width: 500px) {
		top: -95px;
		left: -25px;
	}
}

.button {
	margin: 0 auto;
	width: 232px;
	display: block;
	margin-bottom: 15px;
}

.form {
	max-width: 380px;
	width: 100%;
	margin: 0 auto;
}

.input {
	width: 100%;
	display: block;
	margin-bottom: 40px;
}
