@import '../../../../stylesheets/variables';
@import '../../../../components/Input/Input.module.scss';

.container {
	display: flex;
	flex-flow: column;
	gap: 32px;
	align-items: center;
	padding: 32px;
	text-align: center;
	font-size: $fs_medium;

	@include tablet-wide {
		width: 610px;
	}

	@include monitor {
		width: 610px;
	}
}

.icon {
	width: 96px;
	height: 96px;
}

.closeIcon {
	position: absolute;
	top: 24px;
	right: 24px;
	width: 24px;
	height: 24px;
	cursor: pointer;
	color: $c_blue_300;
}

.title {
	font-size: $fs_huge;
	font-weight: $fw_medium;
	color: $c_blue_300;
}

.content {
	display: flex;
	flex-flow: column;
	gap: 24px;
}

.actions {
	display: flex;
	flex-flow: column;
	gap: 16px;
}

.button {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	min-width: 232px;
}

.value {
	text-align: left;
}

.inputGroup {
	max-width: 100%;
}

.link {
	color: $c_blue;
	text-decoration: none;
	font-weight: $fw_medium;
}
