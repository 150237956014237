@import '../../../../stylesheets/variables.scss';

.container {
	width: 100%;
	display: flex;
	flex-flow: column;
	@include legacyGap(32px, column);
}

.title {
	font-size: $fs_large;
	font-weight: $fw_medium;
}

.empty {
	font-size: $fs_medium;
}

.table {
	display: flex;
	flex-flow: column;

	.row {
		display: flex;
		flex-flow: row;
		height: 76px;
		align-items: center;

		&.header {
			font-size: $fs_medium;
			font-weight: $fw_medium;
			color: $c_blue_250;
			height: auto;
		}

		> div {
			display: flex;
			flex: 0 0;
			padding: 0 16px 0 0;

			&:nth-child(1) {
				flex-basis: 30%;
			}
			&:nth-child(2) {
				flex-basis: 20%;
			}
			&:nth-child(3) {
				flex-basis: 35%;
			}
			&:nth-child(4) {
				flex-basis: 15%;
			}
			&:last-child {
				padding-right: 0;
			}
		}
	}

	.listItem {
		font-size: $fs_medium;
		font-weight: $fw_regular;

		&:not(:last-child) {
			border-bottom: solid 1px #f1f1f3;
		}
	}

	.mobileListItem {
		flex-flow: column;
		height: auto;
		padding: 26px 0;
		@include legacyGap(16px, column);

		.mobileProperty {
			display: flex;
			width: 100%;
			align-items: center;
			flex-basis: 100%;
			padding-right: 0;

			.mobileLabel {
				flex: 1 0 50%;
			}

			.mobileContent {
				flex: 1 0 50%;
				text-align: right;
				word-break: break-word;

				.currency {
					justify-content: flex-end;
				}
			}
		}
	}
}

.currency {
	display: flex;
	line-height: 24px;
	align-items: center;

	.currencyIcon {
		height: 24px;
		width: 24px;
		margin: 0 4px 0 0;
	}
}

.link {
	a {
		font-weight: $fw_medium;
		color: $c_blue_100;
		text-decoration: none;
	}
}
