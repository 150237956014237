@import '../../stylesheets/variables.scss';

.container {
	background-color: white;
	margin-bottom: 16px;
	border-radius: 8px;
	padding: 26px 40px 20px;
	box-shadow: 0 6px 10px #4972f40d;
	align-items: center;
	display: block;

	@media (min-width: 767px) {
		display: flex;
	}
}

.list {
	margin-top: 8px;
	display: flex;
	list-style: none;
	align-items: center;
	margin-bottom: 32px;

	@media (min-width: 767px) {
		margin-left: 33px;
	}

	li {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 12px;
		border-radius: 4px;
		border: 2px solid $c_gray_300;
		font-weight: $fw_medium;
		font-size: $fs_medium;
		flex: 1;
		align-items: center;
		color: $c_blue_300;
		cursor: pointer;
		transition: $tr_fast;

		&:first-child {
			margin-right: 8px;
		}

		&:hover,
		&.selected {
			border: 2px solid $c_blue;
		}
	}
}

.qrCodeContainer {
	position: relative;
	display: block;
	width: 115px;
	height: 115px;
	border: 2px solid $c_blue;
	border-radius: 8px;
	margin: 0 auto 25px;

	@media (min-width: 767px) {
		margin: 0;
	}

	.borders {
		position: absolute;
		width: 115px;
		height: 115px;
		top: -2px;
		left: -2px;
		z-index: 10;
		transform: rotate(45deg);
		background-color: white;
		border-radius: 15px;
	}

	.qrCode {
		z-index: 11;
		position: absolute;
		top: 10px;
		left: 10px;
		transition: $tr_fast;
	}
}

.label {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	color: $c_blue_300;
	display: flex;
	align-items: center;

	@media (min-width: 767px) {
		margin-left: 33px;
	}

	&.mt11 {
		margin-top: 11px;
	}
}

.rightContainer {
	@media (min-width: 767px) {
		max-width: 375px;
		width: 100%;
	}
}

.value {
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: black;
	margin: 8px 0;
	display: flex;
	align-items: center;
	transition: $tr_fast;
	word-break: break-word;
	max-width: 500px;

	@media (min-width: 767px) {
		margin-left: 33px;
	}

	@media (max-width: 1166px) {
		max-width: 347px;
	}

	@media (max-width: 767px) {
		max-width: unset;
	}
}

.getNewAddress {
	display: flex;
	color: $c_blue;
	font-size: $fs_medium;
	font-weight: $fw_medium;
	margin-top: 11px;
	border: 0;
	box-shadow: none;
	background-color: transparent;
	padding: 0;
	transition: $tr_fast;
	cursor: pointer;
	align-items: center;
	height: 24px;
	position: relative;

	@media (min-width: 767px) {
		margin-left: 33px;
	}

	&:hover {
		color: $c_blue_300;
	}

	&[disabled] {
		pointer-events: none;
	}
}

.loader {
	margin-left: 5px;
}

.copyButton {
	margin-left: 15px;
	margin-top: -6px;
}

.opacity {
	opacity: 0.4;
}

.error {
	font-weight: $fw_regular;
	margin-left: 5px;
	color: $c_red;
	font-size: $fs_small;
}

.loadingAddress {
	margin: 15px 0 20px 5px;
}

.bold {
	font-weight: $fw_medium;
	margin-right: 5px;
	font-size: $fs_medium;
}

.text {
	font-size: $fs_medium;
}

.option {
	display: flex;
	align-items: center;
	height: 40px;

	.icon {
		width: 20px;
		height: 20px;
	}
}

.selectInputGroup {
	width: 100%;
	max-width: initial;

	@media (min-width: 768px) {
		max-width: 380px;
	}
}

.select {
	display: flex;
	align-items: center;
	margin-top: 8px;
	height: 40px;
	margin-bottom: 32px;

	@media (min-width: 767px) {
		margin-left: 33px;
	}
}
