@import '../../../../stylesheets/variables.scss';

.container {
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 6px 10px #4972f40d;
	position: relative;

	@media (min-width: 500px) {
		padding: 40px;
	}
}

.cardImg {
	margin-right: 40px;
}

.type {
	font-size: $fs_xlarge;
	color: $c_blue_300;
	font-weight: $fw_medium;
	margin-bottom: 8px;
	display: flex;
	flex-wrap: wrap;
	column-gap: 8px;

	.label {
		font-size: $fs_small;
		font-weight: $fw_medium;
		height: 24px;
		display: inline-flex;
		align-items: center;
		border-radius: 4px;
		padding: 0 8px;
		background-color: #ebf1ff;
		color: #4972f4;
	}
}

.price {
	font-weight: $fw_regular;
	font-size: $fs_medium;
	color: black;
	display: block;
	margin-bottom: 4px;

	b {
		font-weight: $fw_medium;
	}
}

.delivery {
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: black;
	display: block;
}

.cardTypesList {
	margin-bottom: 40px;

	li {
		text-align: left;

		&:first-of-type {
			border-bottom: 1px solid $c_gray_400;
		}

		a {
			cursor: pointer;
			text-decoration: none;
			display: flex;
			align-items: center;
			min-height: 99px;

			&:hover {
				background: #f5f8ff; /* Old browsers */
				background: -moz-linear-gradient(
					left,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* FF3.6-15 */
				background: -webkit-linear-gradient(
					left,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(
					to right,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
			}
		}
	}
}

.infoText {
	font-size: $fs_medium;
	font-weight: $fw_regular;
	line-height: 21px;

	a {
		color: $c_blue_100;
		font-weight: $fw_medium;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.arrow {
	margin-left: auto;
}
