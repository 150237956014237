@import '../../../../stylesheets/variables.scss';

.form {
	width: 100%;
	max-width: 328px;

	@media (max-width: 1200px) {
		margin: 0 auto;
	}

	> div:first-child > label {
		margin-top: 0;
	}
}

.button {
	margin: 40px auto 0;
	display: block;
	max-width: 232px;
	width: 100%;
	text-align: center;
}

.twoFaForm {
	margin: auto;
}

.error {
	margin-top: 15px;
	text-align: center;
	color: $c_red_100;
	font-size: $fs_small;
}
