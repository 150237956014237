.loader {
	margin: auto;
}

.row {
	margin: 24px 0 16px;
	padding: 0 20px 20px 20px;

	@media (min-width: 1200px) {
		padding: 0 40px 0 40px;
	}
}

.select {
	margin: 0 0 15px;
	width: 100%;

	@media (min-width: 480px) {
		flex: 1 1 calc(50% - 16px);
		margin: 0 16px 0 0;
		max-width: 300px;
		width: 100%;

		&:last-child {
			margin: 0;
		}
	}
}

.option {
	display: flex;
	align-items: center;
	cursor: pointer;

	* {
		cursor: pointer;
	}
}

.currencyIcon {
	width: 20px;
	height: 20px;
	margin: 0 10px 0 0;
}

.row {
	margin: 24px 0 0;
	padding: 0 20px 20px 20px;

	@media (min-width: 1200px) {
		padding: 0 40px 20px 40px;
	}
}

.select {
	margin: 0 0 15px;
	width: 100%;

	@media (min-width: 480px) {
		flex: 1 1 calc(50% - 16px);
		margin: 0 16px 0 0;
		max-width: 300px;
		width: 100%;

		&:last-child {
			margin: 0;
		}
	}
}
