@import '../../../stylesheets/variables.scss';

.container {
	background-color: white;
	margin-bottom: 40px;
	border-radius: 8px;
	padding: 26px 50px 20px;
	box-shadow: 0 6px 10px #4972f40d;
	width: 100%;
	text-align: center;

	@media (min-width: 767px) {
		padding: 26px 195px 20px;
	}
}

.title {
	color: #000000;
	font-size: 20px;
	font-weight: $fw_medium;
	display: block;
	margin-bottom: 52px;
}

.iframe {
	width: 100%;
	border: 0;
	max-width: 740px;
	margin: 0 auto;
}
