@import '../../../stylesheets/variables.scss';

.body {
	background-color: #fff;
	font-family: 'Red Hat Text', sans-serif;
	left: 50%;
	margin: 0 auto;
	max-height: 100%;
	max-width: 1032px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 20px;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	z-index: 101;

	@media (min-width: 992px) {
		padding: 68px 72px;
	}
}

.closeButton {
	padding: 0;
	margin: 0 0 50px;
	align-self: flex-start;
	color: $c_blue_100;
	font-size: $fs_small;
	font-weight: $fw_bold;
	justify-content: flex-start;

	&:hover {
		color: #6c8bf5 !important;
	}

	&:focus {
		color: #3656c0 !important;
	}
}

.title {
	color: #102d6f;
	font-size: 21px;
	font-weight: $fw_medium;
	margin: 12px 0 24px;
}

.text {
	color: #000000;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	line-height: $lh_medium;
	margin-bottom: 40px;

	a {
		color: #4972f4;
		font-weight: bold;
		text-decoration: none;
	}
}

.toggle {
	margin-bottom: 20px;

	@media (min-width: 992px) {
		margin-bottom: 40px;
	}
}

.toggleLabel {
	font-weight: $fw_regular;
	font-size: $fs_medium;
	flex-direction: row-reverse;
}

.toggleMessage {
	box-sizing: border-box;
	color: #000000;
	padding-right: 40px;
	width: 100%;
	word-break: break-all;

	@media (min-width: 992px) {
		padding-right: 110px;
	}
}

.agreeButton {
	align-items: center;
	align-self: flex-end;
	display: flex;
	font-size: $fs_large;
	font-weight: $fw_medium;
	min-height: 50px;
	justify-content: center;
	margin: 40px auto 30px !important;
	min-width: 240px;
	padding: 0 10px;
	text-transform: none;

	@media (min-width: 992px) {
		margin: 10px 0 0 auto !important;
	}
}

.backdrop {
	background-color: black;
	bottom: 0;
	left: 0;
	opacity: 0.5;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 100;
}
