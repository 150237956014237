@import '../../../../stylesheets/variables.scss';

.infoBox {
	background-color: rgba($c_blue_transparent, 0.1);
	padding: 8px 34px;
	font-size: $fs_small;
	text-align: center;
	color: $c_blue_100;
	border-radius: 4px;
	margin-top: 32px;
}

.readMore {
	margin-top: 16px;
	font-size: $fs_small;
	font-weight: $fw_regular;
	line-height: 20px;
	text-align: center;

	a {
		color: $c_blue_100;
		text-decoration: none;
	}
}
