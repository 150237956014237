@import '../../../stylesheets/variables.scss';

.title {
	font-size: $fs_large;
	line-height: 21px;
	font-weight: $fw_medium;
	color: black;
	display: flex;
	align-items: center;
	margin: 10px 0 20px 16px;
}

.content {
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 6px 10px #4972f40d;
	position: relative;
}
