@import '../../stylesheets/variables';

.listItem {
	cursor: pointer;
	position: relative;

	&:not(:last-child) {
		border-bottom: 1px solid $c_gray_400;
	}

	&.disabled {
		.icon,
		.title,
		.description,
		.currencies,
		.chevronRight {
			opacity: 0.5;
			transition: $tr_fast;
		}

		.title,
		.chevronRight {
			color: $c_blue_200;
		}
	}
}

.flex {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.itemWrapper {
	align-items: center;
	display: flex;
	flex: 1;
	text-decoration: none;
	transition: $tr_fast;
	padding: 14px 0 7px 0;

	@media (min-width: 620px) {
		padding: 0;
	}
}

.link {
	align-items: center;
	display: flex;
	padding: 20px 0;
	text-decoration: none;
	transition: $tr_fast;

	.disabled & {
		.title,
		.chevronRight {
			color: $c_blue_300;
		}
	}

	&:hover {
		background: #f5f8ff; /* Old browsers */
		background: -moz-linear-gradient(
			left,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* FF3.6-15 */
		background: -webkit-linear-gradient(
			left,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(
			to right,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
	}

	@media (min-width: 768px) {
		padding: 28px 0;
	}
}

.icon {
	margin-right: 28px;
	min-width: 78px;
	width: 78px;
	height: 39px;

	@media (min-width: 768px) {
		margin-right: 32px;
		min-width: 94px;
		height: 47px;
	}
}

.iconWrapper {
	display: flex;
	flex-direction: column;
	margin-right: 28px;
	min-width: 78px;
	width: 78px;
	height: 39px;

	@media (min-width: 768px) {
		margin-right: 32px;
		min-width: 94px;
		height: 47px;
	}

	img {
		height: 100%;
	}
}

.body {
	padding: 0 10px 0 0;
	position: relative;
	width: 100%;

	@media (min-width: 768px) {
		padding: 0;
	}
}

.titleWrapper {
	align-items: center;
	display: flex;
	justify-content: space-between;

	@media (min-width: 620px) {
		margin-bottom: 8px;
	}
}

.title {
	color: $c_blue_300;
	font-size: $fs_large;
	font-weight: $fw_medium;
	margin: 0;
}

.chevronRight {
	color: $c_blue_300;

	@media (min-width: 768px) {
		align-self: flex-start;
		position: relative;
		top: 5px;
	}
}

.descriptionWrapper {
	align-items: center;
	display: flex;
	justify-content: space-between;

	@media (min-width: 992px) and (max-width: 1160px) {
		flex-direction: column;
		align-items: flex-start;
	}
}

.mobileDescriptionWrapper {
	.feesAndLimits {
		display: flex;
		align-items: center;
		margin: 5px 0;
	}

	.description {
		display: block;
	}
}

.description {
	color: #122e70;
	display: flex;
	align-items: center;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	line-height: $lh_medium;
	flex: 1;

	@media (min-width: 992px) and (max-width: 1160px) {
		margin-bottom: 4px;
	}

	.duration {
		display: flex;
		align-items: center;
		margin-right: 10px;

		svg {
			margin-right: 5px;
		}
	}

	.limits {
		display: flex;
		align-items: center;
		margin-right: 12px;

		svg {
			margin-right: 7px;
			transform: rotate(-45deg);
		}
	}

	.fee {
		color: #4972f4;
		font-weight: $fw_medium;
		font-size: 12px;
		background-color: #ebf1ff;
		padding: 1px 8px;
		border-radius: 4px;
	}
}

.currencies {
	color: $c_black;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	line-height: $lh_medium;
	max-width: 200px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;

	@media (min-width: 992px) and (max-width: 1160px) {
		max-width: 100%;
	}

	@media (max-width: 768px) {
		display: block;
		max-width: 100%;
	}

	.currency {
		width: 23px;
		height: 23px;
		margin: 0 -5px 0 0;
		border: 2px solid white;
		box-shadow: none;
	}
}

.closeIcon {
	color: $c_red;

	@media (min-width: 768px) {
		align-self: flex-start;
		position: relative;
		top: 5px;
	}
}

.error {
	position: absolute;
	bottom: 0;
	right: 0;
	font-size: $fs_medium;
	color: $c_red;
}

.loader {
	background-color: rgba(0, 0, 0, 0.05);
	position: absolute;
}

.modalIcon {
	width: 96px;
	height: 96px;
	margin: 40px auto 25px;
}

.post {
	font-size: 24px;
	text-align: center;
	color: $c_blue_300;
	margin-bottom: 10px;
}

.details {
	font-size: $fs_medium;
	text-align: center;
	color: $c_black;
	margin-bottom: 40px;
}

.submitButton {
	max-width: 232px;
	width: 100%;
	height: 46px;
	border-radius: 4px;
	color: $c_white;
	background-color: $c_blue_100;
	font-size: $fs_medium;
	text-align: center;
	border: none;
	font-weight: 600;
	margin: 0 auto 24px;
	display: block;
}

.no {
	color: $c_blue_100;
	text-align: center;
	cursor: pointer;
	font-size: $fs_medium;
	font-weight: 600;
}

.heightAuto {
	height: auto;

	@media (min-width: 768px) {
		height: auto;
	}
}

.noBottomMargin {
	margin: 0;
}
