@import '../../../../../stylesheets/variables.scss';

.container {
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	min-height: 581px;
}

.content {
	@media (min-width: 767px) {
		width: 488px;
	}
}

.button {
	align-self: center;
	min-width: 50%;
	font-size: $fs_medium;
}
