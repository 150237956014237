@import '../../../../stylesheets/variables.scss';

.autoConvert {
	display: flex;
	align-items: center;

	.currencyIcon {
		width: 20px;
		height: 20px;
	}

	.currencyLabel {
		font-size: $fs_medium;
		font-weight: $fw_medium;
	}
}
