@import '../../../../stylesheets/variables';

.container {
	margin: 32px 0 0;
	padding: 0 20px 20px 20px;

	@media (min-width: 768px) and (max-width: 1024px) {
		display: flex;
	}

	@media (min-width: 1200px) {
		display: flex;
		padding: 0 40px 0 40px;
	}
}

.column {
	margin: 0 0 40px;

	&:last-child {
		margin: 0;
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		flex: 1 1 calc(50% - 80px);
		margin: 0 80px 0 0;

		&:last-child {
			margin: 0;
		}
	}

	@media (min-width: 1200px) {
		flex: 1 1 calc(50% - 80px);
		margin: 0 80px 0 0;

		&:last-child {
			margin: 0;
		}
	}

	&.limits {
		opacity: 1;
	}
}

.button {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0 auto !important;
	max-width: 232px;
	width: 232px;
}

.loader {
	margin: auto;
}
