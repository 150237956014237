@import '../../../../stylesheets/variables.scss';

.pendingTran {
	opacity: 0.5;
}

.depositCategoryTitle {
	font-size: $fs_medium;
	color: $c_blue_500;
	line-height: $lh_medium;
	font-weight: $fw_medium;
	margin: 5px 0;
}
