.loaderContainer {
    width: 100%;
    text-align: center;
}

.loader {
    margin: 16px auto;
}

.iban {
    margin-top: 27px;

    @media (min-width: 992px) {
        margin-top: 0;
    }
}