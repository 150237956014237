@import '../../../stylesheets/variables';

.block {
	max-width: 768px;
	display: flex;
	margin: 0 auto;
	flex-flow: column;
	@include legacyGap(36px, 'column');
}

.content {
	line-height: $lh_regular;
	font-size: $fs_small;
	white-space: pre-wrap;
}

.link {
	color: $c_blue;
	text-decoration: none;
	font-weight: $fw_medium;
}

.svg {
	max-width: 204px;
}

.actions {
	display: flex;
	flex-flow: column;
	align-items: center;
	@include legacyGap(8px, 'column');

	.button {
		font-size: $fs_medium;
		font-weight: $fw_medium;
		min-width: 232px;
	}
}

.arrow {
	margin: 0 4px;

	[dir='rtl'] & {
		transform: rotate(180deg);
	}
}
