.container {
	position: relative;
}

.icon {
	margin-right: 40px;
}

.button {
	width: 232px;
	margin: 36px auto 136px;
}

.form {
	max-width: 380px;
	margin: 0 auto;
	width: 100%;

	input {
		position: relative;
	}
}

.showButton {
	position: absolute;
	cursor: pointer;
	right: 15px;
	top: 40px;

	img {
		width: 20px;
		height: 20px;
	}
}
