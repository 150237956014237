@import '../../stylesheets/variables.scss';

.pagination {
	display: flex;
	justify-content: center;

	li {
		width: 27px;
		height: 27px;
		font-size: $fs_small;
		font-weight: $fw_regular;
		color: $c_gray;
		line-height: 20px;
		cursor: pointer;
		display: flex !important;
		align-items: center;
		justify-content: center;
		transition: $tr_fast;

		&:hover,
		&:active,
		&:focus,
		&.active {
			background-color: $c_blue_100;
			border-radius: 50%;
			color: white;

			.color {
				stroke: white;
			}
		}

		&.disabled {
			cursor: not-allowed;

			&:hover,
			&:active,
			&:focus,
			&.active {
				background-color: transparent;
				color: $c_gray;

				.color {
					stroke: $c_gray;
				}
			}
		}
	}
}

.rotate {
	transform: rotate(180deg);
}
