@import '../../../../stylesheets/variables.scss';

.container,
.label,
.value,
.inputWithAddon {
	max-width: 328px;
	width: 100%;
	position: relative;
}

.label {
	margin-top: 32px;
	color: $c_blue_300;
	font-weight: $fw_medium;
	font-size: $fs_medium;
	display: block;
	margin-bottom: 8px;
}

.inputWithAddon {
	display: flex;
	align-items: center;
	border: 1px solid #d9dbe5;
	border-radius: 4px;
	padding: 11px 12px;
	height: 52px;
	transition: $tr_fast;

	&.withIcon {
		> div {
			> div {
				width: 230px;

				@media (min-width: 410px) {
					width: 265px;
				}
			}
		}
	}

	&.errorInput {
		border-color: $c_red_100;
	}

	&:hover {
		border-color: darken(#d9dbe5, 15%);
	}

	> div {
		display: block;

		> div {
			display: flex;
			align-items: center;
			margin-left: 10px;
			width: 245px;

			&:last-of-type {
				margin-top: 2px;
				color: #b3b6c6;
				font-size: 10px;
			}
		}
	}

	input {
		border: none;
		align-items: flex-start;
		background: transparent;
		font-size: $fs_medium;

		&:active,
		&:hover,
		&:focus {
			outline: 0;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}

.baseCurCode {
	font-size: $fs_small;
}

.baseCurCode,
.refCurCode {
	margin-left: auto;
	margin-right: 0;
	pointer-events: none;
}

.baseCurCode {
	position: absolute;
	top: 45%;
	right: 12px;
	transform: translateY(-50%);
}

.refCurCode {
	position: absolute;
	top: 60%;
	right: 12px;
}

.refCurValue {
	width: 100%;
	overflow: hidden;
	pointer-events: none;
}

.numberInput {
	padding: 0;
	width: 100%;
}

.useAll {
	display: block;
	margin-top: 8px;
	cursor: pointer;
	color: $c_blue_100;
	font-size: $fs_small;
	font-weight: $fw_medium;

	&.hidden {
		opacity: 0;
		pointer-events: none;
	}

	&:hover {
		color: darken($c_blue_100, 10%);
	}
}

.error {
	position: absolute;
	font-size: $fs_small;
	left: 0px;
	bottom: -16px;
	color: $c_red_100;
	height: 12px;

	&:before {
		content: '';
		background: url('../../../../images/message_icons/danger.svg') no-repeat;
		background-size: 12px 12px;
		width: 12px;
		height: 12px;
		float: left;
		margin: 0 6px 0 0;
	}
}

.disabled {
	pointer-events: none;

	.control {
		opacity: 0.7;
	}
}

.container {
	.icon {
		width: 20px !important;
		height: 20px;
		margin: 0 auto !important;
	}
}
