@import '../../stylesheets/variables.scss';

.field {
	// TODO remove !important declarations once form uses scoped css classes
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	line-height: 15px;

	&:hover .slider {
		background-color: rgba($c_blue_100, 0.25);
	}
}

.label {
	margin-right: 16px;
}

.toggle {
	display: inline-block;
	height: 20px;
	position: relative;
	width: 35px;

	&:hover .slider {
		background-color: rgba($c_blue_100, 0.25);
	}
}

.slider {
	background-color: white;
	border-radius: 16px;
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
    border: 1px solid #E6E8F0;
	transition: 0.4s;

	&:before {
		background-color: $c_blue;
		border-radius: 50%;
        border-color: #E6E8F0;
		bottom: 2px;
		content: '';
		height: 14px;
		left: 0;
		position: absolute;
		transition: 0.4s;
		width: 14px;
	}
}

.checkbox {
	opacity: 0;
	width: 0;
	height: 0;
    
	&:checked + .slider {
        border-color: $c_blue;
		background-color: $c_blue;
	}

	&:checked + .slider:before {
		transform: translateX(16px);
		background-color: white;
	}
}

.disabled {
	opacity: .6;
	pointer-events: none;
}