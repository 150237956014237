@import '../../../../../stylesheets/variables.scss';

.container {
	text-align: center;
	padding: 40px 0;

	@media (min-width: 768px) {
		width: 667px;
	}
}

.title {
	color: $c_blue_300;
	font-weight: $fw_medium;
	font-size: $fs_huge;
	margin: 24px auto 15px;
}

.post {
	color: black;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	margin: 0 auto 40px;
}

.button {
	max-width: 232px;
	width: 100%;
	display: block;
	margin: 0 auto 8px;
}
