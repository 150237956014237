@import '../../stylesheets/variables.scss';

.container {
	display: flex;
	text-align: left;
	border-radius: 4px;
	font-size: $fs_small;
	padding: 8px 16px;

	.message {
		display: flex;
		align-items: center;
	}

	&.FILL {
		&.INFO {
			color: $c_blue_100;
			background: rgba(108, 139, 245, 0.1);
		}

		&.DANGER {
			color: $c_red_100;
			background: rgba(255, 175, 148, 0.1);
		}

		&.WARNING {
			color: $c_orange_100;
			background: rgba(248, 180, 53, 0.1);
		}

		&.SUCCESS {
			color: $c_green;
			background: rgba(167, 255, 116, 0.1);
		}
	}

	&.BORDER {
		border-radius: 0;
		border: 1px solid black;

		&.WARNING {
			border-color: $c_orange_100;
		}

		&.DANGER {
			border-color: $c_red_100;
		}

		&.INFO {
			border-color: $c_blue_100;
		}

		&.SUCCESS {
			border-color: $c_green;
		}
	}

	.icon {
		flex: 0 1;
		align-self: center;
		height: 24px;
		padding: 0 16px 0 0;
		margin: 0;

		img {
			height: 24px;
			width: 24px;
		}
	}
}
