@import '../../../../stylesheets/variables';

.project {
	display: flex;
	flex-flow: row;
	align-items: center;
	text-decoration: none;
	justify-content: space-between;
	padding: 8px 16px;
	min-height: 76px;
	overflow: hidden;

	> div {
		display: flex;
		flex: 1;
		align-items: center;
		@include legacyGap(8px, row);

		&:nth-child(1) {
			flex-basis: 35%;
		}
		&:nth-child(2) {
			justify-content: center;
			flex-basis: 15%;
		}
		&:nth-child(3) {
			justify-content: center;
			flex-basis: 20%;
		}
		&:nth-child(4) {
			flex-basis: calc(30% - 24px);
			align-items: flex-start;
		}
		&:nth-child(5) {
			flex-basis: 24px;
			justify-content: flex-end;
			padding: 0;
		}
	}
}
