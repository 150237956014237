@import '../../../../stylesheets/variables';

.container {
	margin: 0 0 40px;
}

.heading {
	color: $c_black;
	font-size: $fs_large;
	font-weight: $fw_medium;
	margin: 0 0 24px;
}

.list {
	counter-reset: item;
	list-style-type: none;
	margin: 0;
	padding-left: 5px;
}

.listItem {
	color: $c_black;
	display: flex;
	font-size: $fs_small;
	font-weight: $fw_regular;
	margin: 0 0 16px;

	&::before {
		content: counter(item) '  ';
		counter-increment: item;
		font-weight: $fw_medium;
		margin: 0 20px 0 0;
		width: 0;
	}
}

.instructionsText {
	color: $c_black;
	font-size: $fs_small;
	font-weight: $fw_regular;
}
