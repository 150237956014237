@import '../../../../stylesheets/variables.scss';

.form {
	width: 100%;
	max-width: 328px;

	@media (max-width: 1200px) {
		margin: 0 auto;
	}

	> div:first-child > label {
		margin-top: 0;
	}
}

.addButton,
.inputGroup {
	max-width: 328px !important;
	width: 100%;
	margin: 0 auto;
}

.line {
	margin: 32px;
	width: 100wv;
	height: 1px;
	display: block;
	border-top: 1px solid #e6e6e6;
}

.label {
	margin-top: 32px;
	display: block;
	color: $c_blue_300;
	font-weight: $fw_medium;
	font-size: $fs_medium;
	margin-bottom: 8px;
}

.button {
	margin: 40px auto 0;
	display: block;
	max-width: 232px;
	width: 100%;
	text-align: center;

	&.smallerMargin {
		margin-top: 18px;
	}
}

.addButton {
	display: block;
	margin-top: 32px;
	padding: 0;
	color: $c_blue_100;
	font-weight: $fw_medium;
	font-size: $fs_small;
	cursor: pointer;
	border: none;
	text-align: left;
	background-color: transparent;
	transition: $tr_fast;

	&:hover {
		color: darken($c_blue_100, 10%);
	}
}

.removeButton {
	display: flex;
	align-items: center;
	margin-top: 8px;
	padding: 0;
	color: $c_red;
	font-weight: $fw_medium;
	font-size: $fs_small;
	cursor: pointer;
	border: none;
	text-align: left;
	background-color: transparent;
	transition: $tr_fast;

	svg {
		margin-right: 4px;
	}

	&:hover {
		color: darken($c_red, 10%);
	}
}

.info {
	border: 1px solid $c_blue_100;
	max-width: 328px;
	width: 100%;
	margin: 32px auto -12px;
	display: flex;
	padding: 16px;
	font-size: $fs_small;
	border-radius: 3px;

	img {
		margin-right: 16px;
	}
}

.tooltipToggle {
	border-radius: 50%;
	border: 1px solid $c_blue_100;
	color: $c_blue_100;
	width: 16px;
	height: 16px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-left: 8px;
	font-size: 14px;
	font-weight: $fw_bold;
	cursor: pointer;
	transition: $tr_fast;

	&:hover {
		color: white;
		background-color: $c_blue_100;
	}
}

.tooltipContent {
	color: white;
	background-color: $c_blue_300;
	font-size: $fs_small;
	border-radius: 4px;
	padding: 12px 22px;
	font-weight: $fw_regular;
}

.balance {
	display: inline-flex;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: black;
}

.details {
	position: absolute;
	top: 0;
	right: 8px;
	text-align: right;
}

.code {
	font-size: $fs_small;
	font-weight: $fw_regular;
	color: $c_blue_300;
}

.name {
	font-size: 10px;
	font-weight: $fw_regular;
	color: #b3b6c6;
}

[class^='fee'] * {
	cursor: pointer;
}

[class^='fee']:nth-child(2) > div > div:first-child > div > div > label {
	font-weight: $fw_regular;
}

[class^='fee']:nth-child(2) > div > div:first-child > div > div > label > span {
	display: block;
	color: #b3b6c6;
	font-size: 10px;
}

[class^='fee']:nth-child(2) > div > div:first-child > div > div > div {
	display: none;
}

.infoBlock,
.generalError {
	max-width: 328px;
	margin: 25px auto 0;
}

.option {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;

	.icon {
		width: 18px;
		height: 18px;
		margin-left: 0;
	}
}

.bold {
	font-weight: $fw_medium;
	margin-right: 5px;
	font-size: $fs_medium;
}

.text {
	font-size: $fs_medium;
}

.option {
	display: flex;
	align-items: center;
	height: 40px;
}

.selectInputGroup {
	width: 100%;
	max-width: initial;

	@media (min-width: 768px) {
		max-width: 380px;
	}
}

.select {
	display: flex;
	align-items: center;
	margin-top: 8px;
	height: 40px;
}
