@import '../../../../stylesheets/variables.scss';

.form {
	max-width: 380px;
	width: 100%;
	margin: 0 auto;
}

.select {
	margin-bottom: 32px;
}

.label {
	color: $c_blue_300;
	font-size: $fs_medium;
	margin-bottom: 8px;
}

.option {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
}

.icon {
	width: 18px;
	height: 18px;
	display: block !important;
	margin-bottom: 4px;
	margin-left: 0;
	overflow: visible;

	img {
		width: 18px;
	}
}

.balance {
	display: inline-flex;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: black;
}

.details {
	position: absolute;
	top: 0;
	right: 8px;
	text-align: right;
}

.code {
	font-size: $fs_small;
	font-weight: $fw_regular;
	color: $c_blue_300;
}

[data-selected='true'] {
	.balance,
	.code {
		color: black;
	}
}

.name {
	font-size: 10px;
	font-weight: $fw_regular;
	color: #b3b6c6;
}

[class^='from'] * {
	cursor: pointer;
}

[class^='from']:nth-child(2) > div > div:first-child > div > div > label {
	font-weight: $fw_regular;
}

[class^='from']:nth-child(2) > div > div:first-child > div > div > label > span {
	display: block;
	color: #b3b6c6;
	font-size: 10px;
}

[class^='from']:nth-child(2) > div > div:first-child > div > div > div {
	display: none;
}

.button {
	margin: 0 auto 15px;
	width: 232px;
	display: block;
}

.hidden {
	opacity: 0;
	pointer-events: none;
}

.mb20 {
	margin-bottom: 20px;
}
