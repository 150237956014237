@import '../../../../stylesheets/variables';

.title {
	font-size: 20px;
	line-height: 28px;
	font-weight: $fw_medium;
	margin: 0;
	text-align: left;
	margin-bottom: 32px;
}

.iconContainer {
	margin: 49px auto 45px;
	text-align: center;
}

.form {
	max-width: 380px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
}

.button {
	margin: 40px auto 0;
	display: block;
	font-size: $fs_medium;
}

.amount {
	display: flex;
	align-items: center;
	font-size: 22px;
	font-weight: $fw_medium;
	justify-content: center;
	color: $c_blue_300;

	> div {
		width: initial !important;
	}
}

.icon {
	width: 20px;
	height: 20px;
	margin-left: 0;
}

.currencyCode {
	margin-left: 5px;
}

.relative {
	position: relative;
}

.error {
	position: absolute;
	color: $c_red;
	font-size: $fs_small;
	margin-top: 8px;
	text-align: center;
	width: 100%;
}

.arrowIcon {
	color: $c_blue_300;
	margin: 12px auto;
	transform: scaleY(1.4);
}

.frequency {
	margin-top: 12px;
	font-size: $fs_medium;
	display: flex;
	align-items: flex-start;

	span {
		margin-left: auto;
		margin-right: 0;
		font-weight: $fw_medium;
	}
}
