@import '../../../stylesheets/variables.scss';

.description {
	font-weight: $fw_regular;
	font-size: $fs_medium;
	line-height: 24px;
	color: black;
	flex: 1 auto;

	@media (min-width: 768px) {
		font-size: $fs_large;
	}
}

.details {
	display: block;
	font-size: 10px;
	font-weight: $fw_regular;
	color: $c_gray;
	line-height: $lh_medium;

	> span {
		&:first-child {
			margin-right: 10px;
		}
	}

	@media (min-width: 768px) {
		font-size: $fs_small;
	}
}

.amountContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	min-width: 90px;
}

.statusText {
	font-size: $fs_small;
	color: $c_gray;
	text-transform: uppercase;
}

.amount {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	color: black;
	text-align: right;

	&.green {
		color: #53c789;
	}

	@media (min-width: 768px) {
		font-size: $fs_large;
	}
}

.icon {
	margin-right: 12px;
	max-width: initial;
}
