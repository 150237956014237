@import '../../stylesheets/variables.scss';

.optionContainer {
	border-radius: 8px;
	box-shadow: 0 6px 10px #4972f40d;
	width: 100%;
	background-color: $c_white;
	display: flex;
	flex-direction: column;
	min-height: 662px;

	@media (min-width: 992px) {
		max-width: 768px;
	}
}

.flexCol {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.mobilePageTitle {
	padding: 0 0 0 16px !important;
	margin-bottom: 22px !important;

	div[data-cy='title'] {
		color: $c_blue_400;
		font-size: $fs_medium;
	}

	svg {
		margin-right: 12px;
		color: $c_blue_400;
	}
}
