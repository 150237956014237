@import '../../stylesheets/variables.scss';

.inputGroup {
	position: relative;
}

.label {
	display: block;
	font-size: $fs_medium;
	color: $c_blue_300;
	margin-bottom: 16px;
	font-weight: $fw_medium;
	line-height: $lh_medium;
	margin-top: 32px;
}

.input {
	display: none;
}

.checkmark {
	display: inline-flex;
	width: 20px;
	height: 20px;
	border: 1px solid #c8d4fc;
	border-radius: 50%;
	margin-right: 12px;
	transition: $tr_fast;

	&.active {
		outline: 6px solid $c_blue_100;
		outline-offset: -6px;
	}
}

.radioBox {
	display: flex;
	align-items: center;
	margin-bottom: 16px;

	* {
		cursor: pointer;
	}

	&:hover {
		.checkmark {
			border-color: darken(#c8d4fc, 15%);
		}
	}
}

.radioButton {
	display: inline-flex;
	align-items: center;
}
