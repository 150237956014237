@import '../../../../../../stylesheets/variables.scss';

.container {
	display: flex;
	background-color: $c_gray_200;
	padding: 4px;
	border-radius: 4px;
	align-self: flex-start;

	.option {
		cursor: pointer;
		display: flex;
		height: 34px;
		width: 34px;
		justify-content: center;
		align-items: center;
		color: $c_blue_300;
		font-weight: $fw_medium;

		&.active {
			background-color: $c_blue_100;
			border-radius: 4px;
			color: $c_white;
		}
	}
}

.picker {
	display: flex;
	height: 34px;
	width: 34px;
	min-width: 34px;
	margin-right: 0;

	&::after {
		display: none;
	}

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;

		img {
			width: 20px;
			height: 20px;
		}
	}

	:global {
		.react-datepicker-wrapper {
			display: flex;

			.react-datepicker__input-container {
				display: flex;
			}
		}
	}
}
