@import '../../../stylesheets/variables';

.list {
	display: flex;
	flex-flow: column;

	.link {
		text-decoration: none;

		&:not(:last-child) {
			border-bottom: solid 1px #f1f1f3;
		}
	}

	.listItem {
		display: flex;
		flex-flow: row;
		height: 90px;
		align-items: center;
		@include legacyGap(16px, row);
		cursor: pointer;

		.icon {
			display: flex;
			img {
				height: 60px;
				width: 60px;
			}
		}

		.label {
			display: flex;
			position: relative;
			color: $c_blue_500;
			flex: 1;
			font-size: $fs_xlarge;
			font-weight: $fw_medium;

			&::after {
				font-family: 'FontAwesome';
				font-size: 14px;
				position: absolute;
				right: 0;
				top: 2px;
				transition: $tr_fast;
				content: '\f054';

				body[dir='rtl'] & {
					left: 0;
					right: auto;
					transform: rotateZ(180deg);
				}
			}
		}
	}
}
