@import '../../../../stylesheets/variables.scss';

.heading {
	color: #7b7e93;
	font-size: $fs_large;
	font-weight: $fw_medium;
	margin: 30px 40px 24px;
}

.scrollableContent {
	max-height: 488px;
	overflow-y: auto;
	flex: 1;
}

.list {
	margin-top: 16px;
	padding: 0 20px;

	@media (min-width: 1024px) {
		padding: 0 40px;
	}

	> li {
		border-bottom: 1px solid #e6e6e6;

		&:last-of-type {
			border-bottom: 0;
		}
	}
}

.link {
	display: flex;
	cursor: pointer;
	padding: 24px 0;
	width: 100%;
	align-items: center;
	position: relative;
	text-decoration: none;

	&:hover {
		background: #f5f8ff; /* Old browsers */
		background: -moz-linear-gradient(
			left,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* FF3.6-15 */
		background: -webkit-linear-gradient(
			left,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(
			to right,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
	}
}

.icon {
	width: 65px;
	height: auto;
	text-align: left;
	margin-right: 32px;

	@media (min-width: 768px) {
		width: 94px;
	}
}

.body {
	padding: 0 10px 0 0;
	position: relative;
	width: 100%;

	@media (min-width: 768px) {
		padding: 0;
	}
}

.currencies {
	color: $c_black;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	justify-content: space-between;
	line-height: $lh_medium;
	max-width: 277px;
}

.titleWrapper {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
}

.title {
	color: $c_blue_300;
	font-size: $fs_medium;
	font-weight: $fw_medium;
	margin: 0;

	@media (min-width: 768px) {
		font-size: $fs_large;
	}
}

.chevronRight {
	position: absolute;
	top: 24px;
	right: 0;
	color: $c_black;
}

.loading {
	opacity: 0;
}

.iconContainer {
	position: relative;

	@media (min-width: 767px) {
		width: 120px;
	}
}

.loader {
	position: absolute;
	top: calc(50% - 12px);
	left: calc(50% - 34px);
}

.noData {
	margin: 74px auto;
	text-align: center;
	font-size: $fs_medium;
	color: #b3b6c6;
}

.unavailable {
	opacity: 0.5;
}

.smallerLogo {
	max-width: 65px;

	@media (min-width: 767px) {
		margin-left: 15px;
	}
}

.descriptionWrapper {
	align-items: flex-end;
	display: flex;
	justify-content: space-between;
}

.description {
	color: $c_black;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	justify-content: space-between;
	line-height: $lh_medium;
	flex: 1;
}
