@import '../../stylesheets/variables';

.optionsSidebar {
	width: 100%;

	@media (min-width: 992px) {
		max-width: 385px;
		min-width: 385px;
		margin-right: 7px;
	}
}

.option {
	align-items: center;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	min-height: 100px;
	text-decoration: none;
	transition: $tr_fast;

	&.disabled {
		opacity: 0.5;
		cursor: default;

		&:hover {
			background-color: transparent;
		}
	}

	&:not(:last-child) {
		border-bottom: 1px solid $c_gray_400;
	}

	@media (min-width: 992px) {
		&:hover,
		&:focus {
			background-color: $c_white;
		}

		&.active {
			box-shadow: 0 6px 20px #4972f40d;
			background-color: $c_white;
		}

		&.SETTINGS {
			border: none;
		}
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.optionName {
	color: $c_blue_300;
	font-size: $fs_xlarge;
	line-height: 20px;
	font-weight: $fw_medium;
	margin: 0 0 6px;
}

.optionCount {
	font-size: $fs_medium;
	line-height: $lh_medium;
	font-weight: $fw_regular;
	color: $c_black;
}

.optionsTitle {
	font-size: $fs_large;
	line-height: 21px;
	font-weight: $fw_medium;
	color: $c_black;
	margin: 0 0 24px 24px;
}

.icon {
	width: 78px;
	margin-right: 24px;

	@media (min-width: 768px) {
		width: 94px;
	}
}
