@import '../../stylesheets/variables.scss';

.list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	border-top: 1px solid $c_gray_400;
	max-height: 450px;
	overflow-y: auto;
	flex: 1;
}

.listItem {
	width: 100%;

	&:hover {
		background: #f5f8ff; /* Old browsers */
		background: -moz-linear-gradient(
			left,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* FF3.6-15 */
		background: -webkit-linear-gradient(
			left,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(
			to right,
			#ffffff 0%,
			#f5f8ff 51%,
			#ffffff 100%
		); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
	}

	&:not(:last-child) {
		border-bottom: 1px solid $c_gray_400;
	}

	.even & {
		&:nth-last-of-type(-n + 1) {
			border-bottom: none;
		}
	}

	.chevronRight {
		color: $c_black;
	}

	@media (min-width: 768px) {
		padding: 0;

		&:not(:last-child) {
			border-bottom: 1px solid $c_gray_400;
		}
	}
}

.link {
	align-items: center;
	display: flex;
	text-decoration: none;
	padding: 13px 20px;

	@media (min-width: 1024px) {
		padding: 13px 40px;
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}

	.icon {
		margin-right: 16px;
		flex: 0;
		margin-left: 0;

		@media (min-width: 768px) {
			width: 40px;
		}
	}
}

.text {
	color: $c_blue_300;
	font-size: $fs_large;
	font-weight: $fw_medium;

	@media (min-width: 768px) {
		font-size: $fs_xlarge;
		margin: 0 0 0 16px;
	}
}

.chevronRight {
	margin: 0 15px;
	width: 5px;

	html[dir='rtl'] & {
		transform: rotate(180deg);
	}
}

.sortRow {
	display: flex;
	justify-content: space-between;
	padding: 12px 20px;

	@media (min-width: 1024px) {
		padding: 12px 40px;
	}

	span {
		display: flex;
		align-items: center;
		font-size: $fs_medium;
		color: $c_blue_500;
		font-weight: $fw_medium;
		cursor: pointer;
	}
}

.infoRow {
	flex-grow: 1;

	.balancesRow {
		text-align: left;
		color: $c_black;
		font-size: $fs_medium;

		.referenceBalance {
			color: $c_gray;
			font-size: $fs_small;
		}
	}

	@media (min-width: 768px) {
		display: flex;
		justify-content: space-between;

		.balancesRow {
			text-align: right;
		}
	}
}
