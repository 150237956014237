@import '../../../../stylesheets/variables.scss';

.list {
	margin-top: 12px;
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;

	li {
		padding: 11px 0;
		border-radius: 4px;
		background-color: #ebf1ff;
		cursor: pointer;
		color: $c_blue_100;
		font-weight: $fw_medium;
		font-size: $fs_medium;
		transition: $tr_fast;
		margin: 0 4px;

		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}

		&:hover {
			background-color: darken(#ebf1ff, 2%);
		}
	}
}
