@import '../../stylesheets/variables.scss';

.container {
	border-radius: 8px;
	box-shadow: 0 6px 10px #4972f40d;
	width: 100%;
	height: 215px;
	background-color: #fff;
	margin-bottom: 16px;
	display: flex;
	align-items: center;

	@media (min-width: 992px) {
		max-width: 376px;
	}

	:global {
		.slick-track {
			width: 100% !important;
		}

		.slick-slide {
			@media (min-width: 992px) {
				max-width: 376px;
			}
			width: 100%;
		}

		.slick-dots {
			position: absolute;
			bottom: 0;
			left: 40px;

			li {
				display: inline-block;
				width: 8px;
				height: 8px;
				margin-right: 8px;

				button {
					display: block;
					font-size: 0;
					width: 8px;
					height: 12px;
					border-radius: 50%;
					background-color: #e6e9f0;
					box-shadow: none;
					border: 0;
					cursor: pointer;
					transform: scale(0.7);
					transition: $tr_fast;
				}

				&.slick-active {
					button {
						background-color: #102d6f;
					}
				}
			}
		}
	}
}

.slideContainer {
	padding: 0 25px;
	display: flex;
	color: #000;
	font-size: $fs_medium;
	align-items: center;
	position: relative;
	height: 100%;
}

.imageContainer {
	margin-right: 34px;
}

.contentContainer {
	display: inline-block;
	margin-left: auto;
}

.buttonRow {
	margin: 12px 0 20px;
	display: flex;
	@include legacyGap(5px, row);

	@media (max-width: 400px) {
		flex-direction: column;
	}

	.twitter {
		background-color: #1c94e0;
		margin-bottom: 5px;

		&:hover {
			background-color: darken(#1c94e0, 10%);
		}
	}

	.fb {
		background-color: #1877f2;
		margin-bottom: 5px;

		&:hover {
			background-color: darken(#1877f2, 10%);
		}
	}

	.twitter,
	.fb {
		color: white;
		text-decoration: none;
		padding: 5px 12px;
		border-radius: 7px;
		font-weight: $fw_medium;
		transition: $tr_fast;
		display: flex;
		align-items: center;
		text-align: center;
		max-width: 55%;
		max-height: 28px;

		i {
			margin-right: 5px;
		}
	}
}

.moreButton {
	position: absolute;
	bottom: 0;
	right: 40px;
	left: initial;
	color: $c_blue_300;
	font-weight: $fw_medium;
	font-size: $fs_small;
	text-decoration: none;
	transition: $tr_fast;
	z-index: 10;

	&:hover {
		color: lighten($c_blue_300, 10%);
	}
}
