@import '../../../../../stylesheets/variables';

.input {
	max-width: 328px;
}

.sectionTitle {
	display: block;
	font-size: $fs_large;
	font-weight: $fw_medium;
	margin: 32px 0 16px 0;
}

.sectionDescription {
	display: block;
	font-size: $fs_small;
	margin-bottom: 16px;
}

.createButton {
	display: block;
	margin: 16px auto 0 auto;
	width: 232px;
	font-size: $fs_medium;
	font-weight: $fw_medium;
}

.addIpButton {
	height: auto;
	padding: 0;

	div {
		display: flex;
		align-items: center;
		font-size: $fs_medium;
		font-weight: $fw_medium;
	}
}

.ipInput {
	&.inputError {
		margin-bottom: 10px;
	}
}

.ipItem {
	position: relative;
	max-width: 328px;
	border: 1px solid $c_gray_300;
	border-radius: 4px;
	font-size: $fs_medium;
	line-height: 16px;
	font-weight: $fw_regular;
	padding: 11px 12px;
	margin-bottom: 8px;

	&:hover {
		border-color: #c8d4fc;
		outline: 0;
	}

	.closeIcon {
		position: absolute;
		top: 18px;
		right: 12px;
		width: 12px;
		height: 12px;
		z-index: 10;
		cursor: pointer;

		&:before,
		&:after {
			position: absolute;
			content: '';
			width: 10px;
			height: 2px;
			background: $c_red_100;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}

.terms {
	margin: 40px 0 24px 0;
}

.error {
	display: flex;
	align-items: center;
	font-size: $fs_small;
	left: 0;
	color: $c_red_100;
	max-width: 290px;

	&:before {
		content: '';
		background: url('../../../../../images/message_icons/danger.svg') no-repeat;
		background-size: 20px 20px;
		float: left;
		margin: 0 6px 0 0;
		min-width: 20px;
		min-height: 20px;
	}
}

.listItem {
	line-height: $lh_medium;
}
