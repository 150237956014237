@import '../../stylesheets/variables.scss';

.bannerHeader {
	display: flex;
	align-items: baseline;
}

.bannerHeader {
	display: flex;
	align-items: baseline;
}

.dots {
	font-size: 24px;
}

.panNumber {
	display: flex;
	flex-flow: column;
}

.iconContainer {
	display: flex;
	align-items: center;
	position: relative;

	.lock {
		position: absolute;
		top: 5px;
		left: 10px;
		width: 35px;

		@media (min-width: 768px) {
			top: 2px;
			left: 7px;
		}
	}

	img {
		margin-right: 14px;

		&.blocked {
			filter: grayscale(1);
		}

		&.notActive {
			opacity: 0.5;
		}
	}

	.typeImg {
		@media (max-width: 767px) {
			width: 58px;
			margin-right: 16px;
		}
	}
}

.orderBtn {
	margin-right: 16px;

	@media (max-width: 767px) {
		margin-right: 0;
		margin-bottom: 16px;
	}
}

.bannerHead {
	@media (max-width: 767px) {
		display: flex;
		margin-bottom: 24px;

		img {
			width: 87px;
		}
	}
}

.balanceContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;

	.type {
		@media (max-width: 767px) {
			margin: 2px 0 4px;
			font-size: $fs_small;
		}
	}

	.balance {
		font-size: $fs_medium;
		font-weight: $fw_regular;
		color: black;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		@media (max-width: 767px) {
			justify-content: flex-start;
		}
	}

	.reservedAmount {
		opacity: 0.5;
		font-size: $fs_small;
		margin-top: 2px;
	}

	@media (max-width: 767px) {
		flex-flow: column;
		align-items: flex-start;
	}
}

.number {
	font-size: $fs_large;
	line-height: 20px;
	font-weight: $fw_medium;
	color: $c_blue_300;

	@media (max-width: 768px) {
		display: block;
	}
}

.cardItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 33px 41px;

	&:not(:first-child) {
		border-top: 1px solid $c_gray_400;
	}

	@media (max-width: 767px) {
		padding: 33px 14px;
	}
}

.title {
	font-size: $fs_large;
	line-height: 21px;
	font-weight: $fw_medium;
	color: black;
	flex: 18;
	margin: 10px 0 20px 16px;
	display: inline-block;
}

.seeAllLink {
	display: inline-block;
	margin-left: auto;
	font-size: $fs_small;
	font-weight: $fw_medium;
	margin: 0 40px 15px 0;
	align-items: center;
	color: $c_blue_300;
	text-decoration: none;
}

.content {
	box-shadow: 0 6px 10px #4972f40d;
	border-radius: 8px;
	width: 100%;
	display: block;
	padding: 31px 40px;
	margin-bottom: 40px;
	background-color: white;
	display: flex;

	@media (max-width: 767px) {
		flex-flow: column;
		padding: 20px 14px;
	}
}

.summaryContent {
	padding: 0;
	flex-flow: column;
}

.heading {
	color: $c_blue_300;
	font-size: $fs_xlarge;
	font-weight: $fw_medium;
	line-height: 32px;
	margin-bottom: 16px;
}

.paragraph {
	line-height: 28px;
	font-weight: $fw_regular;
	font-size: $fs_medium;
	color: $c_blue_300;
	max-width: 425px;
	width: 100%;
}

.img {
	width: 87px;
	margin-right: 15px;

	@media (min-width: 767px) {
		margin-left: auto;
		margin-right: 0;
		width: initial;
	}
}
