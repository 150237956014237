@import '../../stylesheets/variables.scss';

.modal {
	background-color: white;
	border-radius: 8px;
	display: flex;
	max-height: 100%;
	max-width: 100%;
	overflow-y: auto;
	display: flex;
	align-items: center;
	justify-content: center;

	@include mobile {
		width: 100%;
	}

	&:focus {
		outline: none;
	}
}

.heightAuto {
	@media (min-width: 768px) {
		height: auto;
	}
}

.overlay {
	background-color: rgba(18, 27, 69, 0.4);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 40;
	display: flex;
	align-items: center;
	justify-content: center;
}
