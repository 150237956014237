@import '../../../../../../stylesheets/variables';

.container {
	display: flex;
	flex-flow: column;
	@include legacyGap(24px, column);

	.title {
		@media (max-width: 766px) {
			text-align: center;
		}
	}
}

.buttons {
	display: flex;
	flex-flow: column;
	@include legacyGap(42px, column);
}

.buttonBlock {
	display: flex;
	justify-content: center;
	flex-flow: column;
	@include legacyGap(16px, column);

	@media (min-width: 767px) {
		flex-flow: row;
		align-items: center;
		@include legacyGap(52px, row);

		.code {
			width: 50%;
		}
	}
}

.button {
	display: flex;
	justify-content: center;
	flex: 1;
}

.code {
	max-width: 352px;
	align-self: center;
}
