@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@400;500;700&display=swap');
@import './variables';

/*! rtl:begin:ignore */
* {
	box-sizing: border-box;
	font-family: 'Red Hat Text', sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar:horizontal {
		height: 7px;
	}

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-track:horizontal {
		background: $c_gray_300 0% 0% no-repeat padding-box;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb,
	&::-webkit-scrollbar-thumb:horizontal {
		background-color: $c_blue_100;
		border-radius: 10px;

		&:hover {
			background-color: #6c8bf5;
		}
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

html,
body {
	margin: 0;
	background-color: $c_white;
	background-color: #f9f9fc;
	min-width: 360px;
	overflow-x: hidden;
}

img.lazyload:not([src]) {
	visibility: hidden; /* or opacity: 0.001; but never use display: none */
}

.lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
	transition: opacity 10ms ease;
}

.content {
	min-height: calc(100vh - 708px);
	padding: 0 16px; 
	
	@media (min-width: 1200px) {
		padding: 0;
	}
}

button {
	outline: 0;
}

/*! rtl:end:ignore */
