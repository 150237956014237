@import '../../stylesheets/variables.scss';

.backButton {
	font-size: $fs_medium;
	color: $c_blue_300;
	text-decoration: none;
	font-weight: $fw_medium;
	display: flex;
	align-items: center;
	margin-bottom: 27px;
	cursor: pointer;

	img {
		width: 24px;
	}
}
