@import '../../stylesheets/variables.scss';

/*! rtl:begin:ignore */
.container {
	position: relative;
	display: flex;
	flex-flow: column;

	[class^='select_account'] * {
		cursor: pointer;
	}
}

.label {
	display: flex;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	margin-bottom: 6px;
}

.selectedValue {
	color: $c_blue_300;
	border: 1px solid #e6e8f0;
	border-radius: 4px;
	padding: 2px 2px;
	cursor: pointer;
	height: 40px;
	position: relative;
	overflow: hidden;

	&.selectActive {
		border-color: $c_blue_100;

		&::after {
			color: $c_blue_300;
			transform: rotateZ(180deg);
		}
	}

	&::after {
		font-family: 'FontAwesome';
		color: $c_gray_500;
		font-size: 12px;
		position: absolute;
		top: 12px;
		right: 12px;
		transition: $tr_fast;
		content: '\f078';

		body[dir='rtl'] & {
			left: 12px;
			right: auto;
		}
	}
}

.autoConvertOption {
	color: $c_blue_300;
	height: 46px;
	padding: 0px 14px;

	.autoConvertIcon {
		width: 24px;
		height: 24px;
	}
}

.inputContainer {
	top: 22px;
	left: 20px;
	color: #c7cdd1;
	z-index: 55;
	width: 100%;
}

.inputGroup {
	display: flex;
	position: relative;
	width: 100%;

	.searchInput {
		display: flex;
		max-width: 433px;
		width: 100%;
		overflow: hidden;

		&::after {
			position: absolute;
			top: 9px;
			right: 18px;
			font-family: 'FontAwesome';
			color: $c_gray_500;
			font-size: $fs_small;
			content: '\f002';
		}
	}
}

.modal {
	width: 100%;
	height: 100%;
	background-color: white;
	outline: none;
}

.mobileSelect {
	width: 100%;
	border-radius: 8px;
	height: 80vh;
	overflow-y: scroll;

	li {
		cursor: pointer;
		padding: 10px 15px;

		&:first-child {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}

		&:last-child {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}

		&:hover,
		&.selected {
			background-color: #f5f8ff;
		}
	}
}

.mobileInputContainer {
	position: relative;
	display: block;
	width: 100%;
	padding: 0 10px;
	text-align: center;
	margin: 55px auto 25px;
	max-width: 433px;

	.inputContainer {
		max-width: 433px;
		position: absolute;
		top: 10px;
		left: 25px;

		body[dir='rtl'] & {
			left: auto;
			right: 25px;
		}
	}

	.searchInput {
		padding: 10px;
		display: block;
		width: 100%;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #d9dbe5;
		transition: $tr_fast;
		padding-left: 42px;
		font-size: $fs_medium;
		margin: 0 auto;
		font-weight: $fw_regular;
		line-height: 16px;

		&:active,
		&:focus {
			border-color: darken(#d9dbe5, 15%) !important;
			outline: none;
			color: black;
			box-shadow: none;
		}
	}

	&::after {
		position: absolute;
		top: 12px;
		right: 22px;
		font-family: 'FontAwesome';
		color: $c_gray_500;
		content: '\f002';

		body[dir='rtl'] & {
			left: 22px;
			right: auto;
		}
	}
}

.closeIcon {
	position: absolute;
	top: 24px;
	right: 10px;
	width: 24px;
	height: 24px;
	z-index: 10;
	cursor: pointer;

	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 18px;
		height: 3px;
		background: $c_blue_300;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}
/*! rtl:end:ignore */
