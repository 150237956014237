@import '../../../../stylesheets/variables';

.form {
	max-width: 438px;
	width: 100%;
	display: flex;
	flex-flow: column;
	@include legacyGap(32px, column);
}

.label {
	color: $c_blue_300;
	font-size: $fs_medium;
	font-weight: $fw_medium;
}

.labelWithTooltip {
	display: flex;
	@include legacyGap(8px, row);
	align-items: center;
}

.tooltip {
	background-color: $c_blue_300;
	color: white;
	font-size: $fs_small;
	font-weight: $fw_regular;
	border-radius: 4px;
	padding: 12px 24px;
}

.acceptCurrencies {
	display: flex;
	flex-flow: column;
	@include legacyGap(8px, column);
}
