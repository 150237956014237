@import '../../stylesheets/variables.scss';

.container {
	background-color: white;
	margin-bottom: 16px;
	border-radius: 8px;
	padding: 26px 40px 20px;
	box-shadow: 0 6px 10px #4972f40d;
	display: flex;
}

.balanceContainer {
	flex: 1;
	justify-content: flex-start;
	width: 50%;
	display: block;
	align-items: center;

	@media (min-width: 767px) {
		display: inline-flex;
	}
}

.balance {
	font-size: 20px;
	font-weight: 600;
	line-height: 26px;
	color: $c_blue_300;
	margin-bottom: 6px;
	text-align: center;

	&.biggerFz {
		font-size: 30px;
	}

	@media (min-width: 767px) {
		text-align: left;
	}
}

.convertedAmount {
	display: block;
	color: black;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	margin-bottom: 5px;
	text-align: center;

	@media (min-width: 767px) {
		text-align: left;
	}
}

.reservedAmount {
	color: $c_gray;
	font-size: $fs_small;
	font-weight: $fw_regular;
	text-align: center;

	@media (min-width: 767px) {
		text-align: left;
	}
}

.buttonRow {
	flex: 1;
	display: block;
	margin: 25px auto;
	text-align: center;

	@media (min-width: 767px) {
		display: flex;
		margin: 0 0 0 10px;
		justify-content: flex-end;
	}

	&.cards {
		li {
			max-width: 80px;
			margin-right: 15px;
		}
	}

	li {
		margin-right: 20px;
		display: inline-flex;

		a {
			color: $c_blue_300;
		}

		@media (min-width: 767px) {
			margin-right: 25px;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.availableBalance {
	text-align: left;
}

.labelText {
	margin-bottom: 6px;
	display: flex;
	align-items: center;
	column-gap: 5px;
}

.buyModal {
	display: flex;
	flex-direction: column;

	button {
		margin: 10px auto 16px;
	}

	iframe {
		border: none;
		width: 420px;

		@media (max-width: 767px) {
			width: 100%;
		}
	}
}

.tooltip {
	white-space: pre-wrap;
}
