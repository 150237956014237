@import '../../../../../stylesheets/variables.scss';

.icon {
	width: 160px;
	height: 160px;
}

.text {
	font-size: $fs_xxlarge;
	font-weight: $fw_medium;
}

.button {
	min-width: 176px;
}

.container {
	width: 100%;
	display: flex;
	flex-flow: column;
	flex: 1;
	@include legacyGap(28px, column);
	align-items: center;
	justify-content: center;
}
