.container {
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	min-height: 676px;
}

.title {
	align-self: flex-start;
}

.submit {
	width: 232px;
	align-self: center;
}
