@import '../../../../stylesheets/variables.scss';

.container {
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	min-height: 676px;
}

.title {
	align-self: flex-start;
}

.contentGroup {
	width: 100%;
	display: flex;
	flex-flow: column;
	@include legacyGap(36px, column);

	> * {
		&:not(:first-child) {
			padding-top: 36px;
		}

		&:not(:last-child) {
			padding-bottom: 36px;
			border-bottom: solid 1px #f1f1f3;
		}
	}
}

.content {
	display: flex;
	flex-flow: column;
	@include legacyGap(36px, column);
	width: 100%;
}

.withCopy {
	display: flex;
	justify-content: space-between;

	.copy {
		color: $c_blue_100;
		width: 50%;
		text-align: right;
	}
}
