@import '../../stylesheets/variables.scss';

.container {
	display: flex;
	flex-flow: column;

	@include legacyGap(4px, column);

	.label {
		color: $c_blue_300;
		font-size: $fs_medium;
		font-weight: $fw_medium;
		text-align: left;
	}

	.input {
		display: flex;
		justify-content: space-between;
		border: 1px solid $c_gray_300;
		border-radius: 8px;
		padding: 2px 12px;
		height: 48px;
		font-size: $fs_medium;
		font-weight: $fw_regular;
		line-height: 40px;

		@include legacyGap(12px, row);
	}
	.icon {
		width: 20px;
		height: 20px;
		margin-top: 4px;
	}

	.content {
		display: flex;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: calc(100% - 24px);
		white-space: nowrap;
	}
}
