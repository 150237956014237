@import '../../stylesheets/variables.scss';

.ibanOptions {
	position: absolute;
	border-radius: 16px;
	background-color: #ebf1ff;
	border: 1px solid #3d72fc34;
	padding: 4px;
	left: 50%;
	transform: translate(-50%);
	top: 30px;
	width: 100%;
	display: grid;
	grid-template-columns: 50% 50%;

	@media (min-width: 600px) {
		top: 0;
		width: initial;
	}

	li {
		margin-right: 8px;

		&:last-child {
			margin-right: 0;
		}

		button {
			border-radius: 12px;
			color: $c_blue_100;
			background-color: transparent;
			box-shadow: none;
			text-align: center;
			border: 0;
			font-size: $fs_medium;
			font-weight: $fw_medium;
			text-transform: uppercase;
			padding: 0 24px;
			height: 32px;
			width: 100%;
			cursor: pointer;
			transition: $tr_fast;

			&.active {
				background-color: $c_blue_100;
				color: white;
			}

			&:hover {
				background-color: darken(#ebf1ff, 7%);
			}
		}
	}
}
