@import '../../../stylesheets/variables';

.root {
	display: flex;
	flex-flow: column;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.container {
	padding: 0;
	max-width: 768px;
	width: 100%;
	display: flex;
	flex-flow: column;
	@include legacyGap(8px, column);
}

.title {
	font-size: $fs_xxlarge;
	font-weight: $fw_medium;
	text-align: left;
	max-width: 768px;
	width: 100%;
	padding: 0 40px;
}

.button {
	padding: 0;

	.withIcon {
		font-size: $fs_medium;
		display: flex;
		align-items: center;
		gap: 8px;
	}
}

.section {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.sectionItem {
	display: flex;
	align-items: center;
	padding: 12px 24px;
	gap: 12px;
	width: 100%;

	&:hover {
		background-color: #f5f8ff;
	}

	ul {
		display: block;
		list-style-type: disc;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
	}

	&:not(:last-child) {
		border-bottom: 1px solid $c_gray_400;
	}
}

.bubble {
	display: flex;
	flex: 0 0 8px;
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background-color: $c_blue_100;
	visibility: hidden;

	&.show {
		visibility: visible;
	}
}

.body {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	min-height: 400px;
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	justify-self: end;
	padding: 32px 0;
}
