@import '../../../../stylesheets/variables';

.menu {
	display: flex;
	flex-flow: column;

	.header {
		flex-flow: row;
		display: flex;

		.title {
			color: $c_black;
			font-size: $fs_large;
			font-weight: $fw_medium;
			margin: 24px 0;
			flex: 1;
		}

		.showMore {
			font-size: $fs_small;
			font-weight: $fw_medium;
			align-self: center;
			text-decoration: none;
			color: $c_blue_300;

			&:hover {
				color: $c_blue_transparent;
			}
		}
	}

	&.separators {
		.link:not(:last-child) {
			border-bottom: 1px solid $c_gray_400;
		}
	}

	.link {
		color: $c_blue_500;
		text-decoration: none;
		display: flex;
		flex-flow: row;
		min-height: 100px;
		align-items: center;

		&:hover {
			color: $c_blue_transparent;
		}

		&.active {
			background: #ffffff 0% 0% no-repeat padding-box;
			box-shadow: 0px 6px 10px #4972f40d;
			border-radius: 4px;
		}

		.optionText {
			font-size: $fs_xlarge;
			font-weight: $fw_medium;
			align-self: center;
			flex: 1;
			margin-left: 24px;
		}

		.iconContainer {
			width: 94px;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
