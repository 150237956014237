@import '../../stylesheets/variables.scss';

.list {
	li {
		border-bottom: 1px solid $c_gray_400;
		display: grid;

		&:first-of-type {
			border: none;
			grid-template-columns: 50% 50%;
			padding: 20px 20px 10px;

			@media (min-width: 768px) {
				grid-template-columns: 30% 25% 25% 20%;
			}

			@media (min-width: 992px) {
				padding: 28px 41px 10px;
			}

			span {
				font-size: $fs_medium;
				line-height: 24px;
				font-weight: $fw_medium;
				color: $c_blue_300;
				display: flex;
				align-items: center;
				justify-content: center;

				&:first-of-type {
					justify-content: flex-start;
				}

				&:last-of-type {
					justify-content: flex-end;
				}

				&:nth-child(2),
				&:last-of-type {
					cursor: pointer;
				}
			}
		}

		&:last-of-type {
			border: none;
			border-bottom-right-radius: 8px;
			border-bottom-left-radius: 8px;
			overflow: hidden;
		}

		.item {
			padding: 20px;
			display: grid;
			text-decoration: none;
			grid-template-columns: 50% 50%;

			@media (min-width: 768px) {
				grid-template-columns: 30% 25% 25% 20%;
			}

			@media (min-width: 992px) {
				padding: 28px 41px;
			}

			&:hover {
				background: #f5f8ff; /* Old browsers */
				background: -moz-linear-gradient(
					left,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* FF3.6-15 */
				background: -webkit-linear-gradient(
					left,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(
					to right,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
			}
		}
	}
}

.cardIcon {
	display: flex;
}

.iconContainer {
	margin-right: 13px;
	display: flex;
	align-items: center;
	position: relative;

	.lock {
		position: absolute;
		top: 15px;
		left: 0;
		width: 100%;

		@media (min-width: 768px) {
			top: 2px;
		}
	}

	img {
		&.blocked {
			filter: grayscale(1);
		}

		&.notActive {
			opacity: 0.5;
		}
	}
}

.cardLabel {
	text-align: left;
}

.number {
	font-size: $fs_large;
	line-height: 20px;
	font-weight: $fw_medium;
	color: $c_blue_300;
	display: block;
	margin-top: 13px;

	@media (min-width: 768px) {
		display: initial;
		margin-top: 0;
	}
}

.type {
	display: block;
	font-size: $fs_small;
	line-height: $lh_medium;
	font-weight: $fw_regular;
	color: black;
	text-align: left;
}

.balance,
.date {
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: black;
	display: flex;
	align-items: center;
	justify-content: center;
}

.balance {
	display: block;
	text-align: right;
	margin-top: 15px;

	@media (min-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 0;
	}
}

.status {
	font-size: 10px;
	line-height: 40px;
	font-weight: $fw_medium;
	color: black;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
}

.opacity {
	opacity: 0.5;
}

.button {
	font-size: 10px;
	line-height: 20px;
	text-transform: uppercase !important;
	padding: 0 12px;
	min-width: initial;
	border-radius: 2px;
	height: 26px;
}

.blue {
	color: $c_blue_100;
}

.dots {
	font-size: 24px;
}

.loader {
	margin: 35px auto;
}

.mobileStatus {
	display: block;
	text-align: right;
}
