@import '../../../../../stylesheets/variables.scss';

.container {
	text-align: center;
	padding: 40px;

	button {
		margin: 15px 7.5px 0;
	}
}

.text {
	font-size: $fs_medium;
	font-weight: $fw_regular;
}
