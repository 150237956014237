@import '../../../../stylesheets/variables.scss';

.form {
	width: 100%;
	max-width: 328px;

	@media (max-width: 1200px) {
		margin: 0 auto;
	}

	> div:first-child > label {
		margin-top: 0;
	}
}

.container {
	margin: 32px 0 0;

	padding: 0 20px 20px 20px;

	@media (min-width: 1024px) {
		padding: 0 40px 40px 40px;
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		display: flex;
	}

	@media (min-width: 1200px) {
		display: flex;
	}
}

.column {
	margin: 0 0 40px;

	&:last-child {
		margin: 0;
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		flex: 1 1 calc(50% - 50px);
		margin: 0 50px 0 0;

		&:last-child {
			margin: 0;
		}
	}

	@media (min-width: 1200px) {
		flex: 1 1 calc(50% - 50px);
		margin: 0 50px 0 0;

		&:last-child {
			margin: 0;
		}
	}
}

.heading {
	color: $c_black;
	font-size: $fs_large;
	font-weight: $fs_medium;
	margin: 0 0 24px;
}

.howToDepositContainer {
	margin: 0 0 40px;
	font-size: $fs_small;

	ol,
	ul {
		color: $c_black;
		font-size: $fs_small;
		counter-reset: item;
		list-style-type: none;
		margin: 0;
		padding-left: 5px;
	}

	li {
		color: $c_black;
		display: flex;
		font-size: $fs_small;
		font-weight: $fw_regular;
		margin: 0 0 16px;

		&::before {
			color: $c_black;
			content: counter(item) '  ';
			counter-increment: item;
			font-weight: $fw_medium;
			margin: 0 20px 0 0;
			width: 0;
		}
	}
}

.inputError {
	margin-top: 6px;
	margin-bottom: -6px;
}

.nextButton {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 32px auto 0 !important;
	max-width: 232px;
	width: 232px;
}

.label {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	color: $c_blue_300;
	display: block;
	margin: 32px 0 8px;
}

.checkBoxLabel {
	color: $c_blue_300;
	font-size: $fs_small;
}

.inputGroup {
	input {
		border-radius: 4px;
		border: 1px solid #d9dbe5;
		font-size: $fs_medium;
		height: 40px;
		outline-color: #d9dbe5;
		padding: 0 12px;
		transition: $tr_fast;
		appearance: none;

		&::placeholder {
			color: #c7cdd1;
			font-size: $fs_medium;
		}

		&:focus {
			outline-color: $c_blue_100;
		}
	}
}

.input {
	margin-bottom: 32px;
}

.checkbox {
	margin-top: 32px;
}

.option {
	display: flex;
	align-items: center;
	cursor: pointer;

	* {
		cursor: pointer;
	}

	.currencyIcon {
		width: 20px;
		height: 20px;
		margin: 0 10px 0 0;
	}
}

.message {
	margin: 15px 0 0 0;
}

.loader {
	margin: auto;
}

.receiveAmount {
	position: relative;
	margin: 0 0 40px;
}

.receiveError {
	bottom: -24px;
}
