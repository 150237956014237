@import '../../stylesheets/variables.scss';

.container {
	position: relative;
	min-width: 6px;
	min-height: 6px;
	cursor: pointer;
}

.successText {
	position: absolute;
	bottom: -25px;
	right: 0;
	font-size: $fs_small;
	color: $c_blue;
	text-align: right;
	font-weight: $fw_medium;
	word-break: initial;
	background-color: #fff;
	z-index: 10;
	padding: 0 2.5px;

	@media (min-width: 550px) {
		bottom: initial;
		right: initial;
		top: -4px;
		left: 10px;
	}
}

.copyIcon {
	position: relative;
	cursor: pointer;

	div {
		position: absolute;
		top: 0;
		right: 0;
		width: 8px;
		height: 8px;
		background-color: white;
		border: 1px solid $c_blue;
		z-index: 2;

		&:last-child {
			z-index: 1;
			top: -3px;
			right: -3px;
		}
	}
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}
