@import '../../../../stylesheets/variables';

.container {
	min-height: 360px;
	padding: 26px;

	@media (min-width: 767px) {
		width: 667px;
	}

	.header {
		display: flex;
		justify-content: flex-end;

		.closeIcon {
			cursor: pointer;
		}
	}

	.body {
		display: flex;
		flex-flow: column;
		justify-content: center;
		text-align: center;

		.icon {
			width: 96px;
			height: 96px;
		}

		.text {
			font-size: $fs_xxxlarge;
			font-weight: $fw_medium;
			color: $c_blue_300;
			margin: 32px 0 36px;
		}

		.buttons {
			display: flex;
			flex-flow: column;
			align-items: center;

			.button {
				width: 232px;
				height: 46px;
			}
		}
	}
}
