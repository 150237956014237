@import '../../../../stylesheets/variables.scss';

.container {
	padding: 40px;
	display: grid;
	grid-template-columns: 100%;

	> div {
		text-align: center;
		margin: 0 auto;
		max-width: 360px;
		width: 100%;
	}
}

.button {
	margin: 40px 0;
	max-width: 232px;
	width: 100%;
}

.data {
	text-align: left;
	&:first-child {
		label {
			margin-top: 0;
		}
	}

	label {
		margin: 24px 0 0 0;
		text-align: left;
	}

	> div {
		margin: 6px 0 0 0;
		font-size: $fs_large;
		word-break: break-all;
	}
}

.error {
	font-size: $fs_small;
	margin-top: 8px;
	color: $c_red;
}

.errorContainer {
	margin-top: 25px;
}
