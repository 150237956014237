@import '../../../../stylesheets/variables.scss';

.container {
	position: relative;
}

.icon {
	margin-right: 40px;
}

.back {
	position: absolute;
	top: -110px;
	left: -40px;

	@media (max-width: 500px) {
		top: -95px;
		left: -25px;
	}
}

.title {
	font-size: $fs_xlarge;
	color: $c_blue_300;
	font-weight: $fw_medium;
	margin-bottom: 8px;
	display: block;
}

.price {
	font-weight: $fw_regular;
	font-size: $fs_medium;
	color: black;
	display: block;
	margin-bottom: 4px;

	b {
		font-weight: $fw_medium;
	}
}

.shippingOptions {
	max-width: 380px;
	width: 100%;
	margin: 0 auto 40px;

	li {
		text-align: left;
		border-bottom: 1px solid $c_gray_400;

		&:last-of-type {
			border: none;
		}

		a {
			text-decoration: none;
			display: flex;
			align-items: center;
			min-height: 99px;
			cursor: pointer;

			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}

			&:hover {
				background: #f5f8ff; /* Old browsers */
				background: -moz-linear-gradient(
					left,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* FF3.6-15 */
				background: -webkit-linear-gradient(
					left,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(
					to right,
					#ffffff 0%,
					#f5f8ff 51%,
					#ffffff 100%
				); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
			}
		}
	}
}

.arrow {
	margin-left: auto;
}

.bold {
	color: $c_blue_300;
}

.error {
	color: $c_red;
	font-size: $fs_small;
}
