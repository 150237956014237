@import '../../../../stylesheets/variables.scss';

.container {
	text-align: left;
	border-bottom: 1px solid $c_gray_400;
	display: flex;
	padding: 20px;

	@media (min-width: 992px) {
		padding: 25px 38px;
	}
}

.plus {
	display: inline-flex;
	font-size: $fs_small;
	line-height: 20px;
	font-weight: $fw_medium;
	color: $c_blue_300;
	text-decoration: none;
	background-image: url('./images/plus.svg');
	background-repeat: no-repeat;
	padding-left: 64px;
	height: 40px;
	align-items: center;
	margin-right: 50px;
}

.filterTitle {
	font-size: 10px;
	color: #4e4e4e;
	font-weight: $fw_regular;
	display: block;
	margin-bottom: 8px;
	max-width: 486px;
	margin: 22px auto 8px;
	text-align: center;

	@media (min-width: 992px) {
		margin: 0 0 8px;
		text-align: left;
	}
}

.filter {
	display: block;
	max-width: 486px;
	margin: 0 auto;

	li {
		display: inline-flex;
		text-transform: uppercase;
		font-size: 9px;
		font-weight: $fw_medium;
		margin-right: 8px;
		color: $c_blue_100;
		border: 1px solid $c_blue_100;
		border-radius: 4px;
		min-width: 60px;
		height: 17px;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: $tr_fast;

		&.noMargin {
			margin-right: 0;
			margin-bottom: 8px;
		}

		&:hover,
		&.active {
			animation: glowing 300ms ease;
		}

		&.active,
		&:active,
		&:focus {
			background-color: $c_blue_100;
			color: white;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

@keyframes glowing {
	0% {
		box-shadow: 0 0 3px $c_blue_100;
	}
	50% {
		box-shadow: 0 0 10px $c_blue_100;
	}
	100% {
		box-shadow: 0 0 3px $c_blue_100;
	}
}
