@import '../../../../stylesheets/variables';

.propertyBlock {
	display: flex;
	flex-flow: column;
	width: 100%;
	padding-left: 8px;
	padding-right: 8px;

	> .property {
		display: flex;
		flex-flow: column;
		width: 100%;
		align-items: center;
		font-size: $fs_medium;
		font-weight: $fw_medium;

		.content {
			padding-left: 8px;
			padding-right: 8px;
			display: flex;
			flex-flow: row;
			align-items: center;
			width: 100%;
			min-height: 32px;

			.label {
				font-weight: $fw_regular;
			}

			.value {
				color: $c_blue_300;
				text-align: right;
				display: flex;
				flex: 1;
				justify-content: flex-end;
			}
		}

		&.expandable {
			cursor: pointer;

			.value {
				flex-basis: calc(50% - 34px);
			}

			&.expanded {
				.content {
					background-color: $c_gray_200;
					border-radius: 4px;
				}

				.expander {
					transform: rotateZ(180deg);
				}
			}

			.expander {
				align-self: center;
				display: flex;
				width: 16px;
				margin-left: 16px;
				transition: $tr_fast;
			}
		}

		.expandedContent {
			padding: 8px;
			width: calc(100% - 16px);
			border-bottom: 1px solid $c_gray_400;
		}
	}
}
