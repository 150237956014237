@import '../../../../../stylesheets/variables.scss';

.item {
	color: $c_blue_300;
	font-weight: $fw_medium;
	font-size: $fs_medium;
}

.assets {
	display: flex;
	align-items: center;

	.icon {
		padding: 0;
		margin: 0;
		border: 2px solid white;
		box-shadow: none;

		&.margin {
			margin-left: -7px;
			margin-right: 8px;
		}
	}

	.date {
		display: block;
	}
}

.icon {
	width: 26px;
	height: 26px;
}

.arrow {
	margin: 0 4px;
}

.details {
	display: block;
	font-size: 10px;
	font-weight: $fw_regular;
	color: $c_gray;
	line-height: $lh_medium;

	> span {
		&:first-child {
			margin-right: 10px;
		}
	}

	@media (min-width: 768px) {
		font-size: $fs_small;
	}
}

.amountContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	min-width: 90px;
	margin-left: auto;
	margin-right: 0;
}

.statusText {
	font-size: $fs_small;
	color: $c_gray;
	text-transform: uppercase;
}

.amount {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	text-align: right;
	color: #53c789;

	@media (min-width: 768px) {
		font-size: $fs_large;
	}
}
