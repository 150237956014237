@import '../../stylesheets/variables.scss';

.inputGroup {
	position: relative;
}

.label {
	display: block;
	font-size: $fs_medium;
	color: $c_blue_300;
	margin-bottom: 8px;
	font-weight: $fw_medium;
	line-height: $lh_medium;
}

.input {
	border: 1px solid #e6e8f0;
	border-radius: 4px;
	font-size: $fs_medium;
	line-height: 16px;
	font-weight: $fw_regular;
	transition: $tr_fast;
	padding: 11px 12px;
	width: 100%;

	&.error {
		border-color: $c_red_100;
	}

	> input {
		border: none;
		outline: 0;
	}

	:global {
		.PhoneInputCountryIcon {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			overflow: hidden;
			outline: 0 !important;
			box-shadow: none !important;
			position: relative;
		}

		.PhoneInputCountryIconImg {
			position: absolute;
			top: -5px;
			left: -5px;
			width: 30px;
			height: 30px;
		}

		.PhoneInputCountry {
			pointer-events: none;
			> select {
				display: none;
			}
		}

		.PhoneInputCountrySelectArrow {
			display: none;
		}
	}
}

.errorMessage {
	position: absolute;
	font-size: $fs_small;
	left: 0px;
	bottom: -16px;
	color: $c_red_100;
	height: 12px;
}
