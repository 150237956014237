.BreakWord {
	word-break: break-word;
}

.Ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
}

.NoWrap {
	white-space: nowrap;
}
