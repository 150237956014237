@import '../../../../stylesheets/variables.scss';

.button {
	margin: 0 auto 15px;
	width: 232px;
	display: block;
}

.options {
	max-width: 380px;
	width: 100%;
	margin: 0 auto;

	&.loading {
		opacity: 0.6;
		pointer-events: none;
	}
}

.toggle {
	margin-bottom: 32px;
}

.label {
	display: flex;
}

.title {
	font-weight: $fw_medium;
	line-height: 24px;
	font-size: $fs_large;
	text-align: left;
	color: $c_blue_300;
	margin: 0 auto 8px;
}

.post {
	margin: 0 auto;
	color: black;
	font-size: $fs_small;
	line-height: 21px;
	text-align: left;
	max-width: 210px;
	width: 100%;
}

.icon {
	width: 48px;
	margin-right: 16px;
}

.additionalInfo {
	border: 1px solid #ffb063;
	color: black;
	font-size: $fs_small;
	line-height: 21px;
	padding: 0 15px 0 50px;
	display: flex;
	max-width: 316px;
	width: 100%;
	margin-left: auto;
	margin-bottom: 32px;
	text-align: right;
	position: relative;
}

.exclamationMark {
	background-color: #ffb063;
	width: 25px;
	height: 25px;
	position: absolute;
	top: calc(50% - 12.5px);
	left: 15px;
	border-radius: 50%;

	&:before {
		content: '!';
		color: white;
		position: absolute;
		top: 3px;
		font-size: 15px;
		font-weight: $fw_medium;
		left: calc(50% - 1.5px);
	}
}
