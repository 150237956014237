.loaderContainer {
	width: 100%;
	text-align: center;
	margin-top: 150px;

	@media (min-width: 992px) {
		margin-top: 0;
	}
}
.loader {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.container {
	max-width: 1160px;
	width: 100%;
	margin: 20px auto 0;

	@media (min-width: 991px) {
		margin: 0 auto;
	}
}

.flexGrid {
	display: block;

	@media (min-width: 992px) {
		display: flex;
	}
}

.sidebar {
	display: block;

	@media (min-width: 992px) {
		flex: 1;
	}
}

.content {
	display: block;
	margin-bottom: 40px;
	width: 100%;
	position: relative;
	text-align: center;

	@media (min-width: 992px) {
		margin-left: 16px;
		flex: 3;
	}

	&.noSidebar {
		display: block;
		margin: 0 auto;
		flex: initial;
		max-width: 768px;
		width: 100%;
	}
}

.backButton {
	position: absolute;
	top: -30px;
	left: 0;
}

.errorContainer {
	width: 100%;
	background-color: transparent;
	box-shadow: none;
}
