// colors
$c_blue: #3d72fc;
$c_blue_transparent: #6c8bf5;
$c_blue_10: #ebf1ff;
$c_blue_50: #c8d4fc;
$c_blue_100: #4972f4;
$c_blue_200: #5048bf;
$c_blue_250: #183373;
$c_blue_300: #102d6f;
$c_blue_400: #121b45;
$c_blue_500: #122e70;
$c_gray: #757575;
$c_gray_100: #f9f9fc;
$c_gray_200: #f5f8ff;
$c_gray_300: #e6e8f0;
$c_gray_400: #e6e6e6;
$c_gray_500: #b3b6c6;
$c_gray_600: #4e4e4e;
$c_cyan_100: #60dfcf;
$c_cyan_200: #97c5d3;
$c_green: #53c789;
$c_orange: #fa9419;
$c_orange_100: #ffb063;
$c_red: #dd4b64;
$c_red_100: #ff8770;
$c_white: #ffffff;
$c_yellow: #fcea81;
$c_black: #000000;
$c_black_100: #121a44;

// font-weight
$fw_regular: 400;
$fw_medium: 500;
$fw_bold: 700;

// font-size
$fs_xsmall: 10px;
$fs_small: 12px;
$fs_medium: 14px;
$fs_large: 16px;
$fs_xlarge: 18px;
$fs_xxlarge: 20px;
$fs_xxxlarge: 24px;
$fs_huge: 28px;

// line-height
$lh_regular: 20px;
$lh_medium: 22px;
$lh_large: 24px;

// transitions
$tr_fast: all 0.1s, visibility 0s;
$tr_default: all 0.3s ease, visibility 0s;
$tr_slow: all 0.7s ease, visibility 0s;

@mixin legacyGap($size, $direction) {
	> * {
		&:not(:last-child) {
			@if $direction == 'row' {
				margin-right: $size;
			}
			@if $direction == 'column' {
				margin-bottom: $size;
			}
		}
	}
}

@mixin mobile {
	@media (max-width: 540px) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: 541px) and (max-width: 991px) {
		@content;
	}
}

@mixin tablet-wide {
	@media (min-width: 992px) and (max-width: 1200px) {
		@content;
	}
}

@mixin monitor {
	@media (min-width: 1201px) {
		@content;
	}
}
