.container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.button {
	margin-top: 8px;
	display: block;
}

.buttonWidth {
	width: 280px;
}

.successMessage {
	margin-top: 8px;
}
