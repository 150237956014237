@import '../../stylesheets/variables.scss';

.container {
	background-color: #ffffff;
	bottom: 0;
	box-shadow: 0 -4px 20px rgba(16, 45, 111, 0.16);
	font-family: 'Red Hat Text', sans-serif;
	position: fixed;
	text-align: left;
	width: 100%;
	z-index: 99;
}

.body {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin: auto;
	max-width: 1440px;
	padding: 24px 20px 30px;

	@media (min-width: 992px) {
		flex-direction: row;
	}
}

.content {
	@media (min-width: 992px) {
		margin: 0 105px 0 0;
	}
}

.title {
	color: #102d6f;
	font-size: $fs_xlarge;
	font-weight: $fw_medium;
	line-height: 23px;
	margin: 0 0 16px;
}

.text {
	color: #000000;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	line-height: $lh_medium;
	margin: 0;

	a {
		color: #4972f4;
		font-weight: bold;
		text-decoration: none;
	}
}

.buttonGroup {
	display: flex;
	flex-direction: column;
	padding: 30px 0 0;

	@media (min-width: 992px) {
		justify-content: center;
		margin-left: auto;
		padding: 16px 0;
	}
}

@mixin button {
	font-size: $fs_medium;
	font-weight: 600;
	min-width: 240px;
	outline: none;
	white-space: nowrap;

	@media (min-width: 992px) {
		min-width: 150px;
	}
}

.button {
	@include button;
	height: 50px;
	margin: auto auto 20px;
	outline: none;
}

.buttonLink {
	@include button;
	margin: 0 auto;
	padding: 0;
	font-size: $fs_small;
}
