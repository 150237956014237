@import '../../../../stylesheets/variables';

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px 40px 40px 40px;

	&.mobile {
		padding: 20px 20px 20px 20px;
	}

	form {
		width: 100%;
	}
}

.deleteButton {
	color: $c_blue_300 !important;
	font-size: $fs_small !important;
	font-weight: $fw_medium !important;
	padding: 0;
	height: auto;
	min-width: 0;
	display: block;
	margin-left: 26px;

	&:hover,
	&:focus {
		color: $c_blue_200 !important;
	}

	@media (max-width: 767px) {
		margin-left: 16px;
	}
}

.modalCloseButton {
	width: 232px;
	height: 46px;
	font-size: $fs_medium;
	font-weight: $fw_medium !important;
	margin-top: 16px;
}
