@import '../../../../../stylesheets/variables.scss';

.list {
	margin-top: 28px;

	li {
		display: grid;
		color: $c_blue_300;
		font-weight: $fw_medium;
		font-size: $fs_medium;
		padding: 20px 0;
		border-bottom: 1px solid #e6e8f0;
		grid-template-columns: 100%;

		@media (min-width: 600px) {
			grid-template-columns: 40% 20% 20% 20%;
		}

		&:first-child {
			padding: 0;
			border-bottom: none;
			pointer-events: none;
			padding-bottom: 7px;
			display: none;

			@media (min-width: 992px) {
				display: grid;
			}
		}
		&:last-child {
			border-bottom: none;
		}
	}
}
