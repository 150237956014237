.container {
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 6px 10px #4972f40d;
	position: relative;

	@media (max-width: 979px) {
		margin: 40px 0;
	}

	@media (min-width: 500px) {
		padding: 40px;
	}
}
