@import '../../../../stylesheets/variables';

.label {
	color: $c_blue_300;
	display: flex;
	justify-content: space-between;
	font-size: $fs_small;
	font-weight: $fw_medium;
	margin: 0 0 8px;
}

.mt10 {
	margin-top: 10px;
}

.shareAddressLabel {
	@media (min-width: 1200px) {
		margin-left: 24px;
	}
}

.shareAddressList {
	list-style-type: none;
	margin: 0;
	padding: 0;

	@media (max-width: 1200px) {
		display: flex;
		flex-wrap: wrap;
	}
}

.shareAddressListItem {
	border-bottom: 1px solid #f1f1f3;
	cursor: pointer;
	margin-top: 4px;
	padding: 12px 24px;

	&:last-child {
		border-bottom: none;
	}

	&:hover {
		.shareAddressListItemIcon,
		.shareAddressListItemName {
			color: $c_blue_200;
		}
	}

	@media (max-width: 1200px) {
		flex: 50%;
		padding: 12px 0;
		border-bottom: none;
	}
}

.shareAddressListItemLink {
	align-items: center;
	display: flex;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.shareAddressListItemIcon {
	color: $c_blue_100;
	font-size: 20px;
	margin: 0 16px 0 0;
	transition: $tr_fast;
	width: 24px !important;
}

.shareAddressListItemName {
	color: $c_blue_100;
	font-size: $fs_large;
	font-weight: $fw_medium;
	transition: $tr_fast;
}

.copyButtonContainer {
	margin: 32px 0 10px;
}

.copyButtonWrapper {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: 0 0 8px;
}

.copyButton {
	background: none;
	border: none;
	color: $c_blue_100;
	font-size: $fs_small;
	font-weight: $fw_medium;
	margin: 0;
	outline: none;
	padding: 0;
	transition: $tr_fast;

	&:hover,
	&:focus {
		color: $c_blue_200;
	}
}

.copyButtonLabel {
	color: $c_blue_300;
	display: block;
	font-size: $fs_small;
	font-weight: $fw_medium;
}

.copyButtonValue {
	position: relative;
	color: $c_black;
	font-size: $fs_large;
	font-weight: $fw_regular;
	max-width: 100%;
	word-break: break-word;

	@media (min-width: 768px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.getNewAddressButton {
	cursor: pointer;
	background: none;
	border: none;
	color: $c_blue_100;
	font-size: $fs_small;
	font-weight: $fw_medium;
	margin: 12px 0;
	height: 20px;
	outline: none;
	padding: 0;
	display: flex;
	align-items: center;
	transition: $tr_fast;

	> .loader {
		margin-left: 5px;
	}

	&:hover,
	&:focus {
		color: $c_blue_200;
	}
}

.separator {
	border-bottom: 1px solid $c_gray_400;
	margin: 0 0 24px;
	width: 100%;
}

.importPrivateKey {
	color: $c_black;
	font-size: $fs_medium;
	font-weight: $fw_regular;
}

.importPrivateKeyButton {
	color: $c_blue_100;
	font-weight: $fw_medium;
	text-decoration: none;
	transition: $tr_fast;

	&:hover,
	&:focus {
		color: $c_blue_200;
		text-decoration: none;
	}
}

.qrCode {
	display: flex;
	justify-content: center;
	margin: 49px 0;
}

.body {
	padding: 0 40px;
	margin: 20px 0 50px 0;

	@media (min-width: 1200px) {
		display: flex;
	}
}

.column {
	@media (min-width: 1200px) {
		flex: 1 1 calc(50% - 80px);
		margin: 0;

		&:first-child {
			min-width: 328px;
			width: 100%;
			margin-right: 15px;
		}

		&:last-child {
			margin: 0;
			margin-left: 15px;
		}
	}
}

.paragraph {
	align-items: center;
	color: $c_black;
	display: flex;
	font-size: $fs_small;
	font-weight: $fw_regular;
	margin: 10px 0 0;
}

.copyButtonAddressContainer {
	margin: 0;
}

.exclamationIcon {
	color: #ff8d83;
	font-size: 20px;
	margin-right: 12px;
}

.shareAddressColumn {
	margin: 32px 0 0;
}

.loader {
	margin: auto;
}

.opacity {
	opacity: 0.4;
}

.error {
	font-weight: $fw_regular;
	margin-left: 5px;
	color: $c_red;
	font-size: $fs_small;
}

.list {
	margin-top: 8px;
	display: flex;
	list-style: none;
	min-width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;

	li {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 12px 24px;
		border-radius: 4px;
		border: 2px solid $c_gray_300;
		font-weight: $fw_medium;
		font-size: $fs_medium;
		flex: 1;
		align-items: center;
		color: $c_blue_300;
		cursor: pointer;
		transition: $tr_fast;

		&:first-child {
			margin-right: 8px;
		}

		&:hover,
		&.selected {
			border: 2px solid $c_blue;
		}
	}
}

.selectInputGroup {
	width: 100%;
	max-width: initial;
	margin: 0 auto 32px;

	@media (min-width: 768px) {
		max-width: 380px;
	}
}

.select {
	display: flex;
	align-items: center;
	margin-top: 8px;
	height: 40px;
}

.bold {
	font-weight: $fw_medium;
	margin-right: 5px;
	font-size: $fs_medium;
}

.text {
	font-size: $fs_medium;
}

.option {
	display: flex;
	align-items: center;
	height: 40px;

	.icon {
		width: 20px;
		height: 20px;
	}
}

.orSeparator {
	margin: 31px 0 19px;
	display: flex;
	justify-content: center;
	align-items: center;

	.line {
		width: 100%;
		height: 1px;
		background-color: $c_gray_400;
	}

	span {
		margin: 0 15px;
	}
}

.buyModal {
	display: flex;
	flex-direction: column;

	button {
		margin: 10px auto 16px;
	}

	iframe {
		border: none;
		width: 420px;

		@media (max-width: 767px) {
			width: 100%;
		}
	}
}

.buyButton {
	width: 100%;

	img {
		margin-right: 8px;
	}
}
