.button {
	width: 232px;
	margin: 0 auto 40px;
}

.back {
	position: absolute;
	top: -110px;
	left: -40px;
}
