@import '../../../../stylesheets/variables.scss';

.container {
	position: relative;
}

.back {
	position: absolute;
	top: -110px;
	left: -40px;

	@media (max-width: 500px) {
		top: -95px;
		left: -25px;
	}
}

.title {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	line-height: $lh_medium;
	color: $c_blue_300;
	margin-bottom: 8px;
}

.dataBox {
	max-width: 380px;
	margin: 0 auto 40px;
	width: 100%;
	text-align: left;
}

.value {
	font-size: $fs_large;
	line-height: 24px;
	font-weight: $fw_regular;
	color: black;
}

.button {
	width: 232px;
	margin: 0 auto 14px;
}

.editBtn {
	display: block;
	margin: 0 auto 50px;
	font-size: $fs_medium;
	font-weight: $fw_medium !important;
}

.form {
	max-width: 380px;
	margin: 0 auto;
	width: 100%;
}

.label {
	font-size: $fs_medium;
	line-height: $lh_medium;
	font-weight: $fw_medium;
	color: $c_blue_300;
}

.input {
	width: 100%;
	margin-bottom: 32px;
}

.deliverySelect {
	width: 100%;
	margin-bottom: 50px;
}

.fullWidth {
	width: 100%;
}

.saved {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 16px;
	border: 1px solid $c_blue_100;
	margin-bottom: 32px;

	img {
		margin-right: 16px;
	}

	p {
		font-size: $fs_small;
		font-weight: $fw_regular;
		color: black;
	}
}
