@import '../../../../stylesheets/variables';

.form {
	max-width: 438px;
	width: 100%;
	display: flex;
	flex-flow: column;
	@include legacyGap(32px, column);

	.disclaimer {
		display: block;
	}
}

.label {
	color: $c_blue_300;
	font-size: $fs_medium;
	font-weight: $fw_medium;
}

.labelWithTooltip {
	display: flex;
	@include legacyGap(8px, row);
	align-items: center;
}

.tooltip {
	background-color: $c_blue_300;
	color: white;
	font-size: $fs_small;
	font-weight: $fw_regular;
	border-radius: 4px;
	padding: 12px 24px;
}

.publicKey {
	display: flex;
	flex-wrap: wrap;
	@include legacyGap(8px, column);

	.key {
		flex-basis: 100%;
		height: 140px;
	}

	.action {
		font-size: $fs_small;
		font-weight: $fw_medium;
		color: $c_blue_100;
		cursor: pointer;
	}

	.publicKeyActions {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}

.acceptCurrencies {
	display: flex;
	flex-flow: column;
	@include legacyGap(8px, column);
}

.mode {
	font-size: $fs_large;
	font-weight: $fw_medium;
	padding-top: 32px;
	border-top: 1px solid $c_gray_400;
	@include legacyGap(16px, column);
}

.link {
	color: $c_blue;
	text-decoration: none;
	font-weight: $fw_medium;
}

.testContainer {
	display: flex;
	flex-flow: column;
	gap: 16px;

	.testTitle {
		color: #7f95ba;
		font-size: $fs_medium;
	}

	.testLabel {
		font-size: $fs_medium;
		font-weight: $fw_regular;
	}
}
