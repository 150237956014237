@import '../../../../stylesheets/variables';

.container {
	width: 100%;
	min-height: 380px;
	display: flex;
	flex-flow: column;
}

.host {
	@include legacyGap(16px, column);
	display: flex;
	flex-flow: column;
	max-width: 768px;
}

.content {
	display: flex;
	flex-wrap: wrap;
	@include legacyGap(16px, column);
	width: 100%;
}

.notFound {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	height: 100%;
	@include legacyGap(20px, column);

	img {
		width: 246px;
		height: 156px;
	}

	span {
		font-size: $fs_medium;
		color: $c_gray_500;
	}
}

.actions {
	display: flex;
	justify-content: center;
	flex-flow: column;
	flex-basis: 100%;
	align-items: center;

	@media (min-width: 768px) {
		flex-flow: row;
		@include legacyGap(8px, row);
	}

	@media (max-width: 767px) {
		@include legacyGap(8px, column);
	}

	.action {
		font-size: $fs_medium;
		min-width: 174px;
	}
}

.idBlock {
	flex-flow: column;
	align-items: center;
	text-align: center;
	font-size: $fs_medium;
	@include legacyGap(16px, column);

	@media (min-width: 768px) {
		flex-basis: 50%;
	}

	.label {
		display: flex;
		gap: 8px;
	}

	.value {
		font-weight: $fw_medium;
		color: $c_blue_500;
	}
}

.mode {
	flex-flow: wrap;
	align-items: center;
	justify-content: flex-end;

	> * {
		margin-left: 8px;
		margin-bottom: 8px;
	}
}

.autoconvert {
	font-weight: $fw_medium;
	color: $c_blue_500;

	.autoconvertIcon {
		height: 18px;
		width: 18px;
	}
}

.acceptedCurrencies {
	display: flex;
	@include legacyGap(8px, row);
	flex-wrap: wrap;
}
