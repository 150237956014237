@import '../../../../stylesheets/variables';

.container {
	display: flex;
	flex-flow: column;
}

.body {
	display: flex;
	flex-flow: column;
	gap: 16px;
}

.content {
	display: flex;
	min-height: 200px;
}

.note {
	font-size: $fs_small;
}

.stronk {
	font-weight: $fw_medium;
}
