@import '../../../../stylesheets/variables.scss';

.container {
	max-width: 380px;
	width: 100%;
	padding-bottom: 100px;
	margin: 0 auto;
	text-align: center;
}

.img {
	margin: 32px auto 40px;
}

.title {
	font-size: 20px;
	font-weight: $fw_medium;
	line-height: 28px;
	margin-top: 39px;
}

.button {
	min-width: 176px;
	margin-top: 40px;
	font-size: $fs_medium;
	text-transform: initial !important;
}

.link {
	color: $c_blue_100;
	font-size: $fs_medium;
	line-height: 21px;
	font-weight: $fw_medium;
	margin: 24px 0 0;
	display: block;
	text-decoration: none;
	transition: $tr_fast;

	&:hover {
		color: $c_blue_300;
	}
}

.info {
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: black;
	margin: 0;

	strong {
		font-weight: $fw_medium;
	}
}
