@import '../../../../stylesheets/variables';
@import '../../../../components/Input/Input.module.scss';

.container {
	display: flex;
	flex-flow: column;
	gap: 32px;
	align-items: center;
	padding: 32px;
	text-align: center;
	font-size: $fs_medium;

	@include tablet-wide {
		width: 610px;
	}

	@include monitor {
		width: 610px;
	}
}

.icon {
	width: 96px;
	height: 96px;
}

.closeIcon {
	position: absolute;
	top: 24px;
	right: 24px;
	width: 24px;
	height: 24px;
	cursor: pointer;
	color: $c_blue_300;
}

.title {
	display: flex;
	flex-flow: column;
	font-size: $fs_huge;
	font-weight: $fw_medium;
	color: $c_blue_300;
	gap: 8px;
}

.remainingLinks {
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: $c_gray_500;
}

.content {
	display: flex;
	flex-flow: column;
	gap: 32px;
	width: 100%;
	padding: 8px;
}

.actions {
	display: flex;
	flex-flow: column;
	gap: 16px;
}

.label {
	color: $c_black;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	margin: 0;
}

.button {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	min-width: 232px;
}

.inputGroup {
	max-width: 100%;
}

.value {
	text-align: left;
	display: flex;
	width: 100%;
}

.split {
	display: flex;
	flex-flow: column;
	gap: 12px;

	.splitInfo {
		display: flex;
		justify-content: space-between;

		span {
			font-size: $fs_medium;

			strong {
				font-weight: $fw_medium;
			}
		}
	}
}
