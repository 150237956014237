@import '../../../stylesheets/variables.scss';

.heading {
	color: #7b7e93;
	font-size: $fs_large;
	font-weight: $fw_medium;
	margin: 30px 0 24px;
}

.listContainer {
	position: relative;

	padding: 0 20px 20px 20px;

	@media (min-width: 1024px) {
		padding: 0 40px;
	}
}

.list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.transparentLoader {
	height: 100%;
	left: 0;
	opacity: 0.75;
	position: absolute;
	top: 0;
	width: 100%;
}
