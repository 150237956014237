@import '../../../../stylesheets/variables';

.container {
	display: flex;
	font-size: $fs_medium;
	font-weight: $fw_medium;
	color: $c_blue_500;
	flex-flow: column;
	align-items: flex-start;
	@include legacyGap(8px, column);

	.mode {
		display: flex;
		flex-flow: row;
		@include legacyGap(8px, row);
		align-items: center;

		.icon {
			width: 18px;
			height: 18px;
			flex: 0 0 auto;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.label {
			display: flex;
			flex: 0 0 auto;
		}
	}
}
