@import '../../../stylesheets/variables';

.heading {
	color: $c_black;
	font-size: $fs_large;
	font-weight: $fw_medium;
	margin: 0 0 24px;
}

.row {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: 0 0 16px;
}

.label {
	color: $c_black;
	font-size: $fs_small;
	font-weight: $fw_medium;
	margin: 0;
}

.value {
	color: $c_black;
	font-size: $fs_small;
	font-weight: $fw_regular;
	margin: 0;
}
