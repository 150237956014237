@import '../../../../stylesheets/variables.scss';

.progress {
    margin-bottom: 24px;
    
    li {
        display: inline-block;
        height: 2px;
        width: 25px;
        background-color: rgba($c_blue_100, .5);   
        border-radius: 0.5px;
        margin-right: 4.8px;
        position: relative;

        @media (min-width: 768px) {
            width: 60px;
        }

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            transition: $tr_slow;
            background-color: $c_blue_100;
        }

        &.active {
            &:before {
                width: 100%;
            }
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}