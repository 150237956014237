@import '../../../stylesheets/variables.scss';

.container {
	padding: 40px;
	width: 100%;
	position: relative;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 40px 80px;
		width: 522px;
	}

	@media (min-width: 992px) {
		height: auto;
		width: 784px;
		max-width: initial;
	}
}

.closeIcon {
	position: absolute;
	top: 24px;
	right: 10px;
	width: 24px;
	height: 24px;
	z-index: 10;
	cursor: pointer;

	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 18px;
		height: 3px;
		background: $c_blue_300;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}

.header {
	text-align: center;
}

.transactionName {
	font-size: 28px;
	word-break: break-word;
	font-weight: $fw_medium;
	color: $c_blue_300;
	margin: 25px auto 8px;
}

.id {
	margin: 0 auto 4px;
	font-size: $fs_large;
	font-weight: $fw_regular;
	color: black;
	word-break: break-word;
}

.date {
	color: $c_gray;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	margin: 0 auto 4px;
}

.status {
	color: black;
	font-size: $fs_large;
	font-weight: $fw_regular;
	margin: 0 auto;
	text-transform: capitalize;
}

.content {
	margin: 32px auto;
	text-align: left;
}

.label {
	margin-bottom: 8px;
	font-size: $fs_large;
	font-weight: $fw_medium;
	color: $c_blue_300;
	display: flex;
	width: 100%;

	> * {
		&:first-child {
			margin-left: 6px;
			cursor: pointer;
		}

		&:last-child {
			height: 100%;
			display: flex;
			flex: 2 3;
			justify-content: flex-end;
			text-align: right;
			color: $c_blue;
			font-size: $fs_medium;
		}
	}
}

.value {
	margin-bottom: 24px;
	word-break: break-word;
	color: black;
	font-size: $fs_large;
	font-weight: $fw_regular;
}

.detailsHeading {
	margin-bottom: 16px;
	color: black;
	font-weight: $fw_medium;
	font-size: $fs_large;
}

.amount {
	font-size: 24px;
	font-weight: $fw_medium;
	margin: 0;

	&.green {
		color: $c_green;
	}
}

.text {
	font-weight: $fw_regular;
	margin: 8px auto;
	font-size: $fs_medium;
}

.iconContainer {
	margin: 0 auto;
	display: inline-flex;
	padding: 15px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: rgba($c_blue, 0.1);
}

.block {
	display: block;
	text-align: center;
}

.toolTipContainer {
	display: flex;
	flex-direction: column;
	align-items: center;

	.toolTipBottomArrow {
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;

		border-top: 8px solid $c_blue_300;
	}

	.toggleToolTip {
		background-color: $c_blue_300;
		border-radius: 4px;
		color: white;
		padding: 12px 22px;
		font-size: $fs_medium;
		font-weight: $fw_regular;
		line-height: 20px;
		text-align: center;
	}
}

.originalPaymentContainer {
	.toggleContainer {
		display: flex;
		align-items: center;
		cursor: pointer;

		h1 {
			color: $c_blue_300;
			font-size: $fs_large;
			font-weight: $fw_medium;
			margin: 0 8px 0 0;
			cursor: pointer;
		}

		svg {
			color: $c_blue_300;
			transition: 0.2s ease-in-out;
		}

		// .open {
		// 	transform: rotate(180deg);
		// }
	}

	.originalPaymentDetails {
		margin-top: 16px;
		max-height: 0;
		transition: max-height 0.2s ease-in-out;
	}
}

.expanded {
	svg {
		transform: rotate(180deg);
	}

	.originalPaymentDetails {
		max-height: 100%;
	}
}
