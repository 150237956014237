@import '../../../../stylesheets/variables.scss';

.icon {
	color: white;
}

.dropdown {
	position: absolute;
	padding: 12px;
	width: auto;
	z-index: 3;
	top: 46px;
	left: 0;
	border: 1px solid #e6e8f0;
	border-radius: 8px;
	background-color: white;
	box-shadow: 0 10px 10px #4972f40f;

	li {
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		cursor: pointer;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.checkbox {
	width: 20px;
	height: 20px;
	border: 1px solid #c8d4fc;
	border-radius: 4px;
	margin-right: 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	&.active {
		background-color: $c_blue_100;
	}
}

.option {
	font-size: $fs_small;
	font-weight: $fw_medium;
	height: 24px;
	display: inline-flex;
	align-items: center;
	border-radius: 4px;
	padding: 0 8px;
	text-transform: capitalize;
	background-color: #ebf1ff;
	color: #4972f4;

	&.PENDING {
		background-color: rgba(#fa9419, 15%);
		color: #fa9419;
	}
	&.EXPIRED {
		background-color: #fff3f0;
		color: #ed654a;
	}
	&.PAID {
		background-color: #edf9f3;
		color: #53c789;
	}
	&.FAILED {
		background-color: rgba(#f23961, 10%);
		color: #f23961;
	}
	&.CANCELLED {
		background-color: rgba(#504d4e, 10%);
		color: #504d4e;
	}
}
