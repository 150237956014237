@import '../../../../../stylesheets/variables.scss';

.item {
	padding: 50px 32px 20px !important;
	align-items: flex-start !important;
	cursor: default !important;
	border-radius: 8px;
	display: flex;
	position: relative;
	background: initial;

	@media (min-width: 600px) {
		padding: 20px 32px !important;
	}

	&:nth-child(odd) {
		background: #f5f8ff;

		&:hover {
			background: #f5f8ff !important;
		}
	}

	&:last-child {
		margin-bottom: 20px;
	}

	&:hover {
		background: initial !important;
	}
}

.name {
	font-size: $fs_medium;
	text-align: left;
	color: $c_blue_300;
	line-height: 24px;
	font-weight: $fw_regular;
	margin-bottom: 8px;
}

.text {
	font-size: $fs_xsmall;
	text-align: left;
	color: black;
	max-width: 428px;
	line-height: 16px;
	font-weight: $fw_regular;
}

.buttonList {
	position: absolute;
	top: 20px;
	left: 32px;

	@media (min-width: 600px) {
		right: 32px;
		left: initial;
	}

	li {
		display: inline-flex;
		background: initial !important;
		font-size: $fs_small;
		font-weight: $fw_medium;
		margin-right: 16px;
		padding: 0;

		a {
			color: $c_blue_100;
			text-decoration: none;

			&:hover {
				color: darken($c_blue_100, 10%);
			}
		}

		&:last-child {
			color: $c_red_100;

			&a:hover {
				color: darken($c_red_100, 10%);
			}
		}
	}
}
