@import '../../../../stylesheets/variables';

.container {
	padding: 0;
	display: flex;
	flex-flow: column;
}

.body {
	display: flex;
	flex-flow: column;
	padding: 26px 26px 8px 26px;
	@include legacyGap(16px, column);
}

.header {
	background-color: $c_blue_10;
	padding: 18px 0;
	border-radius: 8px 8px 0 0;

	.icon {
		max-width: 204px;
	}
}

.refLink {
	font-weight: $fw_medium;
	font-size: $fs_medium;
	text-overflow: ellipsis;
	overflow: hidden;
}

.actions {
	display: flex;
	flex-flow: column;
	@include legacyGap(8px, column);

	.button {
		font-weight: $fw_medium;
		font-size: $fs_medium;

		.icon {
			margin: 0 4px;

			[dir='rtl'] & {
				transform: rotate(180deg);
			}
		}
	}
}

.title {
	font-size: $fs_xxlarge;
	font-weight: $fw_medium;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.text {
	font-size: $fs_small;
}

.earn {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	background-color: $c_blue_10;
	color: $c_blue_100;
	padding: 12px 16px;
	border-radius: 8px;
}
