.inputContainer {
	pointer-events: none;
	top: 22px;
	left: 20px;
	color: #c7cdd1;
	position: absolute;
	z-index: 55;
}

.searchInput {
	max-width: 362px;
	width: 100%;
}
