@import '../../../stylesheets/variables.scss';

.container {
	padding: 40px 185px;
}

.modalIcon {
	width: 96px;
	height: 96px;
	margin: 40px auto 25px;
}

.post {
	font-size: 24px;
	text-align: center;
	color: $c_blue_300;
	margin-bottom: 10px;
}

.details {
	font-size: $fs_medium;
	text-align: center;
	color: $c_black;
	margin-bottom: 40px;
}

.submitButton {
	max-width: 232px;
	width: 100%;
	height: 46px;
	margin: 0 auto 24px;
	display: block;
}

.no {
	color: $c_blue_100;
	text-align: center;
	cursor: pointer;
	font-size: $fs_medium;
	font-weight: 600;
}

.outterBg {
	background-color: #c9d5fc;
	border-radius: 50%;
	width: 96px;
	height: 96px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;

	.innerBg {
		background-color: #4b74f4;
		border-radius: 50%;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			width: 45px;
			height: 45px;
		}
	}
}
