@import '../../../../../stylesheets/variables.scss';

.item {
	color: $c_blue_300;
	font-size: $fs_medium;
	font-weight: $fw_medium;
	border-bottom: 1px solid #e6e6e6;
	position: relative;

	@media (min-width: 630px) {
		height: 94px;
	}

	&:last-child {
		border-color: transparent;
	}

	> div {
		display: grid;
		grid-template-columns: 40% 60%;

		@media (min-width: 630px) {
			display: flex;
			align-items: center;
		}
	}
}

.assets {
	display: flex;
	align-items: center;
	cursor: initial;
	margin-bottom: 19px;

	@media (min-width: 600px) {
		padding-right: 25px;
		margin-bottom: 0;
	}

	.icon {
		padding: 0;
		margin: 0;
		border: 2px solid white;
		box-shadow: none;

		&.margin {
			margin-left: -7px;
			margin-right: 8px;
		}
	}
}

.icon {
	width: 26px !important;
	height: 26px !important;
}

.status {
	font-size: $fs_small;
	font-weight: $fw_medium;
	height: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	padding: 0 8px;
	text-transform: capitalize;
	background-color: #ebf1ff;
	color: #4972f4;

	&.EXPIRED {
		background-color: #fff3f0;
		color: #ed654a;
	}
	&.PAID {
		background-color: #edf9f3;
		color: #53c789;
	}
	&.CANCELLED {
		background-color: rgba(#f23961, 10%);
		color: #f23961;
	}
	&.network {
		margin-top: 4px;
		display: inline-flex;
		font-size: $fs_xsmall;
	}
}

.addressContainer {
	display: flex;
	align-items: center;
}

.address {
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 140px;
}

.copy {
	display: inline-block;
	margin-left: 8px;
}

.amount {
	display: flex;
	align-items: flex-start;
	cursor: initial;
	color: black;
	font-weight: $fw_regular;
	word-break: break-all;
	padding: 0 10px 0 0;
}

.arrow {
	margin: 0 4px;
}

.label {
	font-size: $fs_small;
	color: black;
	font-weight: $fw_regular;
	margin-left: 13px;
}

.grid {
	display: grid;
	grid-template-columns: 40% 60%;
	margin-bottom: 19px;
	cursor: initial;

	@media (min-width: 600px) {
		grid-template-columns: 100%;
		margin-bottom: 0;

		> span:first-child {
			display: none;
		}
	}
}

.firstCol {
	@media (min-width: 600px) {
		display: none;
	}
}

.lastCol {
	display: none;

	@media (min-width: 600px) {
		display: block;
	}
}

.link {
	color: $c_blue_300;
	position: absolute;
	bottom: 10px;
	right: 10px;

	@media (min-width: 630px) {
		position: static;
		margin-left: auto;
		margin-right: 0;
	}
}
