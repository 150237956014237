@import '../../../../stylesheets/variables';

.button {
	display: flex;
	flex-flow: row;
	text-decoration: none;
	padding: 16px 0;
}

.data {
	display: flex;
	flex-flow: column;
	flex: 1;
}

.action {
	align-self: center;
	flex-basis: 20px;
}

.labeled {
	align-items: center;
	padding: 8px 16px;
}

.label {
	text-decoration: none;
	font-size: $fs_medium;
	font-weight: $fw_medium;
	color: $c_blue_250;
}
