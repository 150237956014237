@import '../../../../../stylesheets/variables';

.container {
	height: 151px;
	padding: 26px;
	word-break: break-all;

	@media (min-width: 767px) {
		width: 667px;
	}

	.header {
		display: flex;
		justify-content: flex-end;

		.closeIcon {
			cursor: pointer;
		}
	}

	.body {
		display: flex;
		flex-flow: column;
		padding: 0 54px;
		@include legacyGap(8px, column);

		.label {
			color: $c_blue_300;
			font-weight: $fw_medium;
			position: relative;
		}

		.copyButton {
			position: absolute;
			right: 0;
			top: 8px;
		}
	}
}
