@import '../../../../stylesheets/variables.scss';

.wrapper {
	padding-top: 25px;
}

.button {
	margin: 0 auto 15px;
	width: 232px;
	display: block;
}

.postContainer {
	max-width: 380px;
	width: 100%;
	margin: 40px auto;
	display: flex;
	justify-content: center;
	font-size: $fs_medium;
	line-height: 20px;
	align-items: center;
}

.tooltipContainer {
	background-color: $c_blue_300;
	padding: 12px 22px;
	border-radius: 4px;
	color: white;
	font-weight: $fw_regular;
	font-size: $fs_small;
	display: block;

	li {
		display: inline-flex;
		margin-right: 3px;
	}
}

.tooltipToggle {
	line-height: 20px;
	cursor: pointer;
}
