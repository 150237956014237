@import '../../../stylesheets/variables';

.list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	border-top: 1px solid $c_gray_400;
	max-height: 450px;
	overflow-y: auto;
	flex: 1;

	@media (min-width: 768px) {
		padding: 0 40px 0 40px;
	}
}

.listItem {
	width: 100%;
	padding: 0 20px 0 20px;

	&:not(:last-child) {
		border-bottom: 1px solid $c_gray_400;
	}

	.even & {
		&:nth-last-of-type(-n + 1) {
			border-bottom: none;
		}
	}

	&:hover {
		.text,
		.chevronRight {
			color: $c_blue_200;
			transition: $tr_fast;
		}
	}

	@media (min-width: 768px) {
		padding: 0;

		&:not(:last-child) {
			border-bottom: 2px solid $c_gray_400;
		}
	}
}

.link {
	align-items: center;
	display: flex;
	padding: 22px 0;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.icon {
	margin-right: 16px;
	flex: 0;

	img {
		width: 32px;
	}

	@media (min-width: 768px) {
		img {
			width: 40px;
		}
	}
}

.text {
	color: $c_blue_300;
	font-size: $fs_large;
	font-weight: $fw_medium;

	@media (min-width: 768px) {
		font-size: $fs_xlarge;
		margin: 0 0 0 16px;
	}
}

.chevronRight {
	margin: 0 15px;
	width: 5px;

	html[dir='rtl'] & {
		transform: rotate(180deg);
	}
}

.sortRow {
	display: flex;
	justify-content: space-between;
	padding: 12px 20px;

	@media (min-width: 768px) {
		padding: 12px 40px;
	}

	span {
		display: flex;
		align-items: center;
		font-size: $fs_medium;
		color: $c_blue_500;
		font-weight: $fw_medium;
		cursor: pointer;
	}
}

.infoRow {
	flex-grow: 1;

	.balancesRow {
		text-align: left;
		color: $c_black;
		font-size: $fs_medium;

		.referenceBalance {
			color: $c_gray;
			font-size: $fs_small;
		}
	}

	@media (min-width: 768px) {
		display: flex;
		justify-content: space-between;

		.balancesRow {
			text-align: right;
		}
	}
}

.loader {
	margin: auto;
}
