@import '../../../../stylesheets/variables';

.option {
	display: flex;
	padding: 8px 4px;

	.optionIcon {
		height: 24px;
		width: 24px;
		align-self: center;
	}

	.optionCurrency {
		color: $c_blue_300;
		font-weight: $fw_medium;
		font-size: $fs_medium;
	}

	.optionBalance {
		font-size: $fs_small;
	}

	.optionConverted {
		color: $c_gray_500;
		font-size: $fs_xsmall;
	}
}
