@import '../../../../stylesheets/variables';
@import '../../../../components/Input/Input.module.scss';

.container {
	display: flex;
	flex-flow: column;
	gap: 26px;
	align-items: center;
	padding: 32px;
	text-align: center;
	font-size: $fs_medium;

	@media (min-width: 767px) {
		width: 454px;
	}
}

.icon {
	width: 96px;
	height: 96px;
}

.backIcon {
	position: absolute;
	top: 24px;
	left: 24px;
	width: 24px;
	height: 24px;
	color: $c_blue_300;
	cursor: pointer;
}

.closeIcon {
	position: absolute;
	top: 24px;
	right: 24px;
	width: 24px;
	height: 24px;
	cursor: pointer;
	color: $c_blue_300;
}

.title {
	font-size: $fs_huge;
	font-weight: $fw_medium;
	color: $c_blue_300;
}

.content {
	display: flex;
	flex-flow: column;
	gap: 24px;
}

.email {
	.inputX {
		position: absolute;
		color: $c_red_100;
		right: 12px;
		top: 12px;
		font-size: 20px;
		cursor: pointer;
	}
}

.actions {
	display: flex;
	flex-flow: column;
	gap: 16px;
}

.button {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	min-width: 232px;
}
