@import '../../../../stylesheets/variables';

.removeProject {
	font-size: $fs_small;
	font-weight: $fw_medium;
	cursor: pointer;
}

.content {
	display: flex;
	flex-wrap: wrap;
	gap: 32px;
}

.row {
	display: flex;
	flex-wrap: wrap;

	@include mobile {
		gap: 24px;
	}
}

.block {
	display: flex;
	flex-flow: column;
	gap: 8px;
	min-width: 200px;

	@include mobile {
		min-width: 100px;
	}

	.blockTitle {
		font-weight: $fw_medium;
		font-size: $fs_medium;
		color: $c_blue_300;
	}

	.blockValue {
		font-size: $fs_medium;
		display: flex;
		gap: 8px;
		align-items: center;

		&.bold {
			font-size: $fs_xxxlarge;
			font-weight: $fw_medium;
		}

		.copy {
			height: 14px;
			width: 14px;
		}
	}
}

.share {
	display: flex;
	gap: 8px;
	font-size: $fs_small;
	font-weight: $fw_medium;
	align-items: center;
	flex: 1;
}

.social {
	display: flex;
	gap: 16px;
}

.socialIcon {
	width: 24px;
	height: 24px;
	cursor: pointer;
}
