@import '../../stylesheets/variables.scss';

.button {
	color: $c_blue;
	font-size: $fs_medium;
	font-weight: $fw_medium;
	text-decoration: none;
	transition: $tr_fast;
	cursor: pointer;

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&:hover {
		color: $c_blue_100;

		.icon {
			background-color: lighten($c_blue, 10%);
		}
	}

	.icon {
		margin: 0 auto;
		width: 40px;
		height: 40px;
		background-color: $c_blue;
		border-radius: 50%;
		margin-bottom: 10px;
		transition: $tr_fast;
		background-repeat: no-repeat;
		background-position: center;

		&.DEPOSIT {
			background-image: url(./images/deposit.svg);
		}

		&.EXCHANGE {
			background-image: url(./images/exchange.svg);
		}

		&.WITHDRAW {
			background-image: url(./images/withdraw.svg);
		}

		&.BUY {
			background-image: url(./images/buy.svg);
		}

		&.LOAD {
			background-image: url(./images/load.svg);
			background-position: 13px 10px;
		}

		&.UNLOAD {
			background-image: url(./images/load.svg);
			background-position: 13px 10px;
			transform: rotate(180deg);
		}
	}
}
