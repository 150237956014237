@import '../../stylesheets/variables';

.container {
	display: flex;
	justify-content: space-between;
	margin: 0 0 12px;
	align-items: center;
	min-height: 34px;
	padding: 20px 20px 0 20px;

	@media (min-width: 1024px) {
		padding: 40px 40px 0 40px;
	}
}

.title {
	color: $c_black;
	font-size: $fs_large;
	font-weight: $fw_medium;
	margin: 0;
	text-decoration: none;

	* {
		color: $c_black;
	}

	@media (min-width: 1024px) {
		font-size: 20px;
	}

	&:hover,
	&:focus {
		color: $c_black;
		text-decoration: none;
	}
}

.link {
	color: $c_blue_300;
	display: block;
	font-size: $fs_small;
	font-weight: $fw_medium;
	transition: $tr_fast;
	margin-left: auto;
	text-decoration: none;

	&:hover,
	&:focus {
		color: $c_blue_200;
	}
}

.icon {
	cursor: pointer;
	font-size: $fs_large;
	margin-right: 16px;

	html[dir='rtl'] & {
		transform: rotate(180deg);
	}
}

.moreOptions {
	margin: 5px 0 0 15px;
	cursor: pointer;
	position: relative;
	width: 24px;
}

.moreOptionsList {
	position: absolute;
	top: 30px;
	right: 0;
	list-style: none;
	margin: 0;
	padding: 0;
	min-width: 150px;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
	z-index: 15;
	background-color: #fff;

	li {
		color: $c_blue_300;
		font-size: $fs_medium;
		font-weight: $fw_medium;
		padding: 15px;
		text-align: right;
		transition: $tr_fast;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:hover {
			color: $c_blue_100;
		}
	}
}

.close {
	width: 21px;
	height: 21px;
	margin-right: 10px;
}

.closeBtn {
	color: $c_blue_300;
	font-size: $fs_medium;
	cursor: pointer;
	font-weight: $fw_medium;
}

.topSection {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		color: $c_blue_300;
	}

	.removeCardButton {
		color: $c_blue_300;
		font-size: $fs_small;
		font-weight: $fw_medium;
		padding: 0;
		height: auto;
		min-width: 0;
		display: block;
		margin-left: 26px;

		&:hover,
		&:focus {
			color: $c_blue_200;
		}

		@media (max-width: 767px) {
			margin-left: 16px;
		}
	}
}
