.form {
	width: 100%;
	max-width: 328px;

	@media (max-width: 1200px) {
		margin: 0 auto;
	}

	> div:first-child > label {
		margin-top: 0;
	}
}

.container {
	padding: 12px 20px;

	@media (min-width: 1024px) {
		padding: 12px 40px;
	}
}

.depositResultContainer {
	margin: 0 auto;
	max-width: 380px;
}

.voucherSuccessViewInfo {
	align-self: flex-start;
	margin-bottom: 40px;
}
