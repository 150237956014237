@import '../../stylesheets/variables';

.container {
	display: flex;
	margin: 27px auto 7px;
	max-width: 1200px;
	padding: 0;
	overflow: hidden;

	@media (min-width: 1200px) {
		padding: 0 20px;
	}
}
