@import '../../../../stylesheets/variables';

.root {
	display: flex;
	flex-flow: column;
	gap: 8px;
}

.container {
	display: flex;
	flex-flow: column;
	gap: 24px;
	padding: 26px;
}

.content {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
}

.header {
	display: flex;
	gap: 10px;

	.title {
		font-size: $fs_large;
		font-weight: $fw_medium;
	}

	.zaibukas {
		width: 12px;
		height: 24px;
		margin: 0;
	}
}

.block {
	display: flex;
	flex-flow: column;
	gap: 8px;
	min-width: calc(50% - 12px);

	.blockTitle {
		font-weight: $fw_medium;
		font-size: $fs_medium;
		color: #7f95ba;
	}

	.blockValue {
		font-size: $fs_xxxlarge;
		font-weight: $fw_medium;
	}
}

.note {
	font-size: $fs_small;
	color: #7f95ba;

	.link {
		color: $c_blue;
		text-decoration: none;
		font-weight: $fw_medium;
	}
}
