@import '../../stylesheets/variables.scss';

.container {
	border-radius: 8px;
	box-shadow: 0 6px 10px #4972f40d;
	width: 100%;
	background-color: #fff;
	margin-bottom: 16px;
	overflow: hidden;
	min-height: 215px;

	@media (min-width: 767px) {
		max-width: 376px !important;
	}

	canvas {
		margin-left: -2px;
		width: 100% !important;
		margin-bottom: -2px;

		@media (min-width: 767px) {
			width: 380px !important;
		}
	}
}

.ratesDetails {
	padding: 23px 40px;
	width: 100%;
	display: flex;
	align-items: center;
}

.label {
	color: $c_blue_400;
	font-weight: $fw_medium;
	font-size: $fs_large;
	margin-left: 10px;

	.value {
		margin-top: 8.25px;
		font-weight: $fw_regular;
	}
}

.percentageValue {
	display: flex;
	flex: 3;
	justify-content: flex-end;
	font-size: $fs_medium;
	color: $c_blue_400;

	span {
		margin-right: 11px;
	}
}

.arrows {
	color: $c_green;

	&.negative {
		color: $c_red;
	}
}

.loader {
	margin: 20px auto;
	display: flex;
}

.errorMessage {
	margin: 20px auto;
	display: block;
	text-align: center;
	font-size: $fs_medium;
	font-weight: $fw_regular;
}
