@import '../../stylesheets/variables.scss';

.label,
.value {
	max-width: 328px;
	width: 100%;
	margin: 0 auto;
}

.label {
	margin-top: 32px;
	color: $c_blue_300;
	font-weight: $fw_medium;
	font-size: $fs_medium;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
}

.value {
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: $c_black;
}

.copyButton {
	* {
		font-size: $fs_small;
		color: $c_blue_100;
	}

	margin-left: auto;
	margin-right: 0;
}

.textWithIconContainer {
	display: flex;
	align-items: center;

	.currencyIcon {
		margin: 0 9px 0 0;
		width: 20px;
		height: 20px;

		img {
			width: 100%;
			height: 100%;
		}
	}
}
