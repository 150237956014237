@import '../../../../../stylesheets/variables';

.container {
	width: 100%;
	min-height: 574px;
	display: flex;
	flex-flow: column;
	max-width: 768px;
}

.buttonsTable {
	display: flex;
	flex-flow: column;
}

.tableHeader {
	display: flex;
	flex-flow: column;
	margin-bottom: 20px;
	color: $c_blue_250;

	.tableControls {
		display: flex;
		flex-flow: row;
		@include legacyGap(28px, row);
		margin-bottom: 32px;
	}

	.columns {
		display: flex;
		font-size: $fs_medium;
		font-weight: $fw_medium;

		> span {
			&:nth-child(1) {
				flex-basis: 40%;
			}
			&:nth-child(2) {
				text-align: center;
				flex-basis: 20%;
			}
			&:nth-child(3) {
				text-align: center;
				flex-basis: 20%;
			}
			&:nth-child(4) {
				text-align: center;
				flex-basis: calc(20% - 24px);
			}
			&:nth-child(5) {
				text-align: center;
				flex-basis: 24px;
			}
		}
	}
}

.listBody {
	.listRow {
		position: relative;
		width: calc(100% + 32px);
		left: -16px;

		&:hover {
			background-color: $c_gray_200;
			border-radius: 8px;
		}

		&:not(:last-child) {
			border-bottom: 1px solid $c_gray_400;
		}
	}

	.mobileListRow {
		&:not(:last-child) {
			border-bottom: 1px solid $c_gray_400;
		}
	}
}

.removeButton {
	font-size: $fs_small;
	font-weight: $fw_medium;
	cursor: pointer;
}
