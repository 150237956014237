@import '../../../../stylesheets/variables';

.title {
	font-size: $fs_xxlarge;
	font-weight: $fw_medium;
	flex: 1;
}

.actions {
	display: flex;
	align-self: flex-start;
	line-height: 23px;

	@media (max-width: 767px) {
		flex-flow: column;
	}

	@media (min-width: 768px) {
		flex-flow: row;
		@include legacyGap(28px, row);
	}

	> * {
		font-size: $fs_small;
		font-weight: $fw_medium;
		cursor: pointer;
		color: $c_blue_300;
		text-decoration: none;

		&:hover {
			color: $c_blue_transparent;
		}
	}
}

.backLink {
	text-decoration: none;
	align-items: center;
	color: $c_black;
	margin-right: 12px;
}

.container {
	margin-bottom: 24px;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	width: 100%;
	@include legacyGap(12px, row);

	&.left {
		text-align: left;
	}

	&.center {
		text-align: center;
	}
}

.backIcon {
	font-size: $fs_large;

	body[dir='rtl'] & {
		transform: rotateZ(180deg);
	}
}
