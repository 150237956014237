@import '../../../../stylesheets/variables';

.heading {
	color: #7b7e93;
	font-size: $fs_large;
	font-weight: $fw_medium;
	margin: 30px 0 24px;
}

.list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.container {
	max-height: 450px;
	overflow-y: auto;
	margin-top: 16px;
	padding: 0 20px;

	@media (min-width: 1024px) {
		padding: 0 40px;
	}
}
