@import '../../../../stylesheets/variables.scss';

.post {
	max-width: 380px;
	width: 100%;
	margin: 40px auto 24px;
	font-size: $fs_medium;
	line-height: 20px;
	font-weight: $fw_regular;

	b {
		font-weight: $fw_medium;
	}

	&.tal {
		text-align: left;
	}

	&.tac {
		text-align: center;
	}

	&.tar {
		text-align: right;
	}
}
