@import '../../../../stylesheets/variables.scss';

.button {
	width: 232px;
	margin: 36px auto 50px;
}

.container {
	max-width: 380px;
	margin: 0 auto;
	width: 100%;
}

.detail {
	margin-bottom: 24px;
	text-align: left;
	color: $c_blue_300;
	font-size: $fs_medium;
	font-weight: $fw_medium;
	line-height: $lh_medium;
	display: flex;
	align-items: center;

	> div {
		display: block;
	}

	.value {
		margin-top: 8px;
		color: black;
		font-size: $fs_large;
		font-weight: $fw_regular;
		display: block;
	}
}

.timer {
	margin-top: 32px;
	color: black;
	border: 1px solid $c_orange_100;
	padding: 12px 10px 10px 50px;
	position: relative;
	font-size: $fs_small;
	text-align: left;

	> span {
		width: 15px;
		text-align: center;
		display: inline-block;
	}

	&::before {
		position: absolute;
		content: '!';
		color: white;
		background-color: $c_orange_100;
		width: 20px;
		height: 20px;
		font-size: $fs_xlarge;
		font-weight: $fw_medium;
		border-radius: 50%;
		top: calc(50% - 10px);
		left: 10px;
		text-align: center;
	}
}

.copy {
	font-size: $fs_medium;
	margin-bottom: -25px;
	padding: 0 !important;
	height: auto;
	justify-content: flex-end;
	margin-left: auto;
}
