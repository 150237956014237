.loader {
    width: 100vw;
    height: calc(100vh - 300px);
    overflow: hidden;
    background-color: #F5F8FF;

    svg {
        display: none;
    }
    svg:first-of-type {
        display: block;
        max-width: 200px;
        margin: 0 auto;

        @media (min-width: 992px) {
            margin: -40px auto 0;
        }
    }
}