@import '../../stylesheets/variables.scss';

.slider {
	position: relative;
	width: 100%;
}

.bubbles {
	height: 20px;
	width: 100%;
	position: absolute;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	pointer-events: none;
}

.bubble {
	background: #c8d4fc;
	height: 12px;
	width: 12px;
	border-radius: 50%;
	top: 0;
	display: flex;

	&.active {
		background: $c_blue_100;
	}

	&.selected {
		height: 20px;
		width: 20px;
		background: $c_white;
		border: 1px solid $c_blue_100;
	}
}

.line {
	pointer-events: none;
	position: absolute;
	top: 8px;
	height: 4px;
	width: 100%;
}

.input {
	width: 100%;
	appearance: none;
	padding: 0 2px 0 2px;
	margin: 0;
}
