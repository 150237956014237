@import '../../stylesheets/variables.scss';

.content {
	display: flex;
	margin-top: 49px;
}

.container {
	border-radius: 8px;
	box-shadow: 0 6px 10px #4972f40d;
	width: 100%;
	background-color: #fff;
	margin-bottom: 16px;

	@media (min-width: 992px) {
		max-width: 376px;
	}

	.marginRight {
		margin-right: 24px;
	}
}

.title {
	font-size: $fs_large;
	line-height: 21px;
	font-weight: $fw_medium;
	color: black;
	margin: 0 0 15px 16px;
	display: flex;
	align-items: center;
	width: 100%;
}

.seeAllLink {
	display: inline-block;
	margin-left: auto;
	margin-right: 16px;
	font-size: $fs_small;
	font-weight: $fw_medium;
	color: $c_blue_300;
	text-decoration: none;
}

.ratesList {
	li {
		padding: 23px 40px;
		width: 100%;
		border-bottom: 1px solid $c_gray_400;
		display: flex;
		align-items: center;

		&:last-of-type {
			border-bottom: 0;
		}
	}
}

.label {
	color: $c_blue_400;
	font-weight: $fw_medium;
	font-size: $fs_large;

	.value {
		margin-top: 8.25px;
		font-weight: $fw_regular;
	}
}

.percentageValue {
	display: flex;
	flex: 3;
	justify-content: flex-end;
	font-size: $fs_medium;
	color: $c_blue_400;

	span {
		margin-right: 11px;
	}
}

.arrows {
	color: $c_green;

	&.negative {
		color: $c_red;
	}
}
