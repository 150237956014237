@import '../../stylesheets/variables.scss';

.inputGroup {
	position: relative;
	text-align: left;
	max-width: 438px;
	width: 100%;
}

.label {
	display: block;
	font-size: $fs_medium;
	color: $c_blue_300;
	margin-bottom: 8px;
	font-weight: $fw_medium;
	line-height: $lh_medium;
}

.textarea {
	min-height: 170px;
	resize: none;
	border: 1px solid #d9dbe5;
	border-radius: 4px;
	font-size: $fs_medium;
	line-height: 16px;
	font-weight: $fw_regular;
	transition: $tr_fast;
	padding: 11px 12px;
	width: 100%;

	&.errorInput {
		border-color: $c_red_100;
	}

	&:active,
	&:hover,
	&:focus {
		border-color: darken(#d9dbe5, 15%);
		outline: 0;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:read-only {
		background-color: rgba(0, 0, 0, 0.1);
	}

	/* Firefox */
	&[type='number'] {
		-moz-appearance: textfield;
	}
}

.error {
	position: absolute;
	font-size: $fs_small;
	left: 0px;
	bottom: -16px;
	color: $c_red_100;
	height: 12px;
}
