@import '../../stylesheets/variables';

.export {
	display: inline-block;
	margin-left: auto;
	font-size: $fs_small;
	color: $c_blue_300;
	font-weight: $fw_medium;
	cursor: pointer;
	transition: $tr_fast;

	&.disabled {
		pointer-events: none;
		color: #b3b6c6;
	}

	&.active,
	&:active,
	&:focus,
	&:hover {
		color: $c_blue_transparent;
	}
}

.options {
	position: absolute;
	top: 100%;
	right: 0;
	width: 154px;
	background-color: white;
	z-index: 10;
	box-shadow: 0 8px 8px #4972f40f;

	li {
		display: flex;
		align-items: center;
		font-weight: $fw_regular;
		cursor: pointer;
		padding: 11px;
		transition: $tr_fast;

		a {
			text-decoration: none;
			color: $c_blue_300;
			width: 100%;

			&.disabled {
				opacity: 0.5;
				pointer-events: none;
				color: #b3b6c6;
			}
		}

		&:hover {
			background-color: #4972f40f;
		}

		img {
			margin-right: 10px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
