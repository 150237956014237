@import '../../../../stylesheets/variables.scss';

.container {
	background-color: white;
	margin-bottom: 16px;
	border-radius: 8px;
	padding: 26px 40px 20px;
	box-shadow: 0 6px 10px #4972f40d;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 10px;
}

.title {
	font-size: 16px;
	font-size: $fs_medium;
	font-weight: $fw_medium;
	margin-bottom: 10px;
}

.linkedWalletItemContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;

	.row {
		display: flex;
		align-items: center;
		column-gap: 15px;
	}

	.currencyColumn {
		display: flex;
		flex-direction: column;
		row-gap: 5px;
		text-align: left;
	}

	.balanceColumn {
		display: flex;
		flex-direction: column;
		row-gap: 5px;
		text-align: right;
	}

	.currencyCode {
		font-size: 16px;
		font-size: $fs_medium;
		font-weight: $fw_medium;
	}

	.currencyName {
		font-size: 14px;
		font-size: $fs_small;
		color: $c_gray;
	}

	.currencyValue {
		font-size: 14px;
		font-size: $fs_medium;
	}

	.balanceCurrencyValue {
		font-size: 12px;
		font-size: $fs_small;
		color: $c_gray;
	}
}
