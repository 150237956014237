@import '../../../../stylesheets/variables.scss';

.container {
	text-align: center;
	padding: 40px 25px;
	max-height: 100vh;
	overflow-y: auto;
	position: relative;

	@media (min-width: 768px) {
		padding: 40px 80px;
		width: 680px;
	}

	.error {
		margin-bottom: 15px;
	}

	.title {
		font-size: 20px;
		font-weight: $fw_medium;
	}

	ul {
		text-align: left;
		list-style: disc;
		margin: 0 30px;
	}

	.fileUpload {
		margin-top: 24px;
		padding: 28px 24px;
		background-color: $c_gray_200;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: left;
		position: relative;

		h3 {
			font-size: $fs_large;
			font-weight: $fw_medium;
			margin-bottom: 10px;
		}

		p {
			font-size: $fs_small;
			margin: 0 0 4px;
		}

		input {
			opacity: 0;
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.files {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 5px;
		padding: 15px;

		.file {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.fileName {
				display: flex;
				align-items: center;

				.fileIcon {
					color: $c_blue_300;
					margin-right: 10px;
				}
			}

			.xMark {
				color: $c_red;
				cursor: pointer;
			}
		}
	}
}
