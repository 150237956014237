@import '../../../../stylesheets/variables';

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px 40px 40px 40px;

	&.mobile {
		padding: 20px 20px 20px 20px;
	}

	form {
		width: 100%;
	}
}

.modalNotification {
	margin: 24px 0 40px 0;
}

.modalCloseButton {
	display: block;
	margin: 0 auto;
	width: 127px;
	height: 46px;
	font-size: $fs_medium;
	font-weight: $fw_medium;
}
