@import '../../../../stylesheets/variables.scss';

.container {
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	min-height: 676px;
}

.title {
	align-self: flex-start;
}

.content {
	display: flex;
	flex-flow: column;
	width: 100%;

	@media (min-width: 538px) {
		flex-flow: row;
		align-self: flex-start;
		width: 50%;
	}

	> * {
		display: flex;
		flex-flow: column;
		flex: 1;

		&.preorder {
			@include legacyGap(36px, column);
		}
	}
}

.public {
	display: flex;
	justify-content: space-between;

	.copy {
		color: $c_blue_100;
	}
}

.publicLink {
	min-height: 72px;
}

.viewButton {
	align-self: center;
	width: 232px;
}

.allowMultiple {
	@include legacyGap(8px, row);
	flex-flow: row !important;
	color: $c_green;
}
