@import '../../../stylesheets/variables.scss';

.container {
	background-color: white;
	margin-bottom: 40px;
	border-radius: 8px;
	padding: 26px 50px 20px;
	box-shadow: 0 6px 10px #4972f40d;
	width: 100%;
	text-align: center;

	@media (min-width: 767px) {
		padding: 26px 195px 20px;
	}
}

.title {
	color: #000000;
	font-size: 20px;
	font-weight: $fw_medium;
	display: block;
	margin-bottom: 52px;
}

.paragraph {
	color: #000000;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	margin-top: 40px;

	a {
		color: $c_blue_300;
		text-decoration: none;
		font-weight: $fw_medium;
		transition: $tr_fast;

		&:hover {
			color: $c_blue_100;
		}
	}
}

.buttonContainer {
	position: relative;
	max-width: 232px;
	margin: 40px auto 16px;

	> * {
		width: 100%;
	}
}
