@import '../../../../stylesheets/variables.scss';

.container {
	position: relative;
}

.back {
	position: absolute;
	top: -110px;
	left: -40px;

	@media (max-width: 500px) {
		top: -95px;
		left: -25px;
	}
}

.formContainer {
	max-width: 380px;
	margin: 0 auto;

	h6 {
		text-align: left;
	}

	.selectContainer {
		.option {
			cursor: pointer;
			margin: 10px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			.optionTitle {
				display: flex;
				@include legacyGap(16px, row);

				font-size: $fs_xlarge;
				font-weight: $fw_medium;
				color: $c_blue_300;
				text-align: left;
			}
		}
	}

	.separator {
		height: 10px;
		display: flex;
		align-items: center;
		@include legacyGap(7px, row);

		div {
			flex: 1;
			height: 1px;
			border: 1px solid $c_gray_400;
		}
	}
}
