@import '../../../../../stylesheets/variables';

.category {
	&.pending {
		.items {
			opacity: 0.5;
		}
	}

	.label {
		font-size: $fs_medium;
		font-weight: $fw_medium;
		color: $c_blue_500;
	}
	.items {
		margin-top: 12px;
	}
}
