@import '../../stylesheets/variables.scss';

.container {
	width: 100%;
	border-radius: 8px;
	background-color: white;
	margin-bottom: 16px;
	padding: 32px;
	text-align: center;
}

.img {
	margin: 57px auto 0;
	width: 320px;
}

.title {
	font-size: $fs_xlarge;
	line-height: 26px;
	text-align: left;
	font-weight: $fw_medium;
	color: $c_blue_300;
	margin: 20px auto 16px;
}

.text {
	font-size: $fs_medium;
	line-height: $lh_medium;
	text-align: left;
	font-weight: $fw_regular;
	color: black;
}
