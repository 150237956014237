@import '../../stylesheets/variables.scss';

.container {
	width: 100%;
}

.toggle {
	color: $c_blue_300;
	font-size: $fs_medium;
	font-weight: $fw_medium;
	margin-bottom: 8px;
}
