@import '../../../../../stylesheets/variables';

.container {
	display: flex;
	flex-flow: column;
	margin-bottom: 12px;

	.link {
		display: flex;
		flex-flow: column;
		width: 100%;
		text-decoration: none;
		color: $c_black;
		@include legacyGap(12px, column);

		.details {
			width: 100%;
			display: flex;
			flex-flow: row;

			.label {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.amount {
				text-align: right;
				flex: 1 0 50%;
				color: $c_gray;
				font-weight: $fw_medium;

				@media (min-width: 767px) {
					flex: 1 0 30%;
				}
			}
		}

		.subtext {
			width: 100%;
			display: flex;
			flex-flow: row;
			font-size: $fs_small;

			.info {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				.inline {
					display: flex;
					@include legacyGap(8px, row);
				}
			}
			.status {
				flex: 1 0 50%;
				text-align: right;
			}
		}
	}
}

.PAID,
.NEW {
	.amount {
		color: $c_green !important;

		&::before {
			content: '+';
		}
	}
}

.EXPIRED {
	.status {
		color: $c_red;
		text-transform: capitalize;
	}
}
