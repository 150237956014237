@import '../../../../stylesheets/variables';

.noProjects {
	display: flex;
	align-items: center;
	justify-content: center;
	justify-self: center;
	flex-flow: column;
	margin: auto;
	height: 100%;
	@include legacyGap(20px, column);

	img {
		width: 246px;
		height: 156px;
	}

	span {
		font-size: $fs_medium;
		color: $c_gray_500;
	}
}
