@import '../../../../stylesheets/variables';

.container {
	margin: 20px 0 0;

	@media (min-width: 768px) and (max-width: 1024px) {
		display: flex;
		align-items: center;
	}

	@media (min-width: 1200px) {
		display: flex;
		align-items: center;
	}
}

.column {
	margin: 0 0 40px;

	&:last-child {
		margin: 0;
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		flex: 1 1 calc(50% - 80px);
		margin: 0 80px 0 0;

		&:last-child {
			margin: 0;
		}

		&.template {
			flex: 1 1 calc(50% - 50px);
			margin: 0 50px 0 0;
		}
	}

	@media (min-width: 1200px) {
		flex: 1 1 calc(50% - 80px);
		margin: 0 80px 0 0;

		&:last-child {
			margin: 0;
		}
	}
}

.button {
	height: 46px;
	min-width: 232px;
	font-size: $fs_medium;
}

.centeredContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.successBox {
	width: 100%;
	max-width: 380px;
	margin: 0 auto;
	text-align: center;
}

.primaryButtonWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
}

.button {
	width: 176px;
}

.statusText {
	font-size: 20px;
	line-height: 28px;
	font-weight: $fw_medium;
}

.inputGroup {
	margin-bottom: 40px;
	text-align: left;
	max-width: 300px;
}

.textGroup {
	margin: 24px 0;
	text-align: left;
}

.label {
	font-size: 16px;
	font-weight: $fw_medium;
	color: $c_blue_300;
	display: block;
	margin-bottom: 8px;
}

.text {
	display: block;
	width: 100%;
	font-size: 16px;
	font-weight: $fw_regular;
	color: $c_black;
	position: relative;
	text-overflow: ellipsis;
}

.heading {
	color: $c_black;
	font-size: 16px;
	font-weight: 500;
	margin: 0 0 24px;
}

.list {
	counter-reset: item;
	list-style-type: none;
	margin: 0;
	padding-left: 5px;
}

.listItem {
	color: $c_black;
	display: flex;
	font-size: 12px;
	font-weight: 400;
	margin: 0 0 16px;

	&::before {
		content: counter(item) '  ';
		counter-increment: item;
		font-weight: 500;
		margin: 0 20px 0 0;
		width: 0;
	}
}

.link {
	padding: 0;
	display: inline-block;
	font-size: 12px;
	text-transform: lowercase;
	margin: 0;
	color: $c_blue_100;
	min-width: auto;
	height: auto;

	&:hover {
		color: $c_blue_300 !important;
	}
}

.paragraph {
	color: $c_black;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	flex-direction: column;
	align-items: center;
}
