@import '../../../../stylesheets/variables.scss';

.container {
	max-width: 380px;
	width: 100%;
	padding: 40px 48px 20px;
	margin: 0 auto;
	background-color: #f9f9fc;
	border-radius: 6px;
	margin-bottom: 32px;
}

.post {
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: black;
	line-height: 20px;
	text-align: left;
}

.button {
	display: block;
	max-width: 232px;
	width: 100%;
	margin: 0 auto 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

.input {
	margin-bottom: 40px;
}

.resend {
	position: absolute;
	bottom: 8px;
	right: 12px;
	font-size: $fs_small;
	color: $c_blue_100;
	line-height: $lh_medium;
	font-weight: $fw_medium;
	cursor: pointer;
	min-width: 35px;
}

.hidden {
	opacity: 0;
	pointer-events: none;
}
