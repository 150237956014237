@import '../../../../stylesheets/variables';

.sortable {
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 4px;
	white-space: nowrap;

	.sortIcon {
		display: flex;
		width: 16px;
		margin: 0;
	}
}

.body {
	display: flex;
	flex-flow: column;
	align-items: center;
	min-height: 200px;
	margin-bottom: 26px;
	width: 100%;
}

.list {
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 100%;
}

.header {
	display: flex;
	flex-flow: row;
	align-items: center;
}

.headerCell {
	color: $c_blue_250;
	font-weight: $fw_medium;
	font-size: $fs_medium;
}

.row {
	display: flex;
	width: 100%;
	gap: 8px;
	align-items: center;
	font-size: $fs_medium;

	&:not(.header) {
		min-height: 92px;
		border-bottom: 1px solid $c_gray_400;
		word-break: break-word;
	}
}

.mobileRow {
	display: flex;
	flex-flow: column;
	width: 100%;
	border-bottom: 1px solid $c_gray_400;
	gap: 16px;
	padding: 16px 0;
}

.mobileProperty {
	display: flex;
	flex-flow: row;
	align-items: center;
	gap: 8px;

	& > * {
		flex-basis: calc(50% - 4px);
	}
}

.cell {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.sortButton {
	align-self: flex-end;
	border-radius: 8px;

	.sortButtonContent {
		display: flex;
		gap: 8px;
		align-items: center;
	}
}

.sortDrawer {
	position: fixed;
	bottom: 0;
	padding: 8px 36px 24px 36px;
	box-shadow: 0px 0px 10px #4972f41f;
	background-color: $c_white;
	border-radius: 8px 8px 0px 0px;
	width: 100%;
	display: flex;
	flex-flow: column;
	gap: 16px;

	.drawerHandle {
		display: flex;
		align-self: center;
		width: 49px;
		height: 4px;
		background: #e5e5e5;
		border-radius: 8px;
	}

	.drawerField {
		color: $c_blue_300;
		font-size: $fs_medium;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&.active {
			font-weight: $fw_medium;
		}

		&:not(:last-child) {
			border-bottom: 1px solid $c_gray_400;
		}

		.icon {
			color: $c_blue;
		}
	}

	.close {
		display: flex;
		align-self: center;
		width: 100%;
	}
}
