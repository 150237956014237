@import '../../../../../stylesheets/variables.scss';

.host {
	width: 100%;
	display: flex;
	flex-flow: column;
	@include legacyGap(16px, column);
}

.container {
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
}

.content {
	display: flex;
	flex-wrap: wrap;
	@include legacyGap(16px, column);
}

.idBlock {
	flex-flow: column;
	align-items: center;
	text-align: center;
	font-size: $fs_medium;
	@include legacyGap(16px, column);
	width: 100%;

	.label {
		display: flex;
		gap: 8px;
	}
}

.editLink {
	font-size: $fs_small;
	font-weight: $fw_medium;
	cursor: pointer;
	color: $c_blue_300;
	text-decoration: none;
	align-self: flex-end;
}

.value {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	color: $c_blue_500;
	word-break: break-word;

	&.right {
		text-align: right;
	}
}

.mode {
	flex-flow: row;
}

.autoconvert {
	font-weight: $fw_medium;
	color: $c_blue_500;

	.autoconvertIcon {
		height: 18px;
		width: 18px;
	}
}
