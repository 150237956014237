@import '../../stylesheets/variables.scss';

.label {
	align-items: center;
	cursor: pointer;
	display: flex;
	font-size: $fs_small;
	font-weight: $fw_medium;
	margin: 0 0 12px 0;
	user-select: none;

	a {
		color: $c_blue_100;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	span {
		order: 2;
	}
}

.label .input {
	cursor: pointer;
	height: 0;
	opacity: 0;
	position: absolute;
	width: 0;
}

.checkmark {
	border: 1px solid;
	border-color: $c_blue_50;
	border-radius: 3px;
	height: 20px;
	justify-self: flex-start;
	order: 1;
	position: relative;
	width: 20px;
	min-width: 20px;
	margin-right: 10px;
}

.input:hover ~ .checkmark,
.label:hover .input ~ .checkmark {
	border-color: $c_white;
	background-color: $c_blue_50;
	border: none;

	&::after {
		display: block;
	}
}

.checkmark::after {
	content: '';
	display: none;
	position: absolute;
}

.label .input:checked ~ .checkmark {
	background-color: $c_blue;
	border: none;

	&:hover {
		background-color: #1f4ee5;
	}

	&::after {
		display: block;
	}
}

.label .checkmark::after {
	border: solid $c_white;
	border-width: 0 3px 3px 0;
	height: 10px;
	left: 6px;
	top: 1px;
	transform: rotate(45deg);
	width: 5px;
}

.disabled {
	cursor: not-allowed;

	.checkmark {
		border-color: $c_gray_500 !important;
		background-color: $c_gray_500 !important;
	}
}

.radio {
	.checkmark {
		border-radius: 50%;

		&::after {
			height: 0px;
			width: 0px;
			border-radius: 50%;
			border-width: 4px;
			top: 6px;
			left: 6px;
		}
	}
}
