@import '../../stylesheets/variables.scss';

.container {
	border-radius: 8px;
	padding: 18px 31px 95px;
	margin-bottom: 16px;
	position: relative;
	height: 403px;

	@media (min-width: 767px) {
		box-shadow: 0 6px 10px #4972f40d;
		background-color: #fff;
		margin-top: 27px;
	}

	@media (min-width: 992px) {
		width: 376px;
		margin-top: 0;
	}
}

.currencyList {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;

	li {
		color: #4d4d4d;
		margin-right: 24px;
		text-align: center;

		&:last-of-type {
			margin-right: 0;
		}

		.currencyCode {
			font-size: $fs_small;
			font-weight: bold;
			margin: 6px auto;
		}

		.value {
			font-size: 10px;
			font-weight: normal;
			margin: 0 auto;
		}
	}
}

.total {
	position: absolute;
	top: calc(47% - 18.5px);
	left: 0;
	width: 100%;
	height: auto;
	height: 37px;

	span {
		display: block;
		margin: 0 auto;
		text-align: center;

		&:first-of-type {
			font-size: $fs_small;
			color: #4e4e4e;
			margin-bottom: 6px;
		}

		&:last-of-type {
			color: #102d6f;
			font-size: $fs_large;
			font-weight: bold;
		}
	}
}

.line {
	margin: 0 auto;
	width: 16px;
	height: 6px;
	border-radius: 7px;

	&.orange {
		background-color: #f7931a;
	}

	&.black {
		background-color: #121b2c;
	}

	&.blue {
		background-color: #0063a9;
	}

	&.lightBlue {
		background-color: #97c5d3;
	}
}

.loader {
	margin: 16px 32px;
}

.select {
	justify-content: flex-end;
}

.selectContainer {
	display: flex;
	justify-content: flex-end;
}
