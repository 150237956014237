@import '../../../../stylesheets/variables.scss';

.form {
	width: 100%;
	max-width: 328px;

	@media (max-width: 1200px) {
		margin: 0 auto;
	}

	> div:first-child > label {
		margin-top: 0;
	}
}

.button {
	margin: 40px auto;
	display: block;
	max-width: 232px;
	width: 100%;
	text-align: center;
}

.label {
	margin-top: 32px;
	color: $c_blue_300;
	font-weight: $fw_medium;
	font-size: $fs_medium;
	display: flex;
	align-items: center;
	margin-bottom: 8px;

	span {
		margin-right: 8px;
	}
}

.infoBox {
	margin-top: 12px;
	font-size: $fs_medium;
	line-height: 22px;
	color: #b3b6c6;
}

.option {
	min-height: 34px;
	display: flex;
	align-items: center;
	padding-left: 8px;

	> span {
		margin-left: 8px;
	}
}
