@import '../../stylesheets/variables.scss';

.label {
	font-size: $fs_medium;
	line-height: $lh_medium;
	color: $c_blue_300;
	font-weight: $fw_medium;
	text-align: left;
	display: flex;
	align-items: center;

	> * {
		margin-left: 6px;
	}
}

.container {
	position: relative;
	display: flex;

	min-width: 135px;
	margin-right: 16px;
	width: inherit;

	&:after {
		content: '';
		position: absolute;
		top: 12px;
		right: 20px;
		width: 13px;
		height: 15px;
		pointer-events: none;
		background-image: url(./images/calendar.svg);
	}

	:global {
		.react-datepicker__triangle {
			display: none;
		}

		.react-datepicker__close-icon {
			display: none;
		}
	}
}

.datePicker {
	color: $c_blue_300;
	border: 1px solid #d9dbe5;
	border-radius: 4px;
	line-height: 16px;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	transition: $tr_fast;
	padding: 11px 12px;
	width: 100%;

	@media (min-width: 600px) {
		font-size: $fs_medium;
	}

	&:hover {
		border-color: #c8d4fc;
		outline: 0;
		cursor: pointer;
	}

	&:focus {
		border-color: #4b74f4;
		outline: 0;
	}

	&:active {
		color: $c_blue_300;
		border-color: #4b74f4;
		outline: 0;
	}

	&:disabled {
		background-color: #e6e8f0;
		border-color: #b3b6c6;
		cursor: not-allowed;
	}
}

.popper {
	z-index: 20;

	:global {
		.react-datepicker {
			border: 1px solid $c_gray_300;
			border-radius: 8px;
			box-shadow: 0 10px 10px #4972f40f;
			padding: 0 24px 8px 24px;
		}

		.react-datepicker__day {
			margin: 3px 0;
			padding: 0 3px;
			color: $c_black;
			font-size: $fs_small;
			box-sizing: content-box;
			background-color: $c_white;
			outline: none;

			:local(.day) {
				display: block;
				width: 26px;
				height: 26px;
				border-radius: 4px;
			}

			&:hover {
				:local(.day) {
					color: $c_white;
					background-color: #1f4ee5;
				}
			}

			&--disabled {
				border-radius: 4px;
				background-color: $c_gray_300;
				pointer-events: none;
			}

			&--outside-month {
				visibility: hidden;
			}

			&--in-range {
				background-color: $c_gray_200;
				border-radius: 0;

				&:hover {
					background-color: $c_gray_200;
				}
			}

			&--in-selecting-range {
				background-color: $c_gray_200;
				border-radius: 0;

				&:hover {
					background: linear-gradient(90deg, $c_gray_200 50%, white 50%);
				}
			}

			&--keyboard-selected,
			&--selected,
			&--range-end,
			&--range-start,
			&--selecting-range-start {
				:local(.day) {
					background-color: $c_blue_100;
					color: $c_white;
				}
			}

			&--selecting-range-start,
			&--range-start {
				background: linear-gradient(90deg, white 50%, $c_gray_200 50%);
			}

			&--range-end {
				background: linear-gradient(90deg, $c_gray_200 50%, white 50%);
			}

			&--keyboard-selected {
				&.react-datepicker__day--in-selecting-range {
					&:not(.react-datepicker__day--selecting-range-start) {
						background: linear-gradient(90deg, $c_gray_200 50%, white 50%);
					}
				}
			}
		}

		.react-datepicker__header {
			border-bottom: none;
			background-color: $c_white;

			.react-datepicker__current-month {
				font-size: $fs_large;
				font-weight: $fw_medium;
				padding: 25px 0;
			}

			.react-datepicker__day-names {
				.react-datepicker__day-name {
					color: #272f4d;
					font-size: $fs_small;
					font-weight: $fw_medium;
				}
			}
		}

		.react-datepicker__navigation {
			overflow: visible;
		}

		.react-datepicker__navigation-icon {
			width: 10px;
		}

		.react-datepicker__navigation-icon--next,
		.react-datepicker__navigation-icon--previous {
			top: 26px;

			&:before {
				border-color: $c_blue_300;
				font-size: 20px;
			}
		}

		.react-datepicker__navigation-icon--next {
			left: -30px;
		}

		.react-datepicker__navigation-icon--previous {
			left: 30px;
		}
	}
}

.tooltip {
	background-color: $c_blue_300;
	color: white;
	font-size: $fs_small;
	border-radius: 4px;
	padding: 10px 12px;
	max-width: 231px;
	font-weight: $fw_regular;
}
