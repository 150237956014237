@import '../../../../stylesheets/variables.scss';

.form {
	width: 100%;
	max-width: 328px;

	@media (max-width: 1200px) {
		margin: 0 auto;
	}

	> div:first-child > label {
		margin-top: 0;
	}
}

.errorMessage,
.inputGroup {
	max-width: 328px;
	width: 100%;
	margin: 0 auto;
}

.errorMessage {
	margin-top: 20px;
}

.input {
	margin-top: 32px;
}

.button {
	margin: 40px auto 0;
	display: block;
	max-width: 232px;
	width: 100%;
	text-align: center;
}

.option {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
}

.icon {
	width: 18px;
	height: 18px;
	margin-left: 0;
}

.bold {
	font-weight: $fw_medium;
	margin-right: 5px;
	font-size: $fs_medium;
}

.text {
	font-size: $fs_medium;
}

.option {
	display: flex;
	align-items: center;
	height: 40px;
}

.selectInputGroup {
	width: 100%;
	max-width: initial;

	@media (min-width: 768px) {
		max-width: 380px;
	}
}

.select {
	display: flex;
	align-items: center;
	margin-top: 8px;
	height: 40px;
}

.label {
	margin-top: 32px;
	display: block;
	color: $c_blue_300;
	font-weight: $fw_medium;
	font-size: $fs_medium;
	margin-bottom: 8px;
}
