@import '../../stylesheets/variables.scss';

.container {
	background-color: white;
	margin-bottom: 16px;
	border-radius: 8px;
	padding: 26px 40px 20px;
	box-shadow: 0 6px 10px #4972f40d;
	position: relative;

	&.withIban {
		.details {
			margin: 0 40px 30px 0;
		}
	}
}

.title {
	color: #000000;
	font-size: $fs_large;
	font-weight: $fw_medium;
	display: flex;
	margin-bottom: 70px;
	position: relative;

	@media (min-width: 600px) {
		margin-bottom: 40px;
	}
}

.flexContainer {
	flex-wrap: wrap;
	display: block;

	@media (min-width: 767px) and (max-width: 991px) {
		display: flex;
	}

	@media (min-width: 1200px) {
		display: flex;
	}
}

.details {
	margin: 5px 40px 0 0;
	max-width: 189px;

	&.centered {
		margin: 0;
		margin-top: 15px;
		margin-left: auto;
		display: inline-block;
		text-align: center;

		@media (min-width: 767px) and (max-width: 991px) {
			margin-top: 0;
		}

		@media (min-width: 1200px) {
			margin-top: 0;
		}
	}
}

.label {
	color: $c_blue_300;
	font-size: $fs_medium;
	font-weight: $fw_medium;
	margin-bottom: 11px;
}

.value {
	font-size: $fs_medium;
	color: #000000;
	font-weight: $fw_regular;
	position: relative;
	display: flex;
	align-items: center;
}

.blur {
	filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
	filter: blur(3px);
	pointer-events: none;
}

.link {
	display: block;
	color: $c_blue_400;
	font-size: $fs_small;
	line-height: 16px;
	text-align: center;
	font-weight: $fw_medium;
	margin-top: 9px;

	&:active,
	&:focus,
	&:hover {
		text-decoration: none;
	}
}

.tooltipToggle {
	position: absolute;
	top: -2px;
	right: -25px;
	color: white !important;
	background-color: $c_blue_100;
	color: $c_blue_100;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	transform: scale(0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.copyButton {
	width: 16px;
	height: 16px;
	margin-left: 15px;

	img {
		width: 16px;
		height: 16px;
	}
}

.loader {
	margin-left: 32px;
	margin-bottom: 16px;
}

.additionalInfo {
	position: absolute;
	font-size: 10px;
	color: #b3b6c6;
	font-weight: $fw_regular;
	bottom: 20px;
	left: 40px;
}

.copyAllDetails {
	color: $c_blue_300;
	font-size: $fs_small;
	font-weight: $fw_medium;
	display: inline-block;
	margin-left: auto;
	width: auto;
	height: auto;
}

.showDetailsButton {
	margin-right: 0 !important;
	text-align: left;

	@media (min-width: 767px) and (max-width: 991px) {
		text-align: right;
	}

	@media (min-width: 1200px) {
		text-align: right;
	}
}

.moreDetails {
	display: block;
	justify-content: flex-start;

	@media (min-width: 767px) and (max-width: 991px) {
		display: inline-flex;
	}

	@media (min-width: 1200px) {
		display: inline-flex;
	}

	.details {
		margin: 0 40px 30px 0 !important;
	}
}

.hidden {
	pointer-events: none;
	opacity: 0;
	height: 0;
	margin: 0 !important;
}

.minWidth {
	width: 187px;

	.label,
	.value {
		word-break: break-all;
	}
}

.ibanNumber {
	max-width: 200px;
}

.addressValue {
	width: 225px;
}

.ibanOptions {
	position: absolute;
	border-radius: 16px;
	background-color: #ebf1ff;
	border: 1px solid #3d72fc34;
	padding: 4px;
	left: 50%;
	transform: translate(-50%);
	top: 30px;
	width: 100%;
	display: grid;
	grid-template-columns: 50% 50%;

	@media (min-width: 600px) {
		top: 0;
		width: initial;
	}

	li {
		margin-right: 8px;

		&:last-child {
			margin-right: 0;
		}

		button {
			border-radius: 12px;
			color: $c_blue_100;
			background-color: transparent;
			box-shadow: none;
			text-align: center;
			border: 0;
			font-size: $fs_medium;
			font-weight: $fw_medium;
			text-transform: uppercase;
			padding: 0 24px;
			height: 32px;
			width: 100%;
			cursor: pointer;
			transition: $tr_fast;

			&.active {
				background-color: $c_blue_100;
				color: white;
			}

			&:hover {
				background-color: darken(#ebf1ff, 7%);
			}
		}
	}
}

.status {
	font-size: $fs_xsmall;
	font-weight: $fw_medium;
	border-radius: 4px;
	color: rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.5);
	width: 58px;
	height: 17px;
	display: flex;
	align-items: center;
	justify-content: center;

	&.active {
		color: $c_blue_100;
		border-color: $c_blue_100;
	}
}
