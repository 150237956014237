@import '../../../stylesheets/variables';

.container {
	display: flex;
	flex-flow: column;
	max-width: 768px;
	align-items: center;
	margin: 0 auto;
	@include legacyGap(24px, column);
}

.content {
	display: flex;
	flex-flow: column;
	width: 100%;
	max-width: 328px;
	margin-bottom: 20px;
	@include legacyGap(24px, column);
}

.label {
	color: $c_blue_300;
	font-size: $fs_medium;
	font-weight: $fw_medium;
}

.field {
	font-size: $fs_medium;
}

.option {
	display: flex;
	align-items: center;
	@include legacyGap(8px, row);

	.optionIcon {
		width: 20px;
		height: 20px;
	}

	.optionlabel {
		color: $c_black;
		font-size: $fs_medium;
	}
}

.button {
	min-width: 60%;
	align-self: center;
}

.singleWallet {
	height: 40px;
	cursor: default;
	border: 1px solid $c_gray_300;
	border-radius: 4px;
	padding: 2px;
}
