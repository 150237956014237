@import '../../../stylesheets/variables.scss';

.post {
	font-size: $fs_small;
	color: black;
	font-weight: $fw_regular;
	padding: 0 20px;
	line-height: 20px;

	@media (min-width: 992px) {
		padding: 0 40px;
	}
}

.form {
	padding: 26px 72px;
	display: block;

	label {
		font-size: $fs_medium;
		font-weight: $fw_regular;
		margin-bottom: 26px;
	}
}

.button {
	margin: 0 auto;
	display: block;
	min-width: 180px;
}

.error {
	margin: 0 auto;
	max-width: 380px;
	width: 100%;
}
