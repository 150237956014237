@import '../../../../stylesheets/variables.scss';

.button {
	max-width: 232px;
	width: 100%;
	margin: 0 auto;
	display: flex;
}

.margin {
	margin: 10px 0 0 !important;
}

.smallerButton {
	max-width: 232px;
	width: 100%;
	margin: 20px auto 0;
	display: flex;
	font-size: 14px;
}

.link {
	margin-top: 32px;
	text-decoration: none;
	font-weight: $fw_medium;
	color: $c_blue;
	display: block;
}

.getVirtualContainer {
	border: 1px solid #4972f4;
	max-width: 380px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	margin: 0 auto;

	.getVirtualContent {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;

		h1 {
			font-weight: $fw_medium;
			font-size: 18px;
			color: $c_blue_300;
			margin: 0 0 12px 23px;
			text-align: left;
		}

		h2 {
			font-size: 12px;
			color: $c_blue_300;
			text-align: left;
			line-height: 20px;
			font-weight: $fw_regular;
			margin: 0 0 0 23px;
		}
	}
}
