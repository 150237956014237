@import '../../../../stylesheets/variables';

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 40px 40px 40px;

	&.mobile {
		padding: 20px 20px 20px 20px;
	}

	.mainImage {
		margin-bottom: 40px;
	}

	.description {
		font-size: $fs_small;
		margin-bottom: 40px;
	}

	.apiLink {
		padding: 0;
		font-size: $fs_small;
		height: auto;
		min-width: auto;
		line-height: $lh_regular;
	}

	.createButton {
		font-size: $fs_medium;
		width: 161px;
		height: 46px;
	}

	.apiListTitle {
		display: block;
		align-self: start;
		font-size: $fs_xxlarge;
		font-weight: $fw_medium;
		margin: 24px 0;
	}
}

.apiKeys {
	.apiListItemDesktop,
	.apiKeysTableHeader {
		display: flex;

		.apiNameCol {
			width: 200px;
			padding: 0 16px;
		}

		.clientIdCol {
			width: 300px;
			padding: 0 40px 0 16px;
		}

		.enabledCol {
			width: 140px;
			padding: 0 16px;
		}

		.arrowCol {
			width: 56px;
			padding: 0 16px;
		}
	}

	.colTitle {
		font-size: $fs_medium;
		color: $c_blue_500;
		font-weight: $fw_medium;
	}

	.apiListItemContainer {
		padding: 8px 0;

		&.mobile {
			padding: 24px 0;

			// first child
			&:first-child {
				padding: 0 0 24px 0;
			}
		}

		&:not(:last-child) {
			border-bottom: 1px solid $c_gray_400;
		}

		.apiName {
			display: block;
			font-weight: $fw_medium;
		}

		.enabledCol,
		.arrowCol {
			display: flex;
			align-items: center;
		}

		.toggle {
			margin-right: 15px;
		}

		.apiListItemDesktop {
			padding: 16px 0;
			font-size: $fs_medium;
			line-height: $lh_large;

			&:hover {
				background-color: $c_gray_200;
				border-radius: 8px;
			}
		}

		.mobileListItemRow {
			display: flex;
			margin-bottom: 16px;

			.colTitle {
				align-self: center;
				flex: 1;
			}

			.descriptionCol {
				flex: 2;
				margin-left: 16px;
			}

			.toggle {
				margin-right: 12px;
			}
		}
	}
}

.icon {
	color: $c_blue_500;
}
