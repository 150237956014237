@import '../../stylesheets/variables.scss';

.container {
	max-width: 380px;
	width: 100%;
	margin: 0 auto;
	background-color: #f9f9fc;
	border-radius: 6px;
	margin-bottom: 32px;
	padding: 25px;
	gap: 16px;
	display: flex;
	flex-flow: column;

	@media (min-width: 768px) {
		padding: 40px 48px 20px;
	}
}

.post {
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: black;
	line-height: 20px;
	text-align: left;
}

.button {
	display: block;
	max-width: 232px;
	width: 100%;
	margin: 0 auto;

	&:last-child {
		margin-bottom: 0;
	}

	&.small {
		font-size: $fs_medium;
		padding: 0;
		margin: 0 auto;
		height: 24px;
	}
}

.hidden {
	opacity: 0;
	pointer-events: none;
}

.input {
	margin-bottom: 40px;
}

.resend {
	position: absolute;
	bottom: 8px;
	right: 12px;
	font-size: $fs_small;
	color: $c_blue_100;
	line-height: $lh_medium;
	font-weight: $fw_medium;
	cursor: pointer;
	min-width: 35px;
}

.blue {
	color: $c_blue_100;
	font-weight: $fw_medium;
}

.timer {
	font-size: $fs_xxxlarge;
	color: $c_blue_100;
	line-height: $lh_medium;
	font-weight: $fw_medium;
	cursor: pointer;
	min-width: 35px;
	text-align: center;
	margin: 8px auto;
}

.description {
	color: $c_blue_300;
	line-height: 20px;
	margin: 0 0 36px;
	font-size: 12px;
	font-weight: $fw_medium;
}
