@import '../../../../stylesheets/variables.scss';

.container {
	position: relative;
}

.title {
	font-size: 20px;
	line-height: $lh_medium;
	font-weight: $fw_medium;
	color: black;
	margin: 0 auto 8px;
}

.img {
	max-width: 380px;
	width: 100%;
	margin-bottom: 41px;
}

.button {
	width: 232px;
	display: block;
	margin: 0 auto 100px;
}

.checkbox {
	margin: 0 auto 40px;
	text-align: center;
	display: flex;
	justify-content: center;
}

.back {
	position: absolute;
	top: -110px;
	left: -40px;

	@media (max-width: 500px) {
		top: -95px;
		left: -25px;
	}
}

.form {
	position: relative;
}

.error {
	color: $c_red;
	position: absolute;
	bottom: -35px;
	left: 0;
	width: 100%;
	font-size: $fs_medium;
}
