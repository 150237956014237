@import '../../../../stylesheets/variables.scss';

.button {
	margin: 0 auto 15px;
	width: 232px;
	display: block;
}

.link {
	font-size: $fs_medium;
	width: initial;
}
