@import '../../../../../stylesheets/variables.scss';

.assets {
	display: flex;
	align-items: center;
	cursor: initial;
	margin-bottom: 19px;

	@media (min-width: 600px) {
		padding-right: 25px;
		margin-bottom: 0;
	}

	.icon {
		padding: 0;
		margin: 0;
		border: 2px solid white;
		box-shadow: none;

		&.margin {
			margin-left: -7px;
			margin-right: 8px;
		}
	}
}

.icon {
	width: 26px;
	height: 26px;
}

.frequency {
	background-color: #ebf1ff;
	border-radius: 4px;
	color: $c_blue_100;
	font-size: $fs_small;
	font-weight: $fw_medium;
	padding: 5px;
	margin-left: 8px;
}

.amount {
	display: flex;
	align-items: flex-start;
	cursor: initial;
	color: black;
	font-weight: $fw_regular;
	word-break: break-all;
	padding: 0 10px 0 0;
}

.interval {
	cursor: initial;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: black;

	span {
		margin-top: 2px;
		display: block;
		font-size: $fs_small;
		color: #757575;
	}
}

.arrow {
	margin: 0 4px;
}

.close {
	font-size: 20px;
	color: $c_blue_100;
	margin-left: auto;
	margin-right: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.label {
	font-size: $fs_small;
	color: black;
	font-weight: $fw_regular;
	margin-left: 13px;
}

.status {
	display: flex;
	align-items: center;

	* {
		display: flex;
		align-items: center;
		flex-direction: row;
	}
}

.grid {
	display: grid;
	grid-template-columns: 40% 60%;
	margin-bottom: 19px;
	cursor: initial;

	@media (min-width: 600px) {
		grid-template-columns: 100%;
		margin-bottom: 0;

		> span:first-child {
			display: none;
		}
	}
}

.firstCol {
	@media (min-width: 600px) {
		display: none;
	}
}

.lastCol {
	display: none;

	@media (min-width: 600px) {
		display: block;
	}
}
