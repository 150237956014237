@import '../../../../../stylesheets/variables';

.container {
	display: flex;
	flex-flow: column;
	line-height: $lh_regular;
	font-size: $fs_small;
	@include legacyGap(48px, column);
}

.title {
	align-self: flex-start;
}

.banner {
	max-width: 436px;

	img {
		width: 436px;
		height: 162px;
	}
}

.listItem {
	padding: 8px 0;

	display: flex;

	&::before {
		font-family: 'FontAwesome';
		font-size: 8px;
		content: '\f111';
		margin-right: 16px;
	}
}

.actions {
	display: flex;
	flex-flow: column;
	align-items: center;

	@media (min-width: 768px) {
		flex-flow: row;
		justify-content: center;
		@include legacyGap(8px, row);
	}

	@media (max-width: 767px) {
		@include legacyGap(8px, column);
	}

	.action {
		font-size: $fs_medium;
		min-width: 30%;
		text-transform: none;
	}
}
