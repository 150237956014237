@import '../../../../stylesheets/variables.scss';

.container {
	padding: 40px 80px;
	text-align: center;
	max-height: 100vh;
	overflow-y: auto;
	position: relative;

	@media (min-width: 768px) {
		width: 680px;
	}
}

.title {
	font-size: 28px;
	color: $c_blue_300;
	font-weight: $fw_medium;
	margin: 25px auto 0;
	text-align: center;
}

.list {
	text-align: left;

	li {
		padding: 15px 0;
		border-bottom: 1px solid $c_gray_400;

		&:last-child {
			border: none;
		}
	}
}

.info {
	* {
		font-size: $fs_large;
	}

	label {
		margin-top: 24px;
	}

	> div {
		word-break: break-all;
	}
}

.date {
	font-size: $fs_small;
	color: #757575;
	text-align: center;
	margin-top: 3px;
}

.status {
	margin-top: 6px;
	text-align: center;
}
