@import '../../../../../stylesheets/variables';

.container {
	display: flex;
	flex-flow: column;
	@include legacyGap(16px, column);
}

.title {
	align-self: flex-start;
}

.form {
	align-self: center;
}

.button {
	align-self: center;
	min-width: 60%;
}
