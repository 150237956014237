@import '../../../../stylesheets/variables';

.container {
	display: flex;
	flex-flow: column;
	padding-top: 20px;
}

.header {
	display: flex;
	flex-flow: row;
	align-items: center;
}

.body {
	display: flex;
	flex-flow: column;
	gap: 16px;
}

.content {
	display: flex;
	min-height: 200px;
	width: 100%;
}

.note {
	font-size: $fs_small;
}

.button {
	display: flex;
	font-size: $fs_medium;
	color: $c_white;
	height: 40px;

	&:hover {
		color: $c_white;
	}
}

.statusCode {
	border-radius: 4px;
	padding: 4px 8px;
	width: max-content;
	font-size: $fs_medium;
	font-weight: $fw_medium;

	&.error {
		color: $c_red_100;
		background: rgba(255, 175, 148, 0.1);
	}

	&.warning {
		color: $c_orange_100;
		background: rgba(248, 180, 53, 0.1);
	}

	&.success {
		color: $c_green;
		background: rgba(167, 255, 116, 0.1);
	}
}

.statusHeader {
	display: flex;
	gap: 8px;
	align-items: center;
}

.strong {
	font-weight: $fw_medium;
}

.tooltip {
	white-space: pre-wrap;
}
