@import '../../stylesheets/variables';

.accordion {
	list-style: none;
}

.accordionItem {
	border-bottom: 1px solid $c_gray_400;
}

.accordionItemBody {
	padding: 0 16px;
	display: flex;
	align-items: center;
}

.accordionButton {
	background-color: $c_white;
	font-size: $fs_large;
	color: $c_blue_300;
	text-align: left;
	font-weight: $fw_medium;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 17.5px 0;
	cursor: pointer;
	border: none;
}

.answer {
	padding: 24px 16px;
}

.accordionItem.active .accordionButton,
.accordionItem.active .accordionItemBody {
	background-color: $c_gray_200;
	border-radius: 4px;
}

.accordionItem.active .accordionItemBody {
	margin-top: 4px;
}

.answerWrapper {
	height: 0;
	overflow: hidden;
	transition: height ease 0.2s;
}

.opened {
	height: auto;

	.answer {
		font-size: $fs_small;
		padding: 24px 16px;
	}
}

.arrow {
	transform: rotate(90deg);
}

.toggledArrow {
	transform: rotate(270deg);
}

.checkboxLabel {
	margin-bottom: 0;
	margin-right: 12px;
}
