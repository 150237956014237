@import '../../stylesheets/variables.scss';

.container {
	display: flex;
	flex-wrap: wrap;
	@include legacyGap(10px, column);
	@include legacyGap(10px, row);

	@media (min-width: 560px) {
		flex-wrap: initial;
	}

	> div {
		flex: 1;

		> * {
			width: 100%;
		}

		&:nth-child(3) {
			margin-right: 10px;
			width: 100%;
		}

		&:last-child {
			margin-right: 10px;
		}

		@media (min-width: 560px) {
			width: initial;
			flex: unset;

			> * {
				width: initial;
				max-width: 170px;
			}

			&:nth-child(3) {
				width: 50%;
			}
		}
	}
}

.label {
	color: $c_blue_300;
}

.select {
	height: 40px;
	min-width: 150px;
}
