@import '../../stylesheets/variables.scss';

.container {
	margin-top: 24px;
	position: relative;
	min-height: 214px;

	.loader {
		right: calc(50% - 12px);
		top: calc(50% - 12px);
		position: absolute;
	}
}

.list {
	margin-bottom: 8px;

	li {
		text-align: left;
		align-items: center;
		padding: 11px 0;
		transition: $tr_fast;
		cursor: pointer;
		display: flex;

		&:hover {
			background: #f5f8ff; /* Old browsers */
			background: -moz-linear-gradient(
				left,
				#ffffff 0%,
				#f5f8ff 51%,
				#ffffff 100%
			); /* FF3.6-15 */
			background: -webkit-linear-gradient(
				left,
				#ffffff 0%,
				#f5f8ff 51%,
				#ffffff 100%
			); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				to right,
				#ffffff 0%,
				#f5f8ff 51%,
				#ffffff 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
		}
	}

	&.WALLET {
		li {
			display: flex;
		}
	}

	&.DEPOSITS {
		.date {
			display: block;
			margin-left: 0;
		}

		li {
			display: flex;
		}
	}

	&.isLoading {
		opacity: 0.3;
		pointer-events: none;
	}
}

.imgContainer {
	text-align: center;
	margin: 74px auto;

	img {
		margin-bottom: 24px;
	}

	p {
		font-size: $fs_medium;
		color: #b3b6c6;
		line-height: 16px;
		font-weight: $fw_regular;
	}
}
