@import '../../../stylesheets/variables';

.container {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 20px 0 0;
	padding: 0 20px 0 20px;

	@media (min-width: 768px) {
		padding: 0 40px 0 40px;
	}
}

.statusImg {
	img {
		width: 160px;
	}
}

.statusText {
	font-weight: $fw_medium;
	font-size: 20px;
	margin: 40px 0;
}

.button {
	width: 176px;
}
