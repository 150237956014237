.container {
	max-width: 1160px;
	width: 100%;
	margin: 0 auto;
}

.flexGrid {
	display: block;

	@media (min-width: 992px) {
		display: flex;
	}
}

.sidebar {
	display: block;

	@media (min-width: 992px) {
		flex: 1;
	}
}

.content {
	display: block;

	@media (max-width: 1150px) and (min-width: 1065px) {
		max-width: auto;
	}

	@media (min-width: 992px) {
		margin-left: 16px;
		flex: 3;
	}

	&.noSidebar {
		display: block;
		margin: 0 auto;
		flex: initial;
		width: 100%;
	}

	&.ibans {
		margin: 0 auto;
	}
}
