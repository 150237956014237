@import '../../stylesheets/variables.scss';

.title {
	font-size: $fs_large;
	line-height: 21px;
	font-weight: $fw_medium;
	color: black;
	display: flex;
	align-items: center;
	margin: 10px 0 20px 16px;

	span {
		width: 100%;
	}

	@media (max-width: 767px) {
		flex-direction: column;
		align-items: flex-start;
	}

	@media (max-width: 1150px) and (min-width: 992px) {
		@include legacyGap(10px, column);
		flex-direction: column;
	}

	.btnContainer {
		@include legacyGap(16px, row);

		display: inline-flex;
		justify-content: flex-end;
		flex: 1;
		white-space: nowrap;
		margin: 0 16px 0 5px;

		@media (max-width: 1150px) and (min-width: 992px) {
			margin: 0 5px;
			width: 100%;

			button {
				padding: 12px 10px;
			}
		}

		.walletBlockButtons {
			font-size: $fs_medium;
			min-width: 170px;

			div {
				display: flex;
				align-items: center;

				span {
					font-size: 13px;
				}
			}

			svg {
				margin-right: 8px;
				width: 13px;
			}

			@media (max-width: 767px) {
				width: 100%;
			}

			@media (max-width: 1150px) and (min-width: 992px) {
				min-width: auto;
				width: 100%;
			}
		}

		@media (max-width: 767px) {
			margin: 15px 0 0 0;
			width: 100%;
			flex-direction: column;

			.middleBtn {
				margin: 10px 0;
			}

			.walletBlockButtons {
				flex: 1;
				height: 40px;
			}
		}
	}
}

.priceText {
	margin: 0 15% auto 20%;
	padding-left: 15px;
	cursor: auto;
}

.addWalletRateText {
	@media (min-width: 1110px) {
		padding-left: 45px;
	}

	@media (min-width: 767px) {
		padding-left: 0;
	}
}

.managePriceText {
	margin: 0 0 0 25%;
}

// !important needed, because we're using &:first-child on a different className, that puts these css values
.addNewBalanceText {
	margin-left: 34px !important;
	cursor: default !important;

	@media (min-width: 1110px) {
		margin-right: 19% !important;
	}

	@media (min-width: 767px) {
		margin-left: auto !important;
		margin-right: 23%;
	}
}

.currencyIcon {
	min-width: 40px;
	min-height: 40px;
}

.filterBarContainer {
	background-color: white;
	border-radius: 8px 8px 0 0;
	box-shadow: 0 6px 10px #4972f40d;
	width: 100%;
	text-align: center;
	padding: 16px;

	@media (min-width: 768px) {
		padding: 37px 25px 37px 37px;
	}

	@media (max-width: 1150px) and (min-width: 992px) {
		padding: 25px 15px;
	}
}

.header {
	display: block;
	flex: 1;

	@media (min-width: 768px) {
		display: flex;
		text-align: center;
	}
}

.toggleContentContainer {
	display: flex;
	flex-direction: column;

	@media (min-width: 1041px) {
		flex-direction: row;
		align-items: center;
	}
}

.filterTitle {
	font-size: 10px;
	color: #4e4e4e;
	font-weight: $fw_regular;
	display: block;
	margin-bottom: 8px;
	max-width: 438px;
	margin: 0 0 8px;
	text-align: left;
}

.smallerInput {
	max-width: 100%;
	margin: 0 16px 14px 0;

	input {
		min-width: 100%;
	}
}

.input {
	margin: 0 0 14px;
	width: 100%;
	max-width: 100%;

	@media (min-width: 768px) {
		margin: 0 20px 0 0;
		width: 100%;
		max-width: 438px;
	}

	> input {
		width: 100%;
		min-width: 100%;
		max-width: 100%;

		@media (min-width: 768px) {
			min-width: auto;
			max-width: 438px;
		}
	}
}

.filter {
	margin: 0;
	max-width: 100%;
	text-align: left;

	@media (min-width: 768px) {
		display: block;
		max-width: 438px;
		margin: 0 auto;
	}

	li {
		display: inline-flex;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: $fw_medium;
		margin-right: 8px;
		color: $c_blue_100;
		border: 1px solid $c_blue_100;
		border-radius: 4px;
		min-width: 55px;
		padding: 0 3px;
		height: 17px;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: $tr_fast;

		&.noMargin {
			margin-right: 0;
			margin-bottom: 8px;
		}

		&:hover,
		&.active {
			animation: glowing 300ms ease;
		}

		&.active,
		&:active,
		&:focus {
			background-color: $c_blue_100;
			color: white;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.walletList {
	box-shadow: 0 6px 10px #4972f40d;
	width: 100%;
	display: block;
	margin-bottom: 40px;

	li {
		background-color: white;
		display: flex;
		align-items: center;
		height: 111px;
		border-bottom: 1px solid $c_gray_400;
		position: relative;
		transition: $tr_fast;

		&:hover {
			background: #f5f8ff; /* Old browsers */
			background: -moz-linear-gradient(
				left,
				#ffffff 0%,
				#f5f8ff 51%,
				#ffffff 100%
			); /* FF3.6-15 */
			background: -webkit-linear-gradient(
				left,
				#ffffff 0%,
				#f5f8ff 51%,
				#ffffff 100%
			); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				to right,
				#ffffff 0%,
				#f5f8ff 51%,
				#ffffff 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
		}

		&:first-child,
		&:last-child {
			&:hover {
				background: white;
			}
		}

		&:first-child {
			border: 0;
			padding: 11px 35px 11px 50px;
			display: flex;
			height: auto;

			@media (max-width: 767px) {
				padding: 11px 35px 11px 17px;
			}

			.addRight {
				margin-right: auto;
			}

			span {
				font-size: $fs_medium;
				font-weight: $fw_medium;
				color: $c_blue_300;
				line-height: 24px;
				display: flex;
				align-items: center;
				cursor: pointer;

				&:last-child {
					display: inline-flex;

					@media (min-width: 767px) {
						margin-left: auto;
					}
				}
			}
		}

		&.addCurrency {
			button {
				margin: 0 auto;
			}

			> a {
				.balance {
					font-size: $fs_small;
					font-weight: $fw_medium;
					color: $c_blue_300;
					cursor: pointer;
				}
			}
		}

		&:last-of-type {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			border-bottom: 0;
		}
	}
}

.toolTipContainer {
	display: flex;
	flex-direction: column;
	align-items: center;

	.toolTipBottomArrow {
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;

		border-top: 8px solid $c_blue_300;
	}

	.toggleToolTip {
		background-color: $c_blue_300;
		border-radius: 4px;
		color: white;
		padding: 12px 22px;
		font-size: $fs_medium;
		font-weight: $fw_regular;
		line-height: 20px;
		text-align: center;
	}
}

.emptyWalletsToggleContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-top: 22px;

	@media (min-width: 1041px) {
		margin-left: 25px;
	}
	img {
		height: 14px;
		width: 14px;
	}

	span {
		color: $c_blue_500;
		font-size: $fs_medium;
		font-weight: $fw_medium;
		text-align: left;
		margin-left: 8px;
	}
}

.filterContainer {
	display: flex;

	.drop {
		&:not(:first-child) {
			margin-left: 16px;
		}
	}
}

.manageWalletsBalance {
	margin-left: 0;

	@media (min-width: 767px) {
		text-align: left;
	}
}

.plusWallet {
	margin: 0 15px 0 10px;
}

.loader {
	margin: 16px 32px;
}

.button {
	text-align: center;
	margin: 0 auto;
}

.hideIfEmpty {
	margin-left: auto;
	text-align: right;
}

.imgContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: $c_white;
	font-size: 14px;
	color: $c_gray_500;
	padding: 35px 0;
}

.grow {
	@media (max-width: 767px) {
		flex: 1;
	}
}
