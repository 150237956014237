@import '../../../../stylesheets/variables.scss';

.container {
	max-width: 667px;
	width: 100%;
	padding: 40px 150px;
	text-align: center;
}

.button {
	margin: 0 auto 15px;
	display: block;
	max-width: 243px;
	width: 100%;
	text-align: center;
}

.title {
	font-size: $fs_huge;
	color: $c_blue_300;
	line-height: 34px;
	font-weight: $fw_medium;
	margin: 0 auto 16px;
}

.post {
	font-size: $fs_medium;
	color: black;
	line-height: 24px;
	font-weight: $fw_regular;
	margin: 0 auto 40px;
}

.close {
	margin: 0 auto 40px;
	width: 90px;
	height: 90px;
	border-radius: 50%;
	background: $c_blue_100;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 50px;
	color: white;
	transform: rotate(45deg);
	position: relative;
	z-index: 1;
	border: 6px solid lighten($c_blue_100, 20%);
}
