@import '../../../../stylesheets/variables';

.title {
	margin: 0;

	> div {
		font-size: $fs_large;
	}
}

.body {
	font-size: $fs_small;
	display: flex;
	gap: 44px;
	padding: 0;
}

.headerContainer {
	display: flex;
}

.actions {
	display: flex;

	@include mobile {
		flex-flow: column;
		gap: 16px;
		padding: 20px;
	}

	.button {
		font-weight: $fw_medium;
		font-size: $fs_medium;
	}
}

.left {
	padding: 0;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	padding: 40px;
	gap: 24px;

	@include mobile {
		padding: 0;
	}
}

.text {
	display: flex;
	flex-flow: column;
	gap: 16px;

	@include mobile {
		padding: 20px 0 0 20px;
	}
}

.right {
	display: flex;
	align-items: center;
}

.icon {
	display: flex;
	margin: 0 20px 0 20px;

	@include mobile {
		align-self: flex-start;
		width: 100px;
	}

	@include tablet {
		width: 155px;
	}

	@include tablet-wide {
		width: 201px;
	}

	@include monitor {
		width: 201px;
	}
}
