@import '../../../../stylesheets/variables.scss';

.option {
	display: flex;
	align-items: center;
	cursor: pointer;

	* {
		cursor: pointer;
	}

	.currencyIcon {
		width: 20px;
		height: 20px;
		margin-right: 8px;
		margin-left: 0;
	}

	.currencyLabel {
		margin-right: 5px;
	}

	.currencyLabel {
		align-items: center;
		display: flex;
		font-size: $fs_medium;
		font-weight: $fw_regular;
	}

	.currencyName {
		color: #b3b6c6;
	}
}
