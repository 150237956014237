@import '../../../../stylesheets/variables.scss';

.container {
	position: relative;
}

.back {
	position: absolute;
	top: -110px;
	left: -40px;

	@media (max-width: 500px) {
		top: -95px;
		left: -25px;
	}
}

.dataBox {
	max-width: 380px;
	width: 100%;
	margin: 0 auto 25px;
	text-align: left;
}

.label {
	font-size: $fs_medium;
	line-height: $lh_medium;
	font-weight: $fw_medium;
	color: $c_blue_300;
	margin-bottom: 8px;
}

.value {
	font-size: $fs_large;
	line-height: 24px;
	font-weight: $fw_regular;
	color: black;
	margin-bottom: 22px;
}

.button {
	width: 232px;
	margin: 0 auto;
}

.error {
	margin-top: 15px;
	font-size: $fs_medium;
	color: $c_red;
}

.linkedWalletItem {
	display: flex;
	align-items: center;

	.currencyIcon {
		width: 20px;
		height: 20px;
		margin-left: 0;
	}
}
