@import '../../stylesheets/variables.scss';

.walletLoader {
	position: absolute;
	top: calc(50% - 12px);
	left: calc(50% - 12px);
	z-index: 10;
}

.linkContainer {
	display: flex;
}

.link {
	display: inline-flex;
	align-items: center;
	font-weight: $fw_medium;
	font-size: $fs_xlarge;
	color: $c_blue_300;
	text-decoration: none;
	max-width: auto;
	width: auto;

	@media (min-width: 767px) {
		width: 100%;
		max-width: 179px;
	}

	&.manageWallets {
		flex: initial;
		text-align: left;
		display: flex;
		margin-right: 0;

		@media (min-width: 767px) {
			margin-right: 75px;
		}

		@media (min-width: 1000px) {
			margin-right: 265px;
		}

		@media (min-width: 767px) {
			width: 200px;
		}
	}

	&.addNewWallets {
		flex: initial;
		text-align: left;
		display: flex;

		@media (min-width: 767px) {
			margin-right: 5vw;
			width: 179px;
		}

		@media (min-width: 1110px) {
			margin-right: 190px;
			width: 200px;
		}
	}

	&.addCurrency {
		font-size: $fs_small;
		font-weight: $fw_medium;
		color: $c_blue_300;
		cursor: pointer;
	}
}

.disabledLink {
	cursor: initial;
	pointer-events: none;
}

.manageWallets {
	flex: initial;
	text-align: left;
	display: flex;
	margin-left: initial;
	margin-right: 35px;

	@media (min-width: 768px) {
		margin-right: 100px;
		min-width: 200px;
	}
}

.noWidth {
	max-width: 100% !important;
}

.percentageValue {
	display: flex;
	margin-left: 4%;
	justify-content: space-between;
	width: 31%;

	@media (min-width: 979px) {
		margin-left: 5%;
	}

	@media (max-width: 1150px) and (min-width: 992px) {
		margin-left: 2%;
	}
}

.opacity {
	@media (min-width: 767px) {
		opacity: 0.4;
	}
}

.manageWalletRates {
	margin-left: 0;

	@media (max-width: 767px) {
		display: none;
	}
}

.currencyText {
	font-size: $fs_large;
	font-weight: $fw_regular;
	color: $c_black_100;
	line-height: 24px;
}

.ratesText {
	font-weight: 500;
	color: $c_green;

	&.negative {
		color: $c_red;
	}
}

.changeContainer {
	display: flex;
	align-items: center;

	span {
		margin-right: 4px;
	}
}

.arrows {
	color: $c_green;
	margin-right: 4px;

	svg {
		width: 16px;
	}

	&.negative {
		color: $c_red;
	}
}

.balance {
	align-items: center;
	font-size: 16px;
	font-weight: $fw_regular;
	word-break: break-word;
	margin-left: 24px;

	@media (min-width: 767px) {
		flex: 1;
		width: 30%;
		text-align: right;
		font-size: 14px;
	}

	@media (max-width: 767px) {
		margin-left: auto;
		text-align: right;
		max-width: 50%;
	}

	&.manageWallets {
		flex: initial;
		text-align: left;
	}
}

.buyButton {
	font-size: $fs_medium;
	max-height: 40px;
	max-width: 80px;
	width: 100%;
	white-space: nowrap;
	margin: 0 0 0 auto;
	color: white;
}

.name {
	font-size: $fs_xlarge;
	font-weight: $fw_medium;
	color: $c_blue_300;
}

.referenceBalance {
	color: $c_gray;
	font-size: 14px;
	display: block;
	justify-content: baseline;
	margin-top: 2px;

	@media (min-width: 767px) {
		font-size: $fs_small;
	}
}

.toggle {
	margin-left: auto;

	div {
		display: flex;
		align-items: center;
	}

	@media (min-width: 767px) {
		margin-left: 20px;
	}
}

.moreActions {
	margin-left: 40px;
	cursor: pointer;
}

.moreActionsContainer {
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	position: absolute;
	right: 10px;
	z-index: 99;
}

.actionsButton {
	display: flex;
	align-items: center;
	padding: 7px 14px;
	transition: $tr_fast;

	&:nth-child(1) {
		margin-top: 7px;
	}

	&:nth-child(3) {
		margin-bottom: 7px;
	}

	&:hover {
		background-color: darken(#ebf1ff, 2%);
		border-color: darken(#ebf1ff, 2%);
	}
}

.actionsText {
	color: #102d6f;
	font-size: $fs_medium;
	line-height: 20px;
	margin-left: 17px;
	font-weight: $fw_medium;
}

.walletContainer {
	padding: 31px 40px;
	width: 100%;
	cursor: pointer;
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	text-decoration: none;
	color: black;

	* {
		text-decoration: none;
	}

	&:first-child {
		border: 0;
		padding: 11px 40px;
		display: flex;

		@media (max-width: 767px) {
			padding: 11px 40px 11px 10px;
		}

		span {
			font-size: $fs_medium;
			font-weight: $fw_medium;
			color: black;
			line-height: 24px;
			display: flex;
			align-items: center;
			cursor: pointer;

			&.manageWallets {
				flex: initial;
				text-align: left;
				display: flex;
				margin-left: initial;
				margin-right: 100px;
				min-width: 200px;
			}

			&:last-child {
				display: inline-flex;
				margin-left: auto;
			}
		}
	}
}

.mobilePrice {
	font-size: 16px;

	p {
		margin: 0;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		svg {
			margin-right: 4px;
			width: 14px;
			height: 14px;
		}
	}
}

.currencyCode {
	margin-left: 20px;
	color: black;

	@media (max-width: 767px) {
		max-width: 50%;
		margin-left: 10px;
	}

	.currencyName {
		opacity: 0.35;
		line-height: 15px !important;
	}
}

.manageName {
	margin-left: 15px !important;
	margin-right: 15px !important;
}

.walletManage {
	padding: 31px 16px 31px 10px;

	@media (min-width: 767px) {
		justify-content: space-between;
		padding: 31px 40px;
	}

	&:hover {
		background: white;
	}
}

.manageWalletContainer {
	justify-content: space-between;
}

.isUpdateLoading {
	opacity: 0.6;
	pointer-events: none;
}
