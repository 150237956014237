@import '../../stylesheets/variables.scss';

.content {
	@media (min-width: 767px) {
		position: relative;
	}

	.container {
		background-color: $c_blue_10;
		padding: 11px 16px;
		font-size: 14px;
		color: $c_blue_100;
		font-weight: 500;
		border-radius: 8px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-basis: initial;
		white-space: nowrap;
		transition: $tr_fast;

		.chevronRight {
			margin-left: 6px;
			widht: 16px;
		}

		&:hover {
			background-color: darken(#ebf1ff, 2%);
			border-color: darken(#ebf1ff, 2%);
		}
	}

	.active {
		background-color: #3656c0;
		color: $c_blue_10;

		img {
			filter: brightness(0) invert(1);
		}

		&:hover {
			background-color: #6c8bf5;
			border-color: #6c8bf5;
		}
	}

	.optionsContainer {
		position: absolute;
		display: flex;
		flex-direction: column;
		text-align: left;
		top: 50px;
		right: 0;
		box-shadow: 0px 10px 10px #4972f40f;
		border: 1px solid $c_gray_300;
		width: 100%;
		z-index: 9999;
		border-radius: 4px;

		.selected {
			font-weight: $fw_medium;
		}

		span {
			background-color: white;
			color: $c_blue_300;
			font-size: $fs_medium;
			padding: 12px 13px;
			width: 100%;
			word-break: break-word;
			transition: $tr_default;
			cursor: pointer;

			&:hover {
				background-color: #f6f8ff;
			}
		}
	}
}

@keyframes slideInBottom {
	0% {
		bottom: -100%;
	}

	100% {
		bottom: 0px;
	}
}

.mobileDropdownContainer {
	background-color: #192e6f99;
	width: 100%;
	height: 100vh;
	position: fixed;
	overflow: hidden;
	bottom: 0;
	left: 0;
	z-index: 999;

	.resizable {
		position: fixed;
		width: 100vw;
		bottom: 0px;
		border-radius: 12px 12px 0px 0px;
		background: #fff;
		touch-action: none;
		animation: slideInBottom 0.2s;
		overflow: hidden;

		.draggable {
			background-color: #e5e5e5;
			border-radius: 8px;
			width: 50px;
			height: 4px;
			margin: 8px auto 24px;
		}

		.closeBtn {
			width: calc(100% - 60px);
			margin-bottom: 25px;
		}

		.selected {
			font-weight: $fw_medium;
		}

		.topMargin {
			padding-top: 12px;
		}

		span {
			display: flex;
			justify-content: space-between;
			margin: auto 30px;
			color: $c_blue_300;
			font-size: $fs_medium;

			&:not(:last-of-type) {
				padding-bottom: 16px;
				border-bottom: 1px solid $c_gray_400;
				margin-bottom: 16px;
			}

			&:last-of-type {
				margin-bottom: 24px;
			}

			svg {
				width: 18px;
				height: 18px;

				path {
					fill: $c_blue_100;
				}
			}
		}
	}
}

.settingsBtn {
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $c_blue_10;
	cursor: pointer;
	transition: $tr_fast;

	&:hover {
		background-color: darken(#ebf1ff, 2%);
		border-color: darken(#ebf1ff, 2%);
	}
}

.settingsDropdown {
	@media (min-width: 767px) {
		position: absolute;
		border-radius: 4px;
		background-color: white;
		box-shadow: 0px 10px 10px #4972f40f;
		border: 1px solid $c_gray_300;
		color: black;
		width: max-content;
		max-width: 267px;
		min-width: 267px;
		display: flex;
		flex-direction: column;
		z-index: 999;
	}

	@media (max-width: 767px) {
		margin: 0 15px;

		a {
			margin: 12px 0 5px;
			width: 100%;

			div {
				&:first-child {
					width: 100%;
				}
			}
		}
	}

	.option {
		display: flex;
		flex: 1;
		padding: 12px 16px;
		align-items: center;
		text-decoration: none;
		color: $c_blue_300;
		font-size: $fs_medium;
		font-weight: 500;
		transition: $tr_default;
		justify-content: space-between;

		&:first-child {
			cursor: pointer;
			&:hover {
				background-color: #f6f8ff;
			}
		}

		.contentContainer {
			display: flex;
			align-items: center;
			margin-right: 12px;
		}

		.icon {
			background-color: $c_blue_10;
			color: $c_blue_100;
			border-radius: 50%;
			width: 24px !important;
			height: 24px;
			margin-right: 12px;
			font-weight: 600;
			font-size: 18px;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				width: 14px;
			}

			img {
				width: 18px;
				filter: none;
			}

			@media (max-width: 767px) {
				width: 40px !important;
				height: 40px;
				font-size: 30px;

				svg {
					width: 20px;
				}

				img {
					width: 28px;
					filter: none;
				}
			}
		}
	}
}

.settingsClosebtn {
	margin-top: 12px;
}

// Settings button is very tiny, need to move the settings dropdown a bit more, than 100% to the left;
.settingsAlign {
	left: auto !important;
	right: 580% !important;
}

.largerWidth {
	@media (min-width: 767px) {
		width: 114px;
	}
}

.smallerWidth {
	@media (min-width: 767px) {
		width: 84px;
	}
}
