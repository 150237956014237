@import '../../../../stylesheets/variables';

.form {
	width: 100%;
	max-width: 328px;

	@media (max-width: 1200px) {
		margin: 0 auto;
	}

	> div:first-child > label {
		margin-top: 0;
	}
}

.container {
	margin: 20px 0 0;

	padding: 0 20px 20px 20px;

	@media (min-width: 768px) and (max-width: 1024px) {
		display: flex;
	}

	@media (min-width: 1024px) {
		display: flex;
		padding: 0 40px 40px 40px;
	}
}

.column {
	position: relative;
	margin: 0 0 40px;
	padding-top: 90px;

	@media (min-width: 600px) {
		padding-top: 0;
	}

	&:last-child {
		margin: 0;

		@media (min-width: 1024px) {
			margin-left: auto;
			margin-right: 0;
		}
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		flex: 1 1 calc(50% - 80px);
		margin: 0 80px 0 0;

		&:last-child {
			margin: 0;
		}

		&.template {
			flex: 1 1 calc(50% - 50px);
			margin: 0 50px 0 0;
		}
	}

	@media (min-width: 1200px) {
		flex: 1 1 calc(50% - 80px);
		margin: 0 80px 0 0;

		&:last-child {
			margin: 0;
		}

		&.template {
			flex: 1 1 calc(50% - 50px);
			margin: 0 50px 0 0;
		}
	}
}

.howToDepositColumn {
	max-width: 280px;
}

.input {
	margin: 0 0 24px;
}

.receiveAmount {
	position: relative;
	margin: 0 0 40px;
}

.receiveError {
	bottom: -24px;
}

.nextButton {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0 auto !important;
	max-width: 232px;
	width: 232px;
}

.label {
	color: $c_blue_300;
	font-size: 12px;
	font-weight: 500;
	margin: 0 0 8px;
}

.value {
	color: $c_black;
	font-size: 16px;
	font-weight: 400;
}

.item {
	margin: 0 0 24px;
}

.copyButtonWrapper {
	align-items: center;
	display: flex;
	white-space: nowrap;
	justify-content: space-between;
	margin: 0 0 8px;
}

.copyButton {
	background: none;
	border: none;
	color: $c_blue_100;
	font-size: 12px;
	font-weight: 500;
	margin: 0;
	outline: none;
	white-space: nowrap;
	padding: 0;
	transition: $tr_fast;

	&:hover,
	&:focus {
		color: $c_blue_200;
	}

	&.copyAllDetails {
		display: flex;
		justify-content: center;
		margin: 0 auto 50px;
	}
}

.copyButtonLabel {
	color: $c_blue_300;
	display: block;
	font-size: 12px;
	font-weight: 500;
}

.copyButtonValue {
	color: $c_black;
	font-size: 16px;
	font-weight: 400;
	max-width: 100%;
	overflow-wrap: break-word;
}

.doneButton {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 40px auto 10px !important;
	max-width: 232px;
	width: 232px;
}

.shareLinkWrapper {
	display: flex;
	justify-content: center;
	margin: 0 auto 16px;
}

.shareLink {
	color: $c_blue_100;
	font-size: 12px;
	font-weight: 500;
	text-decoration: none;
	transition: $tr_fast;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $c_blue_200;
	}
}

.depositWalletSelect {
	margin: 0 0 32px;
}

.template {
	h3 {
		font-size: 16px;
		font-weight: 500;
		color: $c_blue_300;
		margin: 0 0 24px;
	}

	table {
		font-size: 12px;
		font-weight: 400;
	}

	td {
		padding-right: 5px;
	}
}

.loader {
	margin: auto;
}

.heading {
	color: $c_black;
	font-size: $fs_large;
	font-weight: $fw_medium;
	margin: 0 0 24px;
}

.instructions {
	font-size: $fs_small;
	font-weight: $fw_regular;
	color: $c_black;
}

.option {
	display: flex;
	align-items: center;
	cursor: pointer;

	* {
		cursor: pointer;
	}

	.currencyIcon {
		width: 20px;
		height: 20px;
		margin: 0 10px 0 0;
	}
}

.currencyInputContainer {
	max-width: 438px;
}
