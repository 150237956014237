@import '../../../../stylesheets/variables.scss';

.loader {
	margin: auto;
}

.form {
	width: 100%;
	max-width: 328px;

	@media (max-width: 1200px) {
		margin: 0 auto;
	}

	> div:first-child > label {
		margin-top: 0;
	}
}

.button {
	margin: 40px auto 0;
	display: block;
	max-width: 232px;
	width: 100%;
	text-align: center;
}

.label {
	margin-top: 32px;
	color: $c_blue_300;
	font-weight: $fw_medium;
	font-size: $fs_medium;
	display: block;
	margin-bottom: 8px;
}

.select {
	padding-top: 12px;
	max-width: 300px;
	width: 100%;
	margin-left: 40px;
}

.option {
	display: flex;
	align-items: center;
	cursor: pointer;

	* {
		cursor: pointer;
	}
}

.currencyIcon {
	width: 20px;
	height: 20px;
	margin-right: 8px;
}

.currencyLabel {
	margin-right: 5px;
}

.currencyLabel {
	align-items: center;
	display: flex;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	color: black;
}

.currencyName {
	color: #b3b6c6;
}
