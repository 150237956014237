@import '../../../../stylesheets/variables.scss';

.modal {
	width: 100%;
	height: 100%;
}

.mobileSelect {
	width: 100%;
	border-radius: 8px;
	height: 80vh;
	overflow-y: scroll;

	li {
		cursor: pointer;
		padding: 10px 15px;

		&:first-child {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}

		&:last-child {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}

		&:hover,
		&.selected {
			background-color: #f5f8ff;
		}
	}
}

.mobileInputContainer {
	position: relative;
	display: block;
	width: 100%;
	padding: 0 10px;
	text-align: center;
	margin: 55px auto 25px;
	max-width: 433px;

	.inputContainer {
		max-width: 433px;
		position: absolute;
		top: 10px;
		left: 25px;
	}

	.searchInput {
		padding: 10px;
		display: block;
		width: 100%;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #d9dbe5;
		transition: $tr_fast;
		padding-left: 42px;
		font-size: $fs_medium;
		margin: 0 auto;
		font-weight: $fw_regular;
		line-height: 16px;

		&:active,
		&:focus {
			border-color: darken(#d9dbe5, 15%) !important;
			outline: none;
			color: black;
			box-shadow: none;
		}
	}
}

.closeIcon {
	position: absolute;
	top: 24px;
	right: 10px;
	width: 24px;
	height: 24px;
	z-index: 10;
	cursor: pointer;

	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 18px;
		height: 3px;
		background: $c_blue_300;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}
