@import '../../stylesheets/variables.scss';

.button {
	align-items: center;
	background: $c_blue_100;
	border-radius: 4px;
	border: 2px solid $c_blue_100;
	color: $c_white;
	cursor: pointer;
	display: inline-flex;
	font-size: $fs_large;
	font-weight: $fw_medium;
	justify-content: center;
	outline: none;
	padding: 12px 25px;
	height: 49px;
	text-decoration: none;
	transition: $tr_fast;
	min-width: 100px;

	&.primary {
		text-transform: capitalize;

		&:hover {
			background-color: #6c8bf5;
			border-color: #6c8bf5;
		}

		&:focus {
			background-color: #3656c0;
			border-color: #3656c0;
		}

		&[disabled],
		&.disabled {
			background-color: #c6d2fa;
			border-color: #c6d2fa;
			cursor: not-allowed;
			pointer-events: none;
		}
	}

	&.secondary {
		background: $c_white;
		color: $c_blue_100;
		text-transform: capitalize;

		&:hover {
			border-color: #6c8bf5;
			color: #6c8bf5;
		}

		&:focus {
			background-color: #3656c0;
			border-color: #3656c0;
			color: $c_white;
		}

		&[disabled] {
			border-color: #c6d2fa;
			color: #c6d2fa;
			cursor: not-allowed;
			pointer-events: none;
		}
	}

	&.secondaryWithoutBorder {
		@extend .secondary;
		border: 0;
		box-shadow: none;
		background: 0;

		&:focus {
			background: $c_white;
			color: $c_blue_100;
		}
	}

	&.tertiary {
		background-color: #ebf1ff;
		border-color: #ebf1ff;
		color: #4972f4;

		&:hover {
			background-color: darken(#ebf1ff, 2%);
			border-color: darken(#ebf1ff, 2%);
		}
	}

	&.borderless {
		background: transparent;
		border: none;
		color: $c_blue_100;

		&:hover {
			color: #6c8bf5;

			&.hasTransition {
				/*! rtl:ignore */
				.icon {
					transform: rotate(90deg);
					transition: transform 0.2s ease-in-out;
				}
			}
		}

		&:focus {
			color: #3656c0;
		}

		&[disabled] {
			color: #c6d2fa;
			cursor: not-allowed;
			pointer-events: none;

			.icon {
				transform: rotate(0deg) #{'/*rtl:rotate(180deg)*/'};
				transition: unset;
			}
		}
	}

	&.default {
		background: none;
		color: black;
		border: none;
		padding: 0;
		font: inherit;
		cursor: pointer;
		outline: inherit;
		text-decoration: none;
		justify-content: flex-start;
		flex: 1;

		@media (min-width: 767px) {
			div {
				&:first-child {
					width: 100%;
				}
			}
		}
	}

	&.link {
		font-weight: $fw_bold;

		@extend .borderless;
	}
}

.icon {
	margin: 0 0 0 8px;
	transform: rotate(0deg) #{'/*rtl:rotate(180deg)*/'};
	transition: unset;
}

.loader {
	margin: 0 auto;

	div {
		border-color: white transparent transparent transparent;
	}
}

.plus {
	font-size: 28px !important;
	color: $c_blue_100 !important;
	margin-right: 8px;
}
