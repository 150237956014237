@import '../../stylesheets/variables.scss';

.container {
	background-color: white;
	margin-bottom: 16px;
	border-radius: 8px;
	box-shadow: 0 6px 10px #4972f40d;
	padding: 26px 25px 20px;

	@media (min-width: 767px) {
		padding: 26px 40px 20px;
	}
}

.title {
	color: #000000;
	font-size: $fs_large;
	font-weight: $fw_medium;
	display: flex;
	margin-bottom: 31px;
	width: 100%;
	align-items: center;
	position: relative;
}
