@import '../../../../../stylesheets/variables';

.container {
	height: 578px;
	padding: 26px;

	@media (min-width: 767px) {
		width: 667px;
	}

	.header {
		display: flex;
		justify-content: flex-end;

		.closeIcon {
			cursor: pointer;
		}
	}

	.body {
		display: flex;
		flex-flow: column;
		justify-content: center;
		text-align: center;

		.icon {
			width: 96px;
			height: 96px;
		}

		.title {
			color: $c_blue_300;
			font-size: 28px;
			font-weight: $fw_medium;
			margin: 28px 0 16px;
		}

		.details {
			font-size: $fs_large;
			margin-bottom: 32px;
		}

		.label {
			color: $c_blue_300;
			font-weight: $fw_medium;
			font-size: $fs_medium;
		}

		.privateKey {
			display: flex;
			flex-wrap: wrap;
			@include legacyGap(8px, column);
			align-self: center;

			@media (min-width: 767px) {
				width: 438px;
			}

			.key {
				flex-basis: 100%;
				height: 227px;
			}

			.action {
				font-size: $fs_small;
				font-weight: $fw_medium;
				color: $c_blue_100;
				cursor: pointer;
			}

			.copy {
				flex: 1;
				text-align: left;
			}

			.download {
				justify-content: flex-end;
				text-decoration: none;
			}
		}
	}
}
