.title {
	padding: 0;
	margin-bottom: 32px;
}

.flex {
	display: flex;
	align-items: center;
}

.icon {
	width: 20px !important;
	height: 20px !important;
	margin-left: 0;
}

.button {
	margin: 0 auto 15px;
	display: block;
	max-width: 243px;
	width: 100%;
	text-align: center;
}

.success {
	margin: 0 auto;
	max-width: 243px;
	width: 100%;
	text-align: center;
}

.estimate {
	background: #ebf1ff;
	border-radius: 16px;
	padding: 16px;
	margin-bottom: 40px;
}
