@import '../../../../../stylesheets/variables.scss';

.container {
	display: flex;
	flex-flow: column;
	width: 100%;
	@include legacyGap(18px, column);

	.header {
		display: flex;

		@media (max-width: 767px) {
			flex-flow: column;
			@include legacyGap(18px, column);
		}
	}

	.body {
		display: flex;
		height: 260px;
		position: relative;

		&.loading {
			.chart {
				opacity: 0.25;
			}
		}

		.loader {
			width: 48px;
			height: 48px;
			display: inline-block;
			position: absolute;
			left: calc(50% - 24px);
			top: calc(50% - 24px);

			&::before,
			&::after {
				content: '';
				box-sizing: border-box;
				width: 48px;
				height: 48px;
				border-radius: 50%;
				border: 2px solid $c_gray_300;
				position: absolute;
				animation: animloader 2s linear infinite;
			}

			&::after {
				opacity: 0;
				animation-delay: 1s;
			}
		}

		.chart {
			width: 100% !important;
		}
	}
}

@keyframes animloader {
	0% {
		transform: scale(0);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}
