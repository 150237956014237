@import '../../../../stylesheets/variables';

.container {
	display: flex;
	flex-flow: column;
	padding-top: 20px;
}

.header {
	display: flex;
	flex-flow: row;
	align-items: center;
}

.body {
	display: flex;
	flex-flow: column;
	gap: 32px;
}

.note {
	font-size: $fs_small;
}

.button {
	display: flex;
	font-size: $fs_medium;
	color: $c_white;
	height: 40px;

	&:hover {
		color: $c_white;
	}
}

.withIcon {
	display: flex;
	flex-flow: row;
	align-items: center;
	gap: 8px;
}

.id {
	display: flex;
	gap: 8px;
}

.copy {
	display: flex;
	height: 16px;
	align-self: center;
}

.default {
	background-color: $c_blue_10;
	border: 1px solid #3d72fc33;
	border-radius: 4px;
	padding: 4px 8px;
	font-weight: $fw_medium;
	font-size: $fs_small;
	color: $c_blue_100;
	text-align: center;
	width: fit-content;
	justify-self: flex-end;
	height: 26px;
}

.setDefault {
	font-weight: $fw_medium;
	font-size: $fs_small;
	padding: 0;
	min-width: 0;
	justify-self: flex-end;
	text-transform: none !important;
	height: 26px;
}
