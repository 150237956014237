@import '../../stylesheets/variables.scss';

.inputGroup {
	&__inline {
		display: inline-flex;
		align-items: center;
		justify-content: flex-end;
	}
	&__block {
		position: relative;
		display: block;
		text-align: left;
		max-width: 438px;
	}
}

.label {
	&__inline {
		display: inline-flex;
		font-size: 10px;
		line-height: 14px;
		margin-right: 8px;
	}
	&__block {
		display: block;
		font-size: $fs_small;
		font-weight: $fw_medium;
		line-height: $lh_medium;
	}
}

.select {
	&__inline {
		display: inline-flex;
	}
	&__block {
		border-radius: 4px;
		border: 1px solid #d9dbe5;
		padding: 7px 12px;
		text-align: left;
		transition: $tr_fast;

		&.error {
			border: 1px solid $c_red_100;
		}

		&:active,
		&:focus {
			border-color: $c_blue_100;
			outline: 0;
		}

		&:hover {
			border-color: #c8d4fc;
			outline: 0;
		}
	}
}

.selectError {
	position: absolute;
	font-size: $fs_small;
	left: 0px;
	bottom: -16px;
	color: $c_red_100;
	height: 12px;
}
