@import '../../../stylesheets/variables.scss';

.loader {
	margin: 75px auto;
}

.noData {
	margin: 74px auto;
	text-align: center;
	font-size: $fs_medium;
	color: #b3b6c6;
}

.countryFilter {
	@media (max-width: 767px) {
		margin: 0px 20px;
	}
}
