@import '../../stylesheets/variables.scss';

.container {
	width: 100%;
	margin-bottom: 32px;
	position: relative;
}

.inputContainer {
	width: 100%;
	position: relative;
}

.currencyCode {
	position: absolute;
	top: calc(50% - 8px);
	right: 12px;
	font-size: $fs_medium;
	font-weight: $fw_regular;
	line-height: 16px;
	color: #b3b6c6;
}

.label {
	font-size: $fs_medium;
	font-weight: $fw_medium;
	color: $c_blue_300;
	text-align: left;
	width: 100%;
	display: block;
	margin-bottom: 8px;
}
