@import '../../stylesheets/variables.scss';

.inputGroup {
	position: relative;
	text-align: left;
	max-width: 438px;
	width: 100%;

	.icon {
		position: absolute;
		top: 15px;
		font-size: 10px;
		right: 12px;
		color: darken(#d9dbe5, 10%);
		transition: $tr_fast;
	}

	&:hover {
		.icon {
			color: darken(#d9dbe5, 25%);
		}
	}
}

.toolTipContainer {
	display: flex;
	flex-direction: column;
	align-items: center;

	.toolTipBottomArrow {
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;

		border-top: 8px solid $c_blue_300;
	}

	.toggleToolTip {
		background-color: $c_blue_300;
		border-radius: 4px;
		color: white;
		padding: 12px 22px;
		font-size: $fs_medium;
		font-weight: $fw_regular;
		line-height: 20px;
		text-align: center;
	}
}

.label {
	display: flex;
	align-items: center;
	font-size: $fs_medium;
	color: $c_blue_300;
	margin-bottom: 8px;
	font-weight: $fw_medium;
	line-height: $lh_medium;
}

.input {
	border: 1px solid #e6e8f0;
	border-radius: 4px;
	font-size: $fs_medium;
	line-height: 16px;
	font-weight: $fw_regular;
	transition: $tr_fast;
	padding: 11px 12px;
	width: 100%;

	&.errorInput {
		border-color: $c_red_100;
	}

	&:active {
		border-color: $c_blue_100;
	}

	&:hover {
		border-color: #c8d4fc;
		outline: 0;
	}

	&:focus {
		border: 1px solid #acb0c6 !important;
		outline: 0;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:read-only {
		background-color: rgba(0, 0, 0, 0.1);
		pointer-events: none;
	}

	/* Firefox */
	&[type='number'] {
		-moz-appearance: textfield;
	}
}

.error {
	position: absolute;
	font-size: $fs_small;
	left: 0;
	bottom: -16px;
	color: $c_red_100;
	height: 12px;

	&:before {
		content: '';
		background: url('../../images/message_icons/danger.svg') no-repeat;
		background-size: 12px 12px;
		width: 12px;
		height: 12px;
		float: left;
		margin: 0 6px 0 0;
	}
}

.errorMessageNoAbsolute {
	color: $c_red_100;
	font-size: $fs_small;
}
