@import '../../../stylesheets/variables.scss';

.loadingScreen {
	width: 100%;
	height: 100vh;
}

.error {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	@include legacyGap(8px, column);

	.head {
		display: flex;
		flex-flow: column;
		@include legacyGap(8px, column);
	}

	.button {
		font-weight: $fw_medium;
		font-size: $fs_medium;
	}
}
