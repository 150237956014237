@import '../../../stylesheets/variables.scss';

.container {
	border-radius: 8px;
	margin: 0 auto;
	text-align: center;
	padding: 20px 40px;

	@media (min-width: 667px) {
		width: 667px;
	}
}

.timerImg {
	width: 220px;
	margin: 0 auto;
}

.title {
	font-size: $fs_xlarge;
	line-height: $lh_medium;
	font-weight: $fw_medium;
	color: black;
	margin-bottom: 30px;
}

.paragraph {
	font-size: $fs_small;
	line-height: 18px;
	font-weight: $fw_regular;
	color: black;
	max-width: 400px;
	width: 100%;
	margin: 40px auto;

	span {
		font-weight: $fw_medium;
	}
}

.logout {
	font-size: $fs_small;
	line-height: 16px;
	font-weight: 600;
	color: $c_blue_100;
	cursor: pointer;
	display: inline-block;
	margin-bottom: 40px;
}

.button {
	display: block;
	margin: 20px auto;
	max-width: 232px;
	width: 100%;
}

.time {
	display: inline-block;
	width: 15px;
}
